import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import { processKeywords, getFetchFilters } from '../utils';
import apiFactory from '@/api';
import i18n from '@/i18n';
import router from '@/routes';
import { Events, EventBus } from '@/events';
import toastr from '@/helpers/init-toastr';
import { parseUrl, buildUrl } from '@/helpers/url';
import { cookieGetters } from '@/helpers/cookieManager';
import { prepareKeywordSettings } from '@/store/utils/settings';
import { checkIsKeywordRecentlyAdded } from '@/helpers/lastAddedKeywordsChecking';

const usersApi = apiFactory.get('users');
const projectsApi = apiFactory.get('projects');
const keywordsApi = apiFactory.get('keywords');

import {
    SET_KEYWORD_SETTINGS,
    RESET_GLOBAL_SEARCH_QUERY,
    SET_ACTIVE_GROUPS,
    SET_AVAILABLE_TAGS,
    SET_GATHERING_TIMER,
    SET_GLOBAL_SEARCH_QUERY,
    SET_KEYWORDS_FOR_UNDO,
    SET_KEYWORDS_IS_FETCHING,
    SET_KEYWORDS,
    SET_LIST_OF_KEYWORDS_DOMAINS,
    SET_LIST_OF_KEYWORDS_TYPES,
    SET_PROJECT_FOR_KEYWORDS_UNDO,
    SET_SELECTED_KEYWORDS,
    SET_SHOW_KEYWORD_ACTION,
    SET_TOTAL_KEYWORDS_PAGES,
    SET_TOTAL_KEYWORDS,
    SET_ALL_CHECKBOXES_SELECTED,
    SET_FAVORITE_DATA,
    SET_ALL_WITHOUT_IDS,
    SET_CURRENT_PAGE,
    SET_NEW_ADDED_KEYWORDS,
    SET_NEW_ADDED_KEYWORDS_IS_FETCHING,
    SET_LAZY_LOAD_FETCHING,
    SET_FILTER_BY_RANKING_URL,
    SET_IS_EDIT_POPUP_VISIBLE,
    SET_LAST_FETCH_REQUEST,
    SET_CURRENT_REFRESHING_KEYWORDS
} from '../mutations';

export const keywordsPersistedKeys = [
    'keywords.showKeywordAction',
];

let interval;

const keywords = {
    state: {
        availableTags: [],
        gatheringTimer: null,
        globalSearchQuery: '',
        keywordsForUndo: [],
        keywordsIsFetching: false,
        listOfKeywordsDomains: [],
        listOfKeywordsTypes: [],
        originalItems: [],
        projectForKeywordsUndo: null,
        selectedAuth: [],
        showKeywordAction: null,
        totalKeywords: 0,
        totalKeywordsPages: 0,
        allCheckboxesSelected: false,
        favoriteData: {},
        selectedKeywordsData: [],
        allWithoutIds: [],
        //this variable will be "true" only if in the last 24 hours added keywords
        isHaveNewAddedKeywords: false,
        newAddedKeywordsIsFetching: false,
        lazyLoadFetching: false,
        filterByRankingUrl: false,
        isEditPopupVisible: false,
        lastFetchRequest: null,
        settings: {
            chartPeriod: 180,
            table_columns: {},
        },
        currentRefreshingKeywords: []
    },
    mutations: {
        [SET_SHOW_KEYWORD_ACTION]: (state, payload) => state.showKeywordAction = payload,
        [SET_KEYWORDS]: (state, payload = []) => state.originalItems = Object.freeze(payload),
        [SET_LIST_OF_KEYWORDS_DOMAINS]: (state, payload = []) => state.listOfKeywordsDomains = payload,
        [SET_LIST_OF_KEYWORDS_TYPES]: (state, payload = []) => state.listOfKeywordsTypes = payload,
        [SET_SELECTED_KEYWORDS]: (state, payload = {}) => {
            const {
                allData,
                selectedAuth
            } = payload;
            state.selectedAuth = Object.freeze(selectedAuth);
            state.selectedKeywordsData = allData;
        },
        [SET_KEYWORDS_FOR_UNDO]: (state, payload) =>
            (state.keywordsForUndo = Object.freeze(payload)),
        [SET_PROJECT_FOR_KEYWORDS_UNDO]: (state, payload) =>
            (state.projectForKeywordsUndo = Object.freeze(payload)),
        [SET_TOTAL_KEYWORDS]: (state, payload) => (state.totalKeywords = payload),
        [SET_TOTAL_KEYWORDS_PAGES]: (state, payload) => (state.totalKeywordsPages = payload),
        [SET_KEYWORDS_IS_FETCHING]: (state, payload) => (state.keywordsIsFetching = payload),
        [SET_AVAILABLE_TAGS]: (state, payload) => (state.availableTags = payload),
        [SET_GATHERING_TIMER]: (state, timer) => (state.gatheringTimer = timer),
        [SET_GLOBAL_SEARCH_QUERY]: (state, payload) => (state.globalSearchQuery = payload),
        [RESET_GLOBAL_SEARCH_QUERY]: state => (state.globalSearchQuery = ''),
        [SET_ALL_CHECKBOXES_SELECTED]: (state, payload) => state.allCheckboxesSelected = payload,
        [SET_FAVORITE_DATA]: (state, payload) => state.favoriteData = payload,
        [SET_ALL_WITHOUT_IDS]: (state, payload) => state.allWithoutIds = payload,
        [SET_NEW_ADDED_KEYWORDS]: (state, payload) => state.isHaveNewAddedKeywords = payload,
        [SET_NEW_ADDED_KEYWORDS_IS_FETCHING]: (state, payload) => state.newAddedKeywordsIsFetching = payload,
        [SET_KEYWORD_SETTINGS]: (state, payload) => state.settings = payload,
        [SET_LAZY_LOAD_FETCHING]: (state, payload) => state.lazyLoadFetching = payload,
        [SET_FILTER_BY_RANKING_URL]: (state, payload) => state.filterByRankingUrl = payload,
        [SET_IS_EDIT_POPUP_VISIBLE]: (state, payload) => state.isEditPopupVisible = payload,
        [SET_LAST_FETCH_REQUEST]: (state, payload) => state.lastFetchRequest = payload,
        [SET_CURRENT_REFRESHING_KEYWORDS]: (state, payload) => state.currentRefreshingKeywords = payload,
    },
    getters: {
        getLastFetchRequest: state => state.lastFetchRequest,
        getIsEditPopupVisible: state => state.isEditPopupVisible,
        getKeywordSetting: state => prop => state.settings[prop],
        getKeywordSettings: state => state.settings,
        getShowKeywordAction: state => state.showKeywordAction,
        getListOfKeywordsDomains: state => state.listOfKeywordsDomains,
        getListOfKeywordsTypes: state => state.listOfKeywordsTypes,

        getKeywordsIsFetching: state => state.keywordsIsFetching,
        getKeywordsForRefreshing: state =>
            state.originalItems
                .filter(kw => kw.grank === null || kw.clicks === null),
        // the first step of processing
        getOriginalItems: state => state.originalItems,
        getKeywordById: state => id => state.originalItems.find(kw => kw.id === id),
        // the second step of processing
        getSortedAndFilteredKeywords: (state, getters) => originalItems =>
            processKeywords(getters, originalItems),
        getTotalKeywords: state => state.totalKeywords,
        getTotalKeywordsPages: state => state.totalKeywordsPages,
        getSelectedAuth: state => state.selectedAuth,
        getSelectedKeywords: (state, getters) => {
            const { getSortedAndFilteredKeywords, getOriginalItems, getSelectedAuth } = getters;
            return getSortedAndFilteredKeywords(getOriginalItems).filter(el =>
                getSelectedAuth.some(elem => elem === el.id),
            );
        },
        getKeywordsForUndo: state => state.keywordsForUndo,
        getProjectForKeywordsUndo: state => state.projectForKeywordsUndo,
        keyKeywordsById: state => {
            const keywords = state.originalItems.filter(item =>
                state.selectedAuth.includes(item.id),
            );
            return keywords.length ? keywords : [];
        },
        getGlobalSearchQuery: state => state.globalSearchQuery,
        getIsAllCheckboxesSelected: state => state.allCheckboxesSelected,
        getFavoriteData: state => state.favoriteData,
        getSelectedKeywordsWithAllData: state => state.selectedKeywordsData,
        getAllWithoutIdsData: state => state.allWithoutIds,
        withoutIdsGetItem: (state) => (id) => {
            const storeIds = state.allWithoutIds;

            return storeIds.find((item) => {
                return item === id;
            });
        },
        getGatheringTimer: (state) => state.gatheringTimer,
        getIsHaveNewAddedKeywords: state => state.isHaveNewAddedKeywords,
        getNewAddedKeywordsIsFetching: state => state.newAddedKeywordsIsFetching,
        getLazyLoadDone: (state, getters) =>
            getters.getDisplayLength > 250 &&
            getters.getCurrentPage === state.totalKeywordsPages,
        getLazyLoadMode: (state, getters) => getters.getDisplayLength > 250,
        getFilterByRankingUrl: state => state.filterByRankingUrl,
        getLazyLoadFetching: state => state.lazyLoadFetching,
        getCurrentRefreshingKeywords: state => state.currentRefreshingKeywords,
    },
    actions: {
        async deleteKeywords({ getters, commit, dispatch }, keywordsWithAllData) {
            try {
                const keywords = keywordsWithAllData && keywordsWithAllData.map(keyword => keyword.id);
                const withoutIds = getters.getAllWithoutIdsData;
                const dataParams = {
                    type: 'keyword',
                }
                if (keywords) {
                    dataParams.ids = keywords;
                }
                if (withoutIds.length) {
                    dataParams.withoutIds = withoutIds;
                }
                const data = {
                    data: {
                        data: dataParams,
                    },
                };
                let res = null;
                if (getters.getGlobalSearchQuery) {
                    res = await keywordsApi.deleteKeywordsFromGlobalSearch(data);
                } else {
                    const projectId = getters.getCurrentProject.project_id;
                    res = await keywordsApi.deleteKeywords(data, projectId, getFetchFilters(getters));
                }

                if (res) {
                    const {
                        data: {
                            data: {
                                existingKeywordsCount = []
                            }
                        }
                    } = res;
                    existingKeywordsCount.forEach(({ id, count }) => {
                        dispatch('updateKeywordsCount', { id, count });
                    });
                }

                dispatch('setSelectedKeywords', []);
                dispatch('updateLeftSidebarTags', res.data.meta.extra_data.tags);


                const itemsForUndo = keywords ? getters.getOriginalItems.filter(item =>
                    keywords.includes(item.id),
                ) : null;
                commit(SET_KEYWORDS_FOR_UNDO, itemsForUndo);
                dispatch('fetchMetrics');

                if (getters.getCurrentProject) {
                    const tagsForCurrentProject = res.data.meta.extra_data.tags[getters.getCurrentProject.id];
                    if (tagsForCurrentProject) {
                        dispatch('updateCurrentProjectTags', tagsForCurrentProject);
                    }

                    await dispatch('fetchKeywords', { id: getters.getCurrentProject.id });

                } else if (getters.getGlobalSearchQuery) {
                    await dispatch('fetchKeywordsFromSearch');
                }
            } catch (e) {
                console.log(e, 'e')
                throw new Error(e);
            }
        },
        changeKeywordsCount({ getters, commit, dispatch }, { id, count, type }) {
            const projects = _.cloneDeep(getters.getOriginalActiveProjects);

            projects.forEach(project => {
                if (id === project.id) {
                    // if project have just been added
                    if (typeof project.attributes.keywords_count.ACTIVE === 'undefined') {
                        project.attributes.keywords_count = { ACTIVE: 0, INACTIVE: 0 };
                    }

                    type === 'decrease'
                        ? (project.attributes.keywords_count.ACTIVE -= count)
                        : (project.attributes.keywords_count.ACTIVE += count);
                }
            });

            commit(SET_ACTIVE_GROUPS, projects);
            dispatch('fetchUsed');
        },
        updateKeywordsCount({ getters, commit, dispatch }, { id, count }) {
            // change keywords count depend backend response (for example: move, delete, undo actions)
            const projects = _.cloneDeep(getters.getOriginalActiveProjects);

            projects.forEach(project => {
                if (project.attributes.project_id === id) {
                    project.attributes.keywords_count = { ...project.attributes.keywords_count, ACTIVE: count };
                }
            });

            commit(SET_ACTIVE_GROUPS, projects);
            dispatch('fetchUsed');
        },
        async fetchKeywordsFromSearch({ commit, getters, dispatch }) {
            commit(SET_KEYWORDS_IS_FETCHING, true);
            commit(SET_LIST_OF_KEYWORDS_DOMAINS, []);
            const searchQuery = getters.getGlobalSearchQuery;
            const sortDirection = getters.getDisplaySetting('sort_by_order');
            const sortField = getters.getDisplaySetting('sort_by');
            let originalItems = [];
            if (!searchQuery) {
                commit(SET_KEYWORDS_IS_FETCHING, false);
                throw new Error(i18n.t('project-not-found'));
            }
            const params = {
                search: searchQuery,
                per_page: 250,
                page: 1,
                sort_direction: sortDirection,
                sort_field: sortField,
            }
            try {
                const currentFetch = Date.now();
                dispatch('setLastFetchedRequest', currentFetch);

                const res = await keywordsApi.search(params);

                if (currentFetch !== getters.getLastFetchRequest) {
                    return;
                }
                if (!res.data.errors) {
                    originalItems = res.data.data;
                    dispatch('setTotalKeywords', res.data.meta.pagination.total);
                    dispatch('setTotalKeywordsPages', res.data.meta.pagination.total_pages);
                    commit(SET_LIST_OF_KEYWORDS_DOMAINS, res.data.meta.domains);
                }
                dispatch('processKeywords', originalItems);
            } catch (e) {
                throw new Error(e);
            }
        },
        async fetchKeywords({ commit, getters, dispatch }, properties) {
            const currentFetch = Date.now();
            const { id, not_reset_page, page } = properties;
            let originalItems = [];
            let newDomains = [];
            let newKeywordTypes = [];

            if (!id) {
                commit(SET_LAZY_LOAD_FETCHING, false);
                commit(SET_KEYWORDS_IS_FETCHING, false);

                throw new Error(i18n.t('project-not-found'));
            }

            if (page && getters.getLazyLoadDone) {
                return;
            }

            if (page && page > 1 && getters.getLazyLoadMode) {
                if (
                    id === getters.getCurrentProject.id &&
                    getters.getOriginalItems.length > 0
                ) {
                    originalItems = getters.getOriginalItems;
                    newDomains = getters.getListOfKeywordsDomains;
                    newKeywordTypes = getters.getListOfKeywordsTypes;
                    dispatch('setCurrentPage', page);
                }

                commit(SET_LAZY_LOAD_FETCHING, true);
            } else if (!not_reset_page) {
                dispatch('setCurrentPage', 1);
            }

            if (getters.getCurrentPage === 1 || !getters.getLazyLoadMode) {
                commit(SET_KEYWORDS_IS_FETCHING, true);
            }

            try {
                const { name: routeName, params: routeParams } = router.history.current;
                let queryData = {
                    params: {
                        ...getFetchFilters(getters),
                    },
                };

                if (getters.getLazyLoadMode) {
                    queryData.params.per_page = 250;
                }

                if (getters.getFilterByRankingUrl) {
                    queryData.params.ranking_url = getters.getFilterByRankingUrl;
                }

                let res;

                dispatch('setLastFetchedRequest', currentFetch);

                if (getters.getViewkeyView) {
                    const password = properties.password
                        ? properties.password
                        : getters.getCurrentProject.password;
                    queryData.params.auth = getters.getCurrentProject.auth;
                    queryData.params.password = password;

                    if (routeName === 'shareViewkeyWithHash' || routeName === 'shareKeywordsTableWithHash') {
                        // view-key api with hash
                        res = await projectsApi.fetchKeywordsForShareViewKeysWithHash(routeParams.hash, queryData);
                    } else {
                        res = await await projectsApi.fetchKeywordsForShareViewKeys(
                            getters.getCurrentProject.shortkey,
                            queryData
                        );
                    }
                } else {
                    res = await projectsApi.fetchKeywords(id, queryData);
                }

                if (currentFetch !== getters.getLastFetchRequest) {
                    return;
                }

                if (!res.data.errors) {
                    originalItems = [...originalItems, ...res.data.data];

                    dispatch('setTotalKeywords', res.data.meta.pagination.total);
                    dispatch('setTotalKeywordsPages', res.data.meta.pagination.total_pages);
                    commit(SET_LIST_OF_KEYWORDS_DOMAINS, [...newDomains, ...res.data.meta.domains]);
                    commit(SET_LIST_OF_KEYWORDS_TYPES, [...newKeywordTypes, ...res.data.meta.types]);
                }

                dispatch('processKeywords', originalItems);
            } catch (e) {
                console.log(e, 'e')
                throw new Error(e);
            } finally {
                commit(SET_LAZY_LOAD_FETCHING, false);

                if (currentFetch === getters.getLastFetchRequest) {
                    commit(SET_KEYWORDS_IS_FETCHING, false);
                }
            }
        },
        async fetchTopPages({ getters }, properties) {
            const { id, page, sort_direction, sort_field } = properties;

            if (!id) {
                throw new Error(i18n.t('project-not-found'));
            }

            try {
                const { name: routeName, params: routeParams } = router.history.current;
                let queryData = {
                    params: {
                        ...getFetchFilters(getters),
                        page: page,
                        per_page: 10,
                        sort_field: sort_field,
                        sort_direction: sort_direction,
                    },
                };

                let res;

                if (getters.getViewkeyView) {
                    const password = properties.password
                        ? properties.password
                        : getters.getCurrentProject.password;
                    queryData.params.auth = getters.getCurrentProject.auth;
                    queryData.params.password = password;

                    if (routeName === 'shareViewkeyWithHash') {
                        res = await projectsApi.fetchTopPagesForShareViewKeysWithHash(routeParams.hash, queryData);
                    } else {
                        res = await await projectsApi.fetchTopPagesForShareViewKeys(
                            getters.getCurrentProject.shortkey,
                            queryData
                        );
                    }
                } else {
                    res = await projectsApi.fetchTopPages(id, queryData);
                }

                return res.data;
            } catch (e) {
                console.log(e, 'e');

                // throw new Error(e);
                return false;
            }
        },
        async fetchKeywordsForShareViewKeys({ commit, getters, dispatch }, properties) {
            commit(SET_KEYWORDS_IS_FETCHING, true);
            commit(SET_LIST_OF_KEYWORDS_DOMAINS, []);

            const { id, not_reset_page, shortkey, password } = properties;
            let originalItems = [];
            const { name: routeName, params: routeParams } = router.history.current;

            if (
                (!id &&
                    routeName !== 'shareViewkeyWithHash' &&
                    routeName !== 'shareOfVoiceWithHash' &&
                    routeName !== 'shareKeywordsTableWithHash'
                ) ||
                !shortkey
            ) {
                commit(SET_KEYWORDS_IS_FETCHING, false);
                throw new Error(i18n.t('viewkey-not-found'));
            }

            if (!not_reset_page) {
                dispatch('setCurrentPage', 1);
            }

            const queryData = {
                params: {
                    auth: id,
                    password: password,
                    ...getFetchFilters(getters),
                },
            };

            if (getters.getFilterByRankingUrl) {
                queryData.params.url = getters.getFilterByRankingUrl;
            }

            try {
                const currentFetch = Date.now();
                dispatch('setLastFetchedRequest', currentFetch);

                let res;
                if (
                    routeName === 'shareViewkeyWithHash' ||
                    routeName === 'shareOfVoiceWithHash' ||
                    routeName === 'shareKeywordsTableWithHash'
                ) {
                    res = await projectsApi.fetchKeywordsForShareViewKeysWithHash(routeParams.hash, queryData);
                } else {
                    res = await projectsApi.fetchKeywordsForShareViewKeys(shortkey, queryData);
                }

                if (currentFetch !== getters.getLastFetchRequest) {
                    return;
                }

                if (!res.data.errors) {
                    if (!res.data.data.length && !getters.getSearchKeyword) {
                        router.push({ name: 'PageNotFound' });
                        return;
                    }
                    originalItems = res.data.data;
                    dispatch('setTotalKeywords', res.data.meta.pagination.total);
                    dispatch('setTotalKeywordsPages', res.data.meta.pagination.total_pages);
                    commit(SET_LIST_OF_KEYWORDS_DOMAINS, res.data.meta.domains);
                } else {
                    throw new Error(res.data.errors);
                }
                dispatch('processKeywords', originalItems);
            } catch (error) {
                console.error(error);
                toastr.error(i18n.t('viewkey-not-found'));
            }
        },
        fetchGatheringKeywords({ getters, commit, dispatch }) {
            dispatch('clearGatheringTimer');
            const { getKeywordsForRefreshing } = getters;

            if (getters.getViewkeyView ||
                !getters.getCurrentProject ||
                !getters.getCurrentProject.id ||
                !getters.getCurrentProject.shortkey ||
                router.history.current.name !== 'keywordList' ||
                !getKeywordsForRefreshing.length) return;

            commit(SET_CURRENT_REFRESHING_KEYWORDS, getKeywordsForRefreshing.map(kw => kw.id));
            commit(
                SET_GATHERING_TIMER,
                setTimeout(async () => {
                    try {
                        const params = {
                            keyword_ids: getKeywordsForRefreshing.map(kw => kw.id),
                            per_page: 250,
                            page: 1,
                            sort_direction: getters.getDisplaySetting('sort_by_order'),
                            sort_field: getters.getDisplaySetting('sort_by'),
                        }
                        const res = await keywordsApi.search(params);

                        if (!res.data.errors) {
                            let count = 0;
                            let refreshingKeywords = {};
                            const rankNeedsRefresh = getKeywordsForRefreshing.map(kw => kw.grank === null ? kw.id : null);
                            const gscDataNeedsRefresh = getKeywordsForRefreshing.map(kw => kw.clicks === null ? kw.id : null);

                            commit(SET_CURRENT_REFRESHING_KEYWORDS, res.data.data.map(kw=>kw.id));
                            res.data.data
                                .filter(kw =>
                                    kw.attributes.grank != null && rankNeedsRefresh.includes(kw.id) ||
                                    kw.attributes.clicks != null && gscDataNeedsRefresh.includes(kw.id)
                                ).map(kw => {
                                    kw = { ...kw, ...kw.attributes };
                                    delete kw.attributes;

                                    if (!kw.tags) {
                                        kw.tags = [];
                                    }

                                    kw.tagsSplited = kw.tags;

                                    refreshingKeywords[kw.id] = kw;
                                    count++;
                                });

                            if (count) {
                                dispatch('setKeywords', getters.getOriginalItems
                                    .map(kw => refreshingKeywords[kw.id] ?? kw)
                                );
                            }

                            if (getKeywordsForRefreshing.length) {
                                dispatch('fetchGatheringKeywords');
                            }
                        }
                    } catch (e) {
                        console.error(
                            `Error on fetch gathering keywords: ${e.response?.data?.errors?.[0].message || ''}`,
                        );
                        throw e;
                    }
                }, 5000),
            );
        },
        processKeywords({ commit, dispatch }, originalItems) {
            let availableTags = [];

            for (let i = 0; i < originalItems.length; i++) {
                originalItems[i] = { ...originalItems[i], ...originalItems[i].attributes };
                delete originalItems[i].attributes;

                originalItems[i].grankhistory =
                    originalItems[i].grankhistory && originalItems[i].grankhistory.length
                        ? originalItems[i].grankhistory.map(el => parseInt(el, 10))
                        : [];

                originalItems[i].yahooRankHistory =
                    originalItems[i].yahooRankHistory && originalItems[i].yahooRankHistory.length
                        ? originalItems[i].yahooRankHistory.map(el => parseInt(el, 10))
                        : [];

                originalItems[i].bingRankHistory =
                    originalItems[i].bingRankHistory && originalItems[i].bingRankHistory.length
                        ? originalItems[i].bingRankHistory.map(el => parseInt(el, 10))
                        : [];

                const tags =
                    originalItems[i].tags && originalItems[i].tags.length
                        ? originalItems[i].tags
                        : [];

                originalItems[i].tagsSplited = tags;

                if (!originalItems[i].tags) {
                    originalItems[i].tags = [];
                }
                availableTags = availableTags.concat(tags);
            }

            commit(SET_AVAILABLE_TAGS, _.uniq(availableTags));
            dispatch('setKeywords', originalItems);
            dispatch('fetchGatheringKeywords');
            commit(SET_KEYWORDS_IS_FETCHING, false);
            return originalItems;
        },
        toggleKeywordSelection({ getters, dispatch }, auth) {
            let force = null;

            if (typeof auth === 'object') {
                force = auth.force;
                auth = auth.id;
            }

            const { getSelectedAuth } = getters;
            const selected = getSelectedAuth.filter(el => el !== auth);

            if ((selected.length === getSelectedAuth.length && force != false) || force == true) {
                selected.push(auth);
            }

            dispatch('setSelectedKeywords', selected);
        },
        selectAllKeywords({ getters, dispatch }) {
            const sortedAndFilteredKeywords = getters.getSortedAndFilteredKeywords(getters.getOriginalItems).map(el => el.id);
            const selectedKeywords = getters.getSelectedAuth;
            const data = _.uniq([...selectedKeywords, ...sortedAndFilteredKeywords]);
            dispatch('setSelectedKeywords', data);
        },
        selectKeywordRow({ dispatch }, id) {
            const selected = [id];
            dispatch('setSelectedKeywords', selected);
        },
        resetAllKeywords({ getters, dispatch }) {
            const isAllSelected = getters.getIsAllCheckboxesSelected;
            if (isAllSelected) {
                dispatch('setAllCheckboxesSelected', false);
            } else {
                const originalItems = getters.getOriginalItems;
                const selectedKeywords = getters.getSelectedAuth.filter((item) => {
                    const foundedItem = originalItems.find((originalItem) => {
                        return originalItem.id === item;
                    });
                    return !foundedItem;
                });
                dispatch('setSelectedKeywords', selectedKeywords);
            }

        },
        async undoDeleting({ getters, commit, dispatch }) {
            const undoData = getters.getKeywordsForUndo;
            const queryData = {
                data: {
                    type: 'keyword',
                    attributes: {
                        status: '1',
                    },
                },
            };
            if (undoData) {
                const mappedData = undoData.map(item => item.id);
                queryData.data.ids = mappedData;
            }
            try {
                let res = null;
                if (getters.getGlobalSearchQuery) {
                    res = await keywordsApi.undoDeletingFromGlobalSearch(queryData);
                } else {
                    const projectId = getters.getCurrentProject.project_id;
                    res = await keywordsApi.undoDeleting(queryData, projectId, getFetchFilters(getters));
                }

                if (res) {
                    const {
                        data: {
                            data: {
                                existingKeywordsCount = []
                            }
                        }
                    } = res;
                    existingKeywordsCount.forEach(({ id, count }) => {
                        dispatch('updateKeywordsCount', { id, count });
                    });
                }

                commit(SET_KEYWORDS_FOR_UNDO, []);
                dispatch('updateLeftSidebarTags', res.data.meta.extra_data.tags);
                dispatch('fetchMetrics');

                if (getters.getCurrentProject) {
                    if (getters.getProjectForKeywordsUndo) {
                        if (
                            !getters.getOriginalActiveProjects.find(
                                project => project.id === getters.getProjectForKeywordsUndo.id,
                            )
                        ) {
                            commit(SET_ACTIVE_GROUPS, [
                                ...getters.getOriginalActiveProjects,
                                getters.getProjectForKeywordsUndo,
                            ]);
                            commit(SET_PROJECT_FOR_KEYWORDS_UNDO, null);
                        }
                    }

                    dispatch('fetchUsed');
                    await dispatch('fetchKeywords', { id: getters.getCurrentProject.id });
                    dispatch('setTutorialMode');
                } else if (getters.getGlobalSearchQuery) {
                    dispatch('fetchKeywordsFromSearch');
                }
            } catch (e) {
                console.log(e, 'e')
                throw new Error(e);
            }
        },
        async toggleKeywordsFavorite({ dispatch, getters }, properties) {
            const withoutIds = getters.getAllWithoutIdsData;
            const { keywords, action } = properties;

            const queryData = {
                data: {
                    type: 'keyword',
                    attributes: {
                        isfav: action,
                    },
                },
            };
            if (keywords.length) {
                queryData.data.ids = keywords;
            }
            if (withoutIds.length) {
                queryData.data.withoutIds = withoutIds;
            }
            const id = getters.getCurrentProject.project_id;
            try {
                await keywordsApi.favorite(queryData, id, getFetchFilters(getters));
                await dispatch('fetchKeywords', {
                    id: getters.getCurrentProject.id,
                    not_reset_page: true,
                });

            } catch (e) {
                throw new Error(e);
            }
        },
        async updateKeywords({ getters, dispatch }, params = {}) {
            const isAllChecked = getters.getIsAllCheckboxesSelected;
            const withoutIds = getters.getAllWithoutIdsData;
            const { updatableKeywordsArray } = params;

            let { newKeyword } = params;
            delete newKeyword.id;
            delete newKeyword.status;
            delete newKeyword.kw;

            if (!newKeyword.url) {
                delete newKeyword.url;
            }

            if (!newKeyword.language) {
                delete newKeyword.language;
            }

            const tags = newKeyword.tags
                ? newKeyword.tags.map(item => {
                    return item.name;
                })
                : [];
            const queryData = {
                data: {
                    ids: [],
                    type: 'keyword',
                    attributes: {
                        ...newKeyword,
                        url: newKeyword.originalUrl || newKeyword.url,
                        tags,
                    },
                },
            };
            if (isAllChecked) {
                delete queryData.data.ids;
                if (withoutIds.length) {
                    queryData.data.withoutIds = withoutIds
                }
            } else {
                updatableKeywordsArray.forEach(item => queryData.data.ids.push(item.id));
            }

            let res = null;

            try {
                if (getters.getCurrentProject) {
                    const projectId = getters.getCurrentProject.project_id;
                    res = await keywordsApi.update(queryData, projectId, { params: getFetchFilters(getters) });
                    await dispatch('fetchKeywords', {
                        id: getters.getCurrentProject.id,
                        not_reset_page: true,
                    });
                    await dispatch('refreshRemainingKeywords', { project: getters.getCurrentProject, overview: false })
                } else {
                    res = await keywordsApi.updateFromGlobalSearch(queryData);
                    await dispatch('fetchKeywordsFromSearch');
                }
                await dispatch('setSelectedKeywords', getters.getSelectedAuth);


                const duplicates = res.data?.meta?.extra_data?.duplicates;
                const affected = res.data?.meta?.extra_data?.affected;

                // success message
                if (affected) {
                    toastr.s(res.data?.meta?.title);
                }

                // if duplicates
                if (duplicates) {
                    toastr.w(
                        i18n.t('keyword-were-duplicate-update', {
                            duplicates: duplicates,
                            total: affected + duplicates,
                        }),
                    );
                }

                // dispatch('setAllCheckboxesSelected', false);
                // toastr.s(message);
            } catch (e) {
                throw e;
            }
        },
        async moveKeywords({ getters, dispatch }, params) {
            const keywords = params.keywords && params.keywords.length ? params.keywords.map(k => k.id) : null;
            const withoutIds = getters.getAllWithoutIdsData;
            const totalKeywords = getters.getTotalKeywords;
            try {
                const data = {
                    data: {
                        type: 'keyword',
                        from_project_id: params.from_project_id,
                        to_project_id: params.to_project_id,
                    },
                };
                if (keywords) {
                    data.data.ids = keywords;
                }

                if (withoutIds.length) {
                    data.data.withoutIds = withoutIds;
                }
                const res = await keywordsApi.moveKeywords(data, getFetchFilters(getters));

                if (res) {
                    const {
                        data: {
                            data: {
                                existingKeywordsCount = []
                            }
                        }
                    } = res;
                    existingKeywordsCount.forEach(({ id, count }) => {
                        dispatch('updateKeywordsCount', { id, count });
                    });
                }

                dispatch('setSelectedKeywords', []);
                const duplicates = res.data.meta.extra_data.duplicates;
                // success message
                toastr.s(res.data.meta.title);

                // if duplicates
                if (duplicates) {
                    toastr.w(
                        i18n.t('keyword-were-duplicate', {
                            duplicates,
                            total: keywords ? keywords.length : totalKeywords - withoutIds.length,
                        }),
                    );
                }
                dispatch('updateLeftSidebarTags', res.data.data.tags);

                if (getters.getCurrentProject) {
                    await dispatch('fetchKeywords', { id: getters.getCurrentProject.id });
                } else if (getters.getGlobalSearchQuery) {
                    await dispatch('fetchKeywordsFromSearch');
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        async updateKeywordBaseline({ getters, dispatch }, keyword) {
            const projectId = getters.getCurrentProject.id;
            const keywordId = keyword.kwid;
            const keywords = _.cloneDeep(getters.getOriginalItems);
            const i = keywords.findIndex(el => el.id === keywordId);

            if (keyword.start == '') {
                keyword.start = 0;
            }

            if (projectId && keywords[i].start !== keyword.start) {
                const message = i18n.t('baseline-updated');
                const queryData = {
                    data: {
                        type: 'keyword',
                        attributes: {
                            baseline: keyword.start,
                        },
                    },
                };

                try {
                    const res = await keywordsApi.updateBaseline(projectId, keywordId, queryData, { params: getFetchFilters(getters) });

                    EventBus.emit(Events.UPDATE_START_DATE, { projectId, keywordId, queryData });

                    if (res.status === 200) {
                        keywords[i].start = res.data.data.attributes.start;
                        keywords[i].notes = res.data.data.attributes.notes;
                        keywords[i].gpage = res.data.data.attributes.gpage;
                        keywords[i].grank = res.data.data.attributes.grank;
                        keywords[i].best = res.data.data.attributes.best;
                        keywords[i].trends.day = res.data.data.attributes.trends.day;
                        keywords[i].trends.week = res.data.data.attributes.trends.week;
                        keywords[i].trends.month = res.data.data.attributes.trends.month;
                        keywords[i].trends.life = res.data.data.attributes.trends.life;
                        dispatch('setKeywords', keywords);
                        toastr.s(message);
                    }
                } catch (e) {
                    console.warn(e, 'e');
                }
            }
        },
        async fetchKeywordForPdf({ dispatch, commit }, payload) {
            const currentFetch = Date.now();
            const {
                viewkey,
                ...otherProps
            } = payload;

            try {
                commit(SET_KEYWORDS_IS_FETCHING, true);
                dispatch('setLastFetchedRequest', currentFetch);

                const res = await projectsApi.fetchKeywordsForPdf(viewkey, {
                    params: {
                        ...otherProps
                    },
                });

                if (currentFetch !== getters.getLastFetchRequest) {
                    return;
                }

                if (!res.data.errors && res.data.meta?.domains) {
                    commit(SET_LIST_OF_KEYWORDS_DOMAINS, res.data.meta.domains);
                }
                commit(SET_KEYWORDS_IS_FETCHING, false);
                return dispatch('processKeywords', res.data.data);
            } catch (error) {
                console.log(error);
                if (currentFetch === getters.getLastFetchRequest) {
                    commit(SET_KEYWORDS_IS_FETCHING, false);
                }
            }
        },
        setAllCheckboxesSelected({ commit, dispatch, getters }, payload) {
            if (getters.getAllWithoutIdsData.length) {
                dispatch('setAllWithoutIds');
            }

            dispatch('setSelectedKeywords', []);
            commit(SET_ALL_CHECKBOXES_SELECTED, payload);

            if (getters.getIsEditPopupVisible && payload) {
                dispatch('emitEdit');
            }
        },
        setSelectedKeywords({ commit, getters, dispatch }, payload) {
            const originalItems = getters.getOriginalItems;
            const selectedAllData = getters.getSelectedKeywordsWithAllData;
            const selectedKeywordsData = payload.map((item) => originalItems.find((item1) => item1.id === item) || selectedAllData.find((item1) => item1.id === item))
            const dataToStore = {
                selectedAuth: payload,
                allData: selectedKeywordsData,
            }
            commit(SET_SELECTED_KEYWORDS, dataToStore);

            if (getters.getIsEditPopupVisible && payload.length) {
                dispatch('emitEdit');
            }
        },
        async fetchFavoriteData({ getters, dispatch }) {
            try {
                const id = getters.getCurrentProject.project_id;
                const response = await keywordsApi.fetchFavoriteData({ params: getFetchFilters(getters) }, id);
                dispatch('setFavoriteData', response.data.data);
            } catch (e) {
                console.log(e, 'error');
            }
        },
        setFavoriteData({ commit }, payload) {
            commit(SET_FAVORITE_DATA, payload)
        },
        setGlobalSearchQuery({ commit }, payload) {
            commit(SET_GLOBAL_SEARCH_QUERY, payload);
        },
        resetGlobalSearchQuery({ commit }) {
            commit(RESET_GLOBAL_SEARCH_QUERY);
        },
        setAllWithoutIds({ commit, getters, dispatch }, payload) {
            let mergedData = [];
            const storeIds = getters.getAllWithoutIdsData;
            if (payload) {

                if (Array.isArray(payload)) {
                    mergedData = _.uniq([...storeIds, ...payload]);
                } else {
                    mergedData = [...storeIds, payload];
                }

            }
            const data = [...new Set(mergedData)];
            commit(SET_ALL_WITHOUT_IDS, data);

            if (getters.getIsEditPopupVisible) {
                dispatch('emitEdit');
            }
        },
        resetAllWithoutIds({ commit, getters }, payload) {
            let dataToStore = [];
            if (payload) {
                const storeIds = getters.getAllWithoutIdsData;

                if (Array.isArray(payload)) {
                    dataToStore = storeIds.filter((item) => !payload.find((payloadItem) => payloadItem === item));
                } else {
                    dataToStore = storeIds.filter((item) => item !== payload);
                }
            }
            commit(SET_ALL_WITHOUT_IDS, dataToStore);
        },
        setShowKeywordAction({ commit, getters }, payload) {
            if (MOBILE_WIDTH_RESOLUTION_PHONE > window.screen.width) {
                const showKeywordAction = getters.getShowKeywordAction;
                const newData = showKeywordAction === payload ? null : payload;
                commit(SET_SHOW_KEYWORD_ACTION, newData);
            }
        },
        async duplicateKeywordsAction({ dispatch, getters }, { params: payload, exact }) {
            try {
                payload.url = buildUrl(parseUrl(payload.url), exact);
                const currenProject = getters.getCurrentProject;
                const totalKeywords = getters.getTotalKeywords;

                const { data } = await keywordsApi.duplicateKeywords(payload, getFetchFilters(getters));

                if (data.data) {
                    const { to_project: id, quantity: count } = data.data;
                    const duplicates = data.meta.extra_data.duplicates;

                    // if duplicates
                    if (duplicates) {
                        toastr.w(
                            i18n.t('keyword-were-duplicate', {
                                duplicates,
                                total: payload.ids.length || totalKeywords - payload.without_ids.length,
                            }),
                        );
                    }

                    if (id) {
                        dispatch('updateKeywordsCount', { id, count });
                        if (currenProject && !currenProject.isSubproject) {
                            const subProjects = getters.getSubProjectsByParentId(currenProject.project_id);

                            if (currenProject.project_id === id || subProjects.find(item => item.project_id === id)) {
                                dispatch('fetchMetrics');
                                dispatch('fetchKeywords', { id: currenProject.id });
                            }
                        }
                    }
                } else if (!interval) {
                    // @TODO
                    interval = setInterval(() => {
                        dispatch('fetchDuplicationProgressesAction');
                    }, 3000);
                }
            } catch (error) {
                throw error;
            }
        },
        async fetchDuplicationProgressesAction({ dispatch }) {
            try {
                const res = await keywordsApi.fetchDuplicationProgresses();
                const { data = [] } = res.data;
                data.forEach(({ attributes }) => {
                    const { remaining, message, to_project_keywords_count: count, to_project_id: id, duplicates, total } = attributes;
                    if (remaining === 0) {
                        toastr.s(message);

                        // if duplicates
                        if (duplicates) {
                            toastr.w(
                                i18n.t('keyword-were-duplicate', {
                                    duplicates,
                                    total
                                }),
                            );
                        }
                        dispatch('updateKeywordsCount', { id, count });
                    }
                });
                const isExistProgresses = data.some(({ attributes }) => attributes.remaining > 0);
                if (isExistProgresses) {
                    if (!interval) {
                        // @TODO
                        setTimeout(() => {
                            dispatch('fetchDuplicationProgressesAction');
                        }, 3000);
                    }
                } else {
                    clearInterval(interval);
                }
            } catch (error) {
                console.error(error);
            }
        },
        setGatheringTimer({ commit }, payload) {
            commit(SET_GATHERING_TIMER, payload);
        },
        clearGatheringTimer({ dispatch, getters }) {
            clearTimeout(getters.getGatheringTimer);
            dispatch('setGatheringTimer', null);
        },
        async fetchKeywordPosition({ commit, getters }, { projectId, keyword_id }) {
            if (keyword_id) {
                try {
                    commit(SET_KEYWORDS_IS_FETCHING, true);
                    commit(SET_LIST_OF_KEYWORDS_DOMAINS, []);
                    commit(SET_LIST_OF_KEYWORDS_TYPES, []);
                    const queryData = {
                        params: { ...getFetchFilters(getters) }
                    };
                    const res = await keywordsApi.fetchKeywordPosition(projectId, keyword_id, queryData);
                    commit(SET_CURRENT_PAGE, res.data.data.page);
                    return;
                } catch (e) {
                    console.log('e', e);
                }
            }
            return;
        },
        async fetchLastAddedKeyword({ getters, commit }) {
            if (getters.getTotalKeywords === 0) return;
            commit(SET_NEW_ADDED_KEYWORDS, false);
            commit(SET_NEW_ADDED_KEYWORDS_IS_FETCHING, true);
            const params = {
                ...getFetchFilters(getters),
                page: 1,
                per_page: 1,
                sort_direction: 'desc',
                sort_field: 'timestamp',
            }

            if (getters.getFilterByRankingUrl) {
                params.ranking_url = getters.getFilterByRankingUrl;
            }

            try {
                // favorites always come first in the list of results
                // this part makes two requests, since there is no way to ignore the 'favorite' state
                const lastAddedNotFavoriteKeyword = await projectsApi.fetchKeywords(getters.getCurrentProject.id, { params: { ...params, isfav: 0 } });

                if (checkIsKeywordRecentlyAdded(lastAddedNotFavoriteKeyword.data.data[0])) {
                    commit(SET_NEW_ADDED_KEYWORDS_IS_FETCHING, false);
                    return commit(SET_NEW_ADDED_KEYWORDS, true);
                }

                const lastAddedFavoriteKeyword = await projectsApi.fetchKeywords(getters.getCurrentProject.id, { params: { ...params, isfav: 1 } });

                if (checkIsKeywordRecentlyAdded(lastAddedFavoriteKeyword.data.data[0])) {
                    commit(SET_NEW_ADDED_KEYWORDS_IS_FETCHING, false);
                    return commit(SET_NEW_ADDED_KEYWORDS, true);
                }
            } catch (e) {
                console.log(e);
            } finally {
                commit(SET_NEW_ADDED_KEYWORDS_IS_FETCHING, false);
            }
        },
        async saveKeywordSettings({ getters, dispatch }, { setting, value }) {
            const {
                keywordSettings,
            } = prepareKeywordSettings(getters, { setting, value });

            try {
                await usersApi.updateUserCompanySettings({ keywordSettings: keywordSettings });
                toastr.s(i18n.t('saved-changes-msg'));
                dispatch('updateKeywordSettings', keywordSettings);
            } catch (e) {
                console.log(e, 'error')
            }
        },
        updateKeywordSettings({ commit, getters }, payload = {}) {
            const keywordSettings = getters.getKeywordSettings;
            const newSettings = { ...keywordSettings };

            Object.keys(keywordSettings).forEach(key => {
                if (key ==='chartPeriod' && payload?.keyword?.chart?.period) {
                    newSettings[key] = payload.keyword.chart.period;

                    if (getters.getIsFreePlan) {
                        newSettings[key] = Math.min(newSettings[key], 180);
                    } else if (!getters.getIsPackageOver5000) {
                        newSettings[key] = Math.min(newSettings[key], 365);
                    }
                } if (key ==='table_columns' && payload?.keyword?.table_columns) {
                    newSettings[key] = payload?.keyword?.table_columns;
                } else if (typeof payload[key] !== 'undefined') {
                    newSettings[key] = payload[key];
                }
            });

            commit(SET_KEYWORD_SETTINGS, newSettings);
        },
        setFilterByRankingUrl({ commit }, rankingUrl = false) {
            commit(SET_FILTER_BY_RANKING_URL, rankingUrl);
        },
        setTotalKeywordsPages({ commit }, totalKeywordsPages = 0) {
            commit(SET_TOTAL_KEYWORDS_PAGES, totalKeywordsPages);
        },
        setTotalKeywords({ commit }, totalKeywords = 0) {
            commit(SET_TOTAL_KEYWORDS, totalKeywords);
        },
        setIsEditPopupVisible({ commit }, visible = false) {
            commit(SET_IS_EDIT_POPUP_VISIBLE, visible);
        },
        setLastFetchedRequest({ commit }, uuid = false) {
            commit(SET_LAST_FETCH_REQUEST, uuid);
        },
        emitEdit({ getters }) {
            const params = {
                keywordAuth: null,
                multiple: false,
                isAll: false,
            }

            if (getters.getIsAllCheckboxesSelected) {
                params.isAll = true;
                params.keywordAuth = getters.getOriginalItems[0].id;
            } else {
                const checkedItems = getters.getSelectedKeywordsWithAllData;
                params.keywordAuth = checkedItems[0].id;
                params.multiple = checkedItems.length > 1;
            }
            EventBus.emit(Events.SET_EDITABLE_KEYWORDS, params);
        },
    },
};

export default keywords;
