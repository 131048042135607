import { render, staticRenderFns } from "./ProjectWithOverview.vue?vue&type=template&id=c01a292c&scoped=true&"
import script from "./ProjectWithOverview.vue?vue&type=script&lang=js&"
export * from "./ProjectWithOverview.vue?vue&type=script&lang=js&"
import style0 from "./ProjectWithOverview.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ProjectWithOverview.vue?vue&type=style&index=1&id=c01a292c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c01a292c",
  null
  
)

export default component.exports