<template>
    <div class="chart">
        <div
            v-show="!loading"
            :id="`average_rank-chart-${project.id}`"
            class="average_rank__chart"
        />
        <loader v-if="loading" /> 
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import resize from 'vue-resize-directive';
import { multiLineChartConfig } from './config/lineChartConfig';

export default {
    name: 'AverageRankChart',
    directives: {
        resize,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        data() {
            this.$nextTick(() => this.renderChart());
        },
    },
    methods: {
        resizeChart() {
            if (this.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
        
        renderChart() {
            if(!this.data) return;
            this.chart = new Highcharts.chart(
                `average_rank-chart-${this.project.id}`,

                //todo categories not defined
                multiLineChartConfig({
                    categories: this.data?.categories,
                    data: this.data?.series || [],
                    chartStyles: {
                        borderColor: '#DCE4EC',
                        borderWidth: 1,
                        marginTop: 18,
                        borderRadius: 4,
                        plotBorderColor: '#F2F4F7',
                        plotBorderWidth: 1,
                        style: {
                            fontFamily: 'Roboto',
                        },
                    }
                })
            );
        },
    },
    mounted(){
        if(this.data) {
            this.renderChart();
        }
    }
};
</script>

<style lang="scss" scoped>
    .average_rank__chart{
        height: 100%;
        width: 100%;
    }
    .chart {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
