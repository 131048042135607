import {getRangeTitle, processChardData} from '@/helpers/process-metrics';
import {metricsEmptyData} from '../utils';
import apiFactory from '@/api';
const metricsApi = apiFactory.get('metrics');

import {
    SET_OVERVIEW_CHARTS_DATA_LOADING,
    SET_OVERVIEW_CHARTS_DATA_PAGE,
    SET_OVERVIEW_CHARTS_DATA,
    SET_OVERVIEW_CHARTS_TOTAL_ITEMS,
    SET_OVERVIEW_CHARTS_TOTAL_PAGES,
} from '../mutations';

export const expandedOverviewPersistedKeys = ['expandedOverview.overviewChartsDataParams'];

const expandedOverview = {
    state: {
        overviewChartsData: [],
        overviewChartsDataLoading: false,
        overviewChartsTotalItems: 0,
        overviewChartsTotalPages: 0,
        overviewChartsDataParams: {
            page: 1,
            include: 'chart',
            per_page: 5,
        },
    },
    mutations: {
        [SET_OVERVIEW_CHARTS_DATA]: (state, payload) => state.overviewChartsData = payload,
        [SET_OVERVIEW_CHARTS_DATA_LOADING]: (state, payload) => state.overviewChartsDataLoading = payload,
        [SET_OVERVIEW_CHARTS_DATA_PAGE]: (state, payload) => state.overviewChartsDataParams.page = payload,
        [SET_OVERVIEW_CHARTS_TOTAL_ITEMS]: (state, payload) => state.overviewChartsTotalItems = payload,
        [SET_OVERVIEW_CHARTS_TOTAL_PAGES]: (state, payload) => state.overviewChartsTotalPages = payload,
    },
    getters: {
        getOverviewChartsData: state => state.overviewChartsData,
        getOverviewChartsDataLoading: state => state.overviewChartsDataLoading,
        getOverviewChartsDataParams: state => state.overviewChartsDataParams,
        getOverviewChartsTotalItems: state => state.overviewChartsTotalItems,
        getOverviewChartsTotalPages: state => state.overviewChartsTotalPages,
        getProjectsWithOverviewChartsData: (state, getters) => {
            const {getOverviewChartsData, getDisplaySetting} = getters;

            // in case of overview data is empty or fetching - show gap
            if (!getOverviewChartsData.length) {
                const mockData =[];
                for(let i = 0; i < getters.getOverviewChartsDataParams.per_page; i++) {
                    mockData.push({
                        loading: true, 
                        ...metricsEmptyData(),
                    });
                }
                return mockData;
            }

            // modify (merge) projects array and overview data (charts, metrics)
            return getOverviewChartsData.map(item => {
                const metricsRange = index => getDisplaySetting('metrics_range')[index];
                let chartData = processChardData(item.attributes.chart);
                return {
                    ...item.attributes,
                    id: item.id,
                    name: item.id,
                    charts: chartData.map(chart => ({
                        title: chart[0],
                        data: chart[1],
                    })),
                    ranges: item.attributes.ranges.map((el, i) => ({
                        ...el,
                        range: metricsRange(i),
                        title: getRangeTitle(metricsRange(i)),
                    })),
                };
            });
        },
    },
    actions: {
        expandedOverviewPageChanged ({getters, commit}, payload) {
            switch (payload) {
                case 'next':
                    commit(SET_OVERVIEW_CHARTS_DATA_PAGE, getters.getOverviewChartsDataParams.page + 1);
                    break;
                case 'prev':
                    commit(SET_OVERVIEW_CHARTS_DATA_PAGE, getters.getOverviewChartsDataParams.page - 1);
                    break;
                default:
                    commit(SET_OVERVIEW_CHARTS_DATA_PAGE, payload);
                    break;
            }
        },
        async fetchOverviewChartsData ({commit, getters}) {
            commit(SET_OVERVIEW_CHARTS_DATA_LOADING, true);
            commit(SET_OVERVIEW_CHARTS_DATA, []);
            const queryParams = {params: {...getters.getOverviewChartsDataParams}};

            try {
                const res = await metricsApi.fetchChartsMetrics(queryParams);
                const {data, meta} = res.data;
                commit(SET_OVERVIEW_CHARTS_DATA, data);
                commit(SET_OVERVIEW_CHARTS_TOTAL_ITEMS, meta.pagination.total);
                commit(SET_OVERVIEW_CHARTS_TOTAL_PAGES, meta.pagination.total_pages);
            } catch (error) {
                //
            } finally {
                commit(SET_OVERVIEW_CHARTS_DATA_LOADING, false);
            }
        },
    }
};

export default expandedOverview;
