import {switchDateFormat} from '@/helpers/format-date-service';

const getDayFromNow = (format, daysCount = 1) => {
    const d = new Date();
    d.setDate(d.getDate() - daysCount);
    return format ? switchDateFormat(d, format) : d;
};

const getFirstDayOfWeek = (format) => {
    const curr = new Date();
    const day = curr.getDay();
    const diff = curr.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    const firstday = new Date(curr.setDate(diff));

    return format ? switchDateFormat(firstday, format) : firstday;
};

const getLastDayOfWeek = (format) => {
    const curr = new Date;
    const day = curr.getDay();
    const diff = curr.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    const last = diff + 6;
    const lastday = new Date(curr.setDate(last));
    return format ? switchDateFormat(lastday, format) : lastday;
};

const getFirstDayOfMonth = (format, count) => {
    const date = new Date();
    const month = count ? new Date(date.getFullYear(), date.getMonth() - count, 1) : date;
    const res = new Date(month.getFullYear(), month.getMonth(), 1);
    return format ? switchDateFormat(res, format) : res;
};

const getLastDayOfMonth = (format, count) => {
    const date = new Date();
    const month = count ? new Date(date.getFullYear(), date.getMonth() - count, 1) : date;
    const res = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    return format ? switchDateFormat(res, format) : res;
};

function getMonthsBeforeDate(format, monthsToSubtract) {
    const newDate = new Date();
    
    const originalMonth = newDate.getMonth();
    const originalYear = newDate.getFullYear();
    const originalDay = newDate.getDate();
    
    let targetMonth = originalMonth - monthsToSubtract;
    let targetYear = originalYear;
    
    while (targetMonth < 0) {
        targetMonth += 12;
        targetYear -= 1;
    }
    
    newDate.setFullYear(targetYear, targetMonth, originalDay);
    
    return format ? switchDateFormat(newDate, format) : newDate;
}

export const datePickerList = {
    today: { label: 'Today', value: [switchDateFormat(new Date(), 'Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 0 },
    yesterday: { label: 'Yesterday', value: [getDayFromNow('Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 1 },
    this_week: { label: 'This week', value: [getDayFromNow('Y-m-d', 6), switchDateFormat(new Date(), 'Y-m-d')], count: 7 },
    this_month: { label: 'This month', value: [getFirstDayOfMonth('Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 15 },
    last_month: { label: 'Last month', value: [getMonthsBeforeDate('Y-m-d', 1), switchDateFormat(new Date(), 'Y-m-d')], count: 30 },
    last_3_months: { label: 'Last 3 months', value: [getMonthsBeforeDate('Y-m-d', 3), switchDateFormat(new Date(), 'Y-m-d')], count: 90 },
    last_6_months: { label: 'Last 6 months', value: [getMonthsBeforeDate('Y-m-d', 6), switchDateFormat(new Date(), 'Y-m-d')], count: 180 },
    last_year: { label: 'Last year', value: [getMonthsBeforeDate('Y-m-d', 12), switchDateFormat(new Date(), 'Y-m-d')], count: 365 },
    all_time: { label: 'All time', value: [switchDateFormat(new Date('2010-01-01'), 'Y-m-d'), switchDateFormat(new Date(), 'Y-m-d')], count: 666666 }
};

export const exactDatePickerList = {
    today: { label: 'Today', value: switchDateFormat(new Date(), 'Y-m-d'), altLabel: 'today', count: 0 },
    yesterday: { label: 'Yesterday', value: getDayFromNow('Y-m-d'), altLabel: 'daily', count: 1 },
    this_week: { label: 'This week', value: getDayFromNow('Y-m-d', 6), altLabel: 'weekly', count: 7 },
    this_month: { label: 'This month', value: getFirstDayOfMonth('Y-m-d'), altLabel: 'thismonth', count: 15 },
    last_month: { label: 'Last month', value: getMonthsBeforeDate('Y-m-d', 1), altLabel: 'monthly', count: 30 },
    last_3_months: { label: 'Last 3 months', value: getMonthsBeforeDate('Y-m-d', 3), altLabel: '3months', count: 90 },
    last_6_months: { label: 'Last 6 months', value: getMonthsBeforeDate('Y-m-d', 6), altLabel: 'semester', count: 180},
    last_year: { label: 'Last year', value: getMonthsBeforeDate('Y-m-d', 12), altLabel: 'yearly', count: 365 },
    all_time: { label: 'All time', value: switchDateFormat(new Date('2010-01-01'), 'Y-m-d'), altLabel: 'life', count: 666666 }
};
