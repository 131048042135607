import i18n from '@/i18n';
import { intercomEvents, EventBus } from '@/events';
import { cookieGetters } from '@/helpers/cookieManager';

export default ({ $store }) => {
    const { email = '' } = cookieGetters.getUserProfile() || {};
    const name = $store.getters.getAccountSetting('name');

    const {
        intercomUserId = '',
        intercomHash = '',
        createdAtTs = '',
        accessLevel = '',
        addonMasterEmail = '',
    } = $store.getters.getUserData;

    const newsletter_subscription = $store.getters.getUserSetting('newsletter_subscription');

    const { subscription = {} } = $store.getters.getCurrentPlan;
    const {
        package_name: packageName = '',
        package: packageLimit = 0,
        active = 0,
        billing_cycle: billingCycle = '',
        nextpay: nextPay = 0,
        next_billing_at_ts = '',
        expire_post_trial = false,
    } = subscription;

    const settings = {
        active: active,
        addon_masteremail: addonMasterEmail,
        app_id: `${process.env.MIX_INTERCOM_APP_ID}`,
        billingcycle: billingCycle,
        created_at: createdAtTs,
        email: email,
        hide_default_launcher: window.innerWidth < 1024,
        notes: $store.getters.isMasterUser ? 'Main Account' : _.upperFirst(accessLevel),
        package_limit: packageLimit,
        package_name: active === 2 && expire_post_trial ? 'PACKAGE 200 DAILY' : packageName,
        stripe_subscription_status: active === 2 ? 'trialing' : '',
        package_price: nextPay,
        package_used: $store.getters.getUsedKeywords,
        subscription: next_billing_at_ts,
        user_hash: intercomHash,
        user_id: intercomUserId,
        newsletter_subscription,
        ...cookieGetters.getUtmAndMarketingParameters()
    };

    // name property should be present only in master account
    if ($store.getters.isMasterUser) {
        settings.name = name;
    }

    if (window.Intercom) {
        window.Intercom('boot', settings);

        window.Intercom('onUnreadCountChange', count => {
            if (count) {
                window.Intercom('update', { hide_default_launcher: false });
            }
        });
    }

    Object.keys(intercomEvents).map(
        key => EventBus.on(
            intercomEvents[key],
            data => {
                switch (key) {
                    case 'USER_ONBOARD':
                        let settings = {
                            onboarding_seo_goal: i18n.t(`tutorial.seoGoal.values.${data.data.seoGoal}`),
                            onboarding_previous_tracking_method: i18n.t(`tutorial.previous.values.${data.data.previous}`),
                            onboarding_source: data.data.source ? i18n.t(`tutorial.source.values.${data.data.source}`) : '',
                            onboarding_details: data.data.details,
                            onboarding_finished: true,
                        };

                        window.Intercom('update', settings);
                        window.Intercom('trackEvent', key, settings);
                        break;

                    default:
                        window.Intercom('trackEvent', key, data ? { metadata: JSON.stringify(data) } : null);
                        break;
                }
            }
        )
    );
};
