import i18n from '@/i18n';

const tabs = [
    {
        label: i18n.t('billing'),
        routeName: 'billingSetup',
        active: true,
    },
    // {
    //     label: i18n.t('your-plan'),
    //     routeName: 'billingPlan',
    //     active: true,
    // },
    {
        label: i18n.t('invoices'),
        routeName: 'billingInvoices',
        active: true,
    },
    {
        label: i18n.t('cancel-account'),
        routeName: 'billingAccount',
        active: false,
    },
];

const packageColors = [
    {
        status: 'success',
        background: '#AAE4AA',
        lines: '#00A900',
    },
    {
        status: 'warning',
        background: '#FFF2E6',
        lines: '#FB7B05',
    },
    {
        status: 'error',
        background: '#FFB6AC',
        lines: '#E41D02',
    },
    {
        status: 'trial',
        background: '#7c5295',
        lines: '#3c1361',
    },
    {
        status: 'na',
        background: '#CBD6E9',
        lines: '#4180C6',
    },
];

const taxTypes = {
    AU: [
        {
            countryName: "Australia",
            taxId: "au_abn",
            description: "Australian Business Number (AU ABN)",
            placeholder: "12345678912",
            regExp: /^\d{11}$/
        },
        {
            countryName: "Australia",
            taxId: "au_arn",
            description: "Australian Taxation Office Reference Number",
            placeholder: "123456789123",
            regExp: /^\d{12}$/
        }
    ],
    AT: [
        {
            countryName: "Austria",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "ATU12345678",
            regExp: /^[A-Z]{3}\d{8}$/
        }
    ],
    BE: [
        {
            countryName: "Belgium",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "BE0123456789",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    BR: [
        {
            countryName: "Brazil",
            taxId: "br_cnpj",
            description: "Brazilian CNPJ number",
            placeholder: "01.234.456/5432-10",
            regExp: /^\d{2}.\d{3}.\d{3}[/]\d{4}-\d{2}$/
        },
        {
            countryName: "Brazil",
            taxId: "br_cpf",
            description: "Brazilian CPF number",
            placeholder: "123.456.789-87",
            regExp: /^\d{3}.\d{3}.\d{3}-\d{2}$/
        }
    ],
    BG: [
        {
            countryName: "Bulgaria",
            taxId: "bg_uic",
            description: "Bulgaria Unified Identification Code",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        },
        {
            countryName: "Bulgaria",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "BG0123456789",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    CA: [
        {
            countryName: "Canada",
            taxId: "ca_bn",
            description: "Canadian BN",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        },
        {
            countryName: "Canada",
            taxId: "ca_gst_hst",
            description: "Canadian GST/HST number",
            placeholder: "123456789RT0002",
            regExp: /^\d{9}[A-Z]{2}\d{4}$/
        },
        {
            countryName: "Canada",
            taxId: "ca_pst_bc",
            description: "Canadian PST number (British Columbia)",
            placeholder: "PST-1234-5678",
            regExp: /^[A-Z]{3}-\d{4}-\d{4}$/
        },
        {
            countryName: "Canada",
            taxId: "ca_pst_mb",
            description: "Canadian PST number (Manitoba)",
            placeholder: "123456-7",
            regExp: /^\d{6}-\d$/
        },
        {
            countryName: "Canada",
            taxId: "ca_pst_sk",
            description: "Canadian PST number (Saskatchewan)",
            placeholder: "1234567",
            regExp: /^\d{7}$/
        },
        {
            countryName: "Canada",
            taxId: "ca_qst",
            description: "Canadian QST number (Québec)",
            placeholder: "1234567890TQ1234",
            regExp: /^\d{10}[A-Z]{2}\d{4}$/
        },
    ],
    CL: [
        {
            countryName: "Chile",
            taxId: "cl_tin",
            description: "Chilean TIN",
            placeholder: "12.345.678-K",
            regExp: /^\d{2}.\d{3}.\d{3}-[A-Z]$/
        }
    ],
    HR: [
        {
            countryName: "Croatia",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "HR12345678912",
            regExp: /^[A-Z]{2}\d{11}$/
        }
    ],
    CY: [
        {
            countryName: "Cyprus",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "CY12345678Z",
            regExp: /^[A-Z]{2}\d{8}[A-Z]$/
        }
    ],
    CZ: [
        {
            countryName: "Czech Republic",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "CZ1234567890",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    DK: [
        {
            countryName: "Denmark",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "DK12345678",
            regExp: /^[A-Z]{2}\d{8}$/
        }
    ],
    EG: [
        {
            countryName: "Egypt",
            taxId: "eg_tin",
            description: "Egyptian Tax Identification Number",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        }
    ],
    EE: [
        {
            countryName: "Estonia",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "EE123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        }
    ],
    FI: [
        {
            countryName: "Finland",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "FI12345678",
            regExp: /^[A-Z]{2}\d{8}$/
        }
    ],
    FR: [
        {
            countryName: "France",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "FRAB123456789",
            regExp: /^[A-Z]{4}\d{9}$/
        }
    ],
    GE: [
        {
            countryName: "Georgia",
            taxId: "ge_vat",
            description: "Georgian VAT",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        }
    ],
    DE: [
        {
            countryName: "Germany",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "DE123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        }
    ],
    GR: [
        {
            countryName: "Greece",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "EL123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        }
    ],
    HK: [
        {
            countryName: "Hong Kong",
            taxId: "hk_br",
            description: "Hong Kong BR number",
            placeholder: "12345678",
            regExp: /^\d{8}$/
        }
    ],
    HU: [
        {
            countryName: "Hungary",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "HU12345678912",
            regExp: /^[A-Z]{2}\d{11}$/
        },
        {
            countryName: "Hungary",
            taxId: "hu_tin",
            description: "Hungary tax number (adószám)",
            placeholder: "12345678-1-23",
            regExp: /^\d{8}-\d-\d{2}$/
        }
    ],
    IS: [
        {
            countryName: "Iceland",
            taxId: "is_vat",
            description: "Icelandic VAT",
            placeholder: "123456",
            regExp: /^\d{6}$/
        }
    ],
    IN: [
        {
            countryName: "India",
            taxId: "in_gst",
            description: "Indian GST number",
            placeholder: "12ABCDE3456FGZH",
            regExp: /^\d{2}[A-Z]{5}\d{4}[A-Z]{4}$/
        }
    ],
    ID: [
        {
            countryName: "Indonesia",
            taxId: "id_npwp",
            description: "Indonesian NPWP number",
            placeholder: "12.345.678.9-012.345",
            regExp: /^\d{2}.\d{3}.\d{3}.\d-\d{3}.\d{3}$/
        }
    ],
    IE: [
        {
            countryName: "Ireland",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "IE1234567AB",
            regExp: /^[A-Z]{2}\d{7}[A-Z]{2}$/
        }
    ],
    IL: [
        {
            countryName: "Israel",
            taxId: "il_vat",
            description: "Israel VAT",
            placeholder: "000012345",
            regExp: /^\d{9}$/
        }
    ],
    IT: [
        {
            countryName: "Italy",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "IT12345678912",
            regExp: /^[A-Z]{2}\d{11}$/
        }
    ],
    JP: [
        {
            countryName: "Japan",
            taxId: "jp_cn",
            description: "Japanese Corporate Number (*Hōjin Bangō*)",
            placeholder: "1234567891234",
            regExp: /^\d{13}$/
        },
        {
            countryName: "Japan",
            taxId: "jp_rn",
            description: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
            placeholder: "12345",
            regExp: /^\d{5}$/
        },
        {
            countryName: "Japan",
            taxId: "jp_trn",
            description: "Japanese Tax Registration Number (*Tōroku Bangō*)",
            placeholder: "T1234567891234",
            regExp: /^[A-Z]\d{13}$/
        }
    ],
    KE: [
        {
            countryName: "Kenya",
            taxId: "ke_pin",
            description: "Kenya Revenue Authority Personal Identification Number",
            placeholder: "P000111111A",
            regExp: /^[A-Z]\d{9}[A-Z]$/
        }
    ],
    LV: [
        {
            countryName: "Latvia",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "LV12345678912",
            regExp: /^[A-Z]{2}\d{11}$/
        }
    ],
    LI: [
        {
            countryName: "Liechtenstein",
            taxId: "li_uid",
            description: "Liechtensteinian UID number",
            placeholder: "CHE123456789",
            regExp: /^[A-Z]{3}\d{9}$/
        }
    ],
    LT: [
        {
            countryName: "Lithuania",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "LT123456789123",
            regExp: /^[A-Z]{2}\d{12}$/
        }
    ],
    LU: [
        {
            countryName: "Luxembourg",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "LU12345678",
            regExp: /^[A-Z]{2}\d{8}$/
        }
    ],
    MY: [
        {
            countryName: "Malaysia",
            taxId: "my_frp",
            description: "Malaysian FRP number",
            placeholder: "12345678",
            regExp: /^\d{8}$/
        },
        {
            countryName: "Malaysia",
            taxId: "my_itn",
            description: "Malaysian ITN",
            placeholder: "C 1234567890",
            regExp: /^[A-Z] \d{10}$/
        },
        {
            countryName: "Malaysia",
            taxId: "my_sst",
            description: "Malaysian SST number",
            placeholder: "A12-3456-78912345",
            regExp: /^[A-Z]\d{2}-\d{4}-\d{8}$/
        },
    ],
    MT: [
        {
            countryName: "Malta",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "MT12345678",
            regExp: /^[A-Z]{2}\d{8}$/
        }
    ],
    MX: [
        {
            countryName: "Mexico",
            taxId: "mx_rfc",
            description: "Mexican RFC number",
            placeholder: "ABC010203AB9",
            regExp: /^[A-Z]{3}\d{6}[A-Z]{2}\d$/
        }
    ],
    NL: [
        {
            countryName: "Netherlands",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "NL123456789B12",
            regExp: /^[A-Z]{2}\d{9}[A-Z]\d{2}$/
        }
    ],
    NZ: [
        {
            countryName: "New Zealand",
            taxId: "nz_gst",
            description: "New Zealand GST number",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        }
    ],
    NO: [
        {
            countryName: "Norway",
            taxId: "no_vat",
            description: "Norwegian VAT number",
            placeholder: "123456789MVA",
            regExp: /^\d{9}[A-Z]{3}$/
        }
    ],
    PH: [
        {
            countryName: "Philippines",
            taxId: "ph_tin",
            description: "Philippines Tax Identification Number",
            placeholder: "123456789012",
            regExp: /^\d{12}$/
        }
    ],
    PL: [
        {
            countryName: "Poland",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "PL1234567890",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    PT: [
        {
            countryName: "Portugal",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "PT123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        }
    ],
    RO: [
        {
            countryName: "Romania",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "RO1234567891",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    RU: [
        {
            countryName: "Russia",
            taxId: "ru_inn",
            description: "Russian INN",
            placeholder: "1234567891",
            regExp: /^\d{10}$/
        },
        {
            countryName: "Russia",
            taxId: "ru_kpp",
            description: "Russian KPP",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        }
    ],
    SA: [
        {
            countryName: "Saudi Arabia",
            taxId: "sa_vat",
            description: "Saudi Arabia VAT",
            placeholder: "123456789012345",
            regExp: /^\d{15}$/
        }
    ],
    SG: [
        {
            countryName: "Singapore",
            taxId: "sg_gst",
            description: "Singaporean GST",
            placeholder: "M12345678X",
            regExp: /^[A-Z]\d{8}[A-Z]$/
        },
        {
            countryName: "Singapore",
            taxId: "sg_uen",
            description: "Singaporean UEN",
            placeholder: "123456789F",
            regExp: /^\d{9}[A-Z]$/
        }
    ],
    SK: [
        {
            countryName: "Slovakia",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "SK1234567891",
            regExp: /^[A-Z]{2}\d{10}$/
        }
    ],
    SI: [
        {
            countryName: "Slovenia",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "SI12345678",
            regExp: /^[A-Z]{2}\d{8}$/
        },
        {
            countryName: "Slovenia",
            taxId: "si_tin",
            description: "Slovenia tax number (davčna številka)",
            placeholder: "12345678",
            regExp: /^\d{8}$/
        },
    ],
    ZA: [
        {
            countryName: "South Africa",
            taxId: "za_vat",
            description: "South African VAT number",
            placeholder: "4123456789",
            regExp: /^\d{10}$/
        }
    ],
    KR: [
        {
            countryName: "South Korea",
            taxId: "kr_brn",
            description: "Korean BRN",
            placeholder: "123-45-67890",
            regExp: /^\d{3}-\d{2}-\d{5}$/
        }
    ],
    ES: [
        {
            countryName: "Spain",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "ESA1234567Z",
            regExp: /^[A-Z]{3}\d{7}[A-Z]$/
        },
        {
            countryName: "Spain",
            taxId: "es_cif",
            description: "Spanish NIF number (previously Spanish CIF number)",
            placeholder: "A12345678",
            regExp: /^[A-Z]\d{8}$/
        },

    ],
    SE: [
        {
            countryName: "Sweden",
            taxId: "eu_vat",
            description: "European VAT number",
            placeholder: "SE123456789123",
            regExp: /^[A-Z]{2}\d{12}$/
        }
    ],
    CH: [
        {
            countryName: "Switzerland",
            taxId: "ch_vat",
            description: "Switzerland VAT number",
            placeholder: "CHE-123.456.789 MWST",
            regExp: /^[A-Z]{3}-\d{3}.\d{3}.\d{3}\s[A-Z]{4}$/
        }
    ],
    TW: [
        {
            countryName: "Taiwan",
            taxId: "tw_vat",
            description: "Taiwanese VAT",
            placeholder: "12345678",
            regExp: /^\d{8}$/
        }
    ],
    TH: [
        {
            countryName: "Thailand",
            taxId: "th_vat",
            description: "Thai VAT",
            placeholder: "1234567891234",
            regExp: /^\d{13}$/
        }
    ],
    TR: [
        {
            countryName: "Turkey",
            taxId: "tr_tin",
            description: "Turkish Tax Identification Number",
            placeholder: "0123456789",
            regExp: /^\d{10}$/
        }
    ],
    UA: [
        {
            countryName: "Ukraine",
            taxId: "ua_vat",
            description: "Ukrainian VAT",
            placeholder: "123456789",
            regExp: /^\d{9}$/
        }
    ],
    AE: [
        {
            countryName: "United Arab Emirates",
            taxId: "ae_trn",
            description: "United Arab Emirates TRN",
            placeholder: "123456789012345",
            regExp: /^\d{15}$/
        }
    ],
    GB: [
        {
            countryName: "United Kingdom",
            taxId: "eu_vat",
            description: "Northern Ireland VAT number",
            placeholder: "XI123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        },
        {
            countryName: "United Kingdom",
            taxId: "gb_vat",
            description: "United Kingdom VAT number",
            placeholder: "GB123456789",
            regExp: /^[A-Z]{2}\d{9}$/
        }
    ],
    US: [
        {
            countryName: "United States",
            taxId: "us_ein",
            description: "United States EIN",
            placeholder: "12-3456789",
            regExp: /^\d{2}-\d{7}$/
        }
    ],
}

const getInvoicesColumns = [
    { 'title': i18n.t('date') },
    { 'title': i18n.t('invoice-amount') },
    { 'title': '' },
];

export { tabs, packageColors, getInvoicesColumns, taxTypes };
