<template>
    <div class="dashboard-table-wrap">
        <template v-if="isMetricsDisplayed">
            <template v-if="this.$route.name === 'keywordList'">
                <div class="metrics-wrapper">
                    <div class="metrics-block">
                        <div class="metrics-header">
                            <chart-header
                                :title="$t('available-chart-titles.project_metrics')"
                                :toolTip="$t('project_metrics-tooltip')"
                            />
                            <p v-if="getCurrentProject" class="secondary-text">
                                {{ $t('comparison-timeframe') }}
                            </p>
                            <date-ranges-dropdown showDatePickerList type="trending" :value="metricsDate" @onSelect="onMetricsDateSelect" :hiddenDays="hiddenDays" />
                        </div>
                        <metrics
                            :data="getMetricsData"
                            :loading="getMetricsIsFetching"
                            type="chart"
                            showPercentage
                        />
                    </div>
                    <div v-if="showCharts" class="charts-block">
                        <div class="chart-container">
                            <chart-header
                                :title="$t('available-chart-titles.visibility')"
                                :toolTip="$t('visibility-tooltip')"
                                showTimeframe
                                :date="date"
                                @onDateSelect="onDateSelect"
                                :defaultKey="interval"
                            />
                            <visibility-chart
                                v-if="getCurrentProject"
                                :key="`visibility_${chartKey}`"
                                :project="getCurrentProject"
                                :loading="getProjectVisibility.loading"
                                :data="getProjectVisibility.data"
                            />
                        </div>

                        <div class="chart-container">
                            <chart-header
                                :title="$t('available-chart-titles.estimated_traffic')"
                                :toolTip="$t('estimated_traffic-tooltip')"
                            />

                            <estimated-traffic-chart
                                v-if="getCurrentProject"
                                :key="`estimated_traffic_${chartKey}`"
                                :project="getCurrentProject"
                                :loading="getProjectEstimatedTraffic.loading"
                                :data="getProjectEstimatedTraffic.data"
                                :timeframe="date"
                            />                            
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="!getViewkeyView">
                <metrics
                    :data="getMetricsData"
                    :loading="getMetricsIsFetching"
                />
            </template>

            <chart-and-metrics
                v-else
                :loading="getMetricsAndChartIsFetching"
                :project="getProjectWithOverviewChartsData"
            />
        </template>

        <shared-project :overviewTabIsDisplayed="overviewTabIsDisplayed" v-if="getViewkeyView" />
        <div v-else class="project-keywords-and-charts-wrapper">
            <project-keywords-and-charts />
        </div>

        <edit-keywords-popup
            v-if="editKeywordsPopup.isVisible"
            :editable-keyword="editKeywordsPopup.editableKeyword"
            :multiple="editKeywordsPopup.multiple"
            :is-all="editKeywordsPopup.isAll"
            :updatable-keywords-array="getEditableItem"
            @unset-editable-keyword="unsetEditableKeyword"
        />
    </div>
</template>

<script>
import { Events, EventBus } from '@/events';
import { mapActions, mapGetters } from 'vuex';
import ChartAndMetrics from 'components/common/ChartAndMetrics';
import Metrics from 'components/metrics/Metrics';
import ProjectKeywordsAndCharts from './ProjectKeywordsAndCharts';
const EditKeywordsPopup = () =>
    import('components/main-table/EditKeywordsPopup');
const MoveKeywordsModal = () =>
    import('components/main-table/MoveKeywordsModal');
import { processKeywords } from '@/store/utils';
import {
    MOBILE_WIDTH_RESOLUTION_PHONE,
} from '@/constants';
import VisibilityChart from './Charts/VisibilityChart.vue';
import EstimatedTrafficChart from './Charts/EstimatedTrafficChart.vue';
import ChartBlockHeader from './Charts/ChartBlockHeader.vue';
import SharedProject from './SharedProject.vue';
import { projectMetricsDateDropdownRanges } from '@/components/metrics/configs'
import DateRangesDropdown from '@/components/common/DateRangesDropdown.vue';
import { datePickerList, exactDatePickerList } from '@/components/date-range-picker/datePickerList';

export default {
    name: 'ProjectWithOverview',
    components: {
        'chart-and-metrics': ChartAndMetrics,
        'edit-keywords-popup': EditKeywordsPopup,
        metrics: Metrics,
        'project-keywords-and-charts': ProjectKeywordsAndCharts,
        'visibility-chart': VisibilityChart,
        'estimated-traffic-chart': EstimatedTrafficChart,
        'chart-header': ChartBlockHeader,
        'shared-project': SharedProject,
        'date-ranges-dropdown': DateRangesDropdown
    },
    data() {
        return {
            editKeywordsPopup: {
                isVisible: false,
                editableKeyword: null,
                multiple: false,
            },
            date: null,
            dateRanges: projectMetricsDateDropdownRanges,
            metricsDate: 'Last month',
            isLoading: false,
            hiddenDays: ['today', 'this_month', 'last_3_months'],
            interval: null,
        };
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'getViewkeyView',
            'getMetricsData',
            'getOriginalItems',
            'getDisplaySetting',
            'getCurrentProject',
            'getPermissionData',
            'getSelectedKeywords',
            'isAdminOrMasterUser',
            'getMetricsIsFetching',
            'getGlobalSearchQuery',
            'getIsAllCheckboxesSelected',
            'getSortedAndFilteredKeywords',
            'getMetricsAndChartIsFetching',
            'getSelectedKeywordsWithAllData',
            'getProjectWithOverviewChartsData',
            'getIsMindshareDataCalculationInProgress',
            'getProjectEstimatedTraffic',
            'getProjectVisibility',
            'getDisplaySettings',
            'getProjectOverviewOptionItem',
            'getOtherSetting'
        ]),
        // SHOW_GSC_SERVICE() {
        //     return process.env.SHOW_GSC_SERVICE == 'true';
        // },
        // getSuggestionsCount() {
        //     return this.getGSCProfileCurrentConnection &&
        //         this.getGSCSuggestions?.length
        //         ? `<span style="color: #0074D9;">(${this.getGSCSuggestions.length})</span>`
        //         : '';
        // },
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        isMobileView() {
            return MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width;
        },
        getEditableItem() {
            const data = this.editKeywordsPopup.multiple
                ? this.getSelectedKeywordsWithAllData
                : this.editKeywordsPopup.isAll
                ? this.getSortedAndFilteredKeywords(this.getOriginalItems)
                : [this.editKeywordsPopup.editableKeyword];
            return data;
        },
        overviewTabIsDisplayed() {
            return (
                (!this.isSearch && !this.getViewkeyView) ||
                (this.getViewkeyView &&
                    this.getCurrentProject?.sov_view_key &&
                    this.getPermissionData('share_of_voice'))
            );
        },
        isMetricsDisplayed() {
            return (
                !this.getGlobalSearchQuery &&
                this.getDisplaySetting('metrics') !== 'hide'
            );
        },
        showCharts(){
            return (
                this.getOtherSetting('rankingAreaChart') !== 'hide'
            );
        }
    },
    watch: {
        getActiveTag() {
            this.setShownChart({ keyword: null, project: null });
            if (this.getCurrentProject) {
                this.fetchVisibilityAndEstimatedTrafficData();
            }
        },
    },
    async created() {
        EventBus.on(Events.SET_EDITABLE_KEYWORDS, this.setEditableKeyword);
        EventBus.on(Events.SET_KEYWORDS_TO_MOVE, this.setKeywordsToMove);
    },
    mounted() {
        const metricsValue = this.getDisplaySetting('metrics');
        if(metricsValue){
            const dateKey = Object.entries(exactDatePickerList).find(([key, val]) => val.altLabel === metricsValue)?.[0];
            if(dateKey){
                this.metricsDate = exactDatePickerList[dateKey].label;
            }
        }

        if (this.getCurrentProject &&
            ((this.getProjectEstimatedTraffic.timeframe !== this.date ||
            this.getProjectVisibility.timeframe !== this.date) ||
            (this.getProjectVisibility.data === null &&
            this.getProjectEstimatedTraffic.data === null))) {
            this.fetchVisibilityAndEstimatedTrafficData();

        }
    },
    beforeDestroy() {
        EventBus.off(Events.SET_EDITABLE_KEYWORDS, this.setEditableKeyword);
        EventBus.off(Events.SET_KEYWORDS_TO_MOVE, this.setKeywordsToMove);
    },
    methods: {
        ...mapActions([
            'setShownChart',
            'setIsEditPopupVisible',
            'fetchProjectVisibility',
            'fetchProjectEstimatedTraffic',
            'updateOnlyDisplaySettings',
            'updateProjectTimeFrame'
        ]),
        /**
         * If a single category is being edited, then all its data is transferred to the popup
         * Else if a multiple category is being edited, then empty fields (except parent category name)
         * is transferred to the popup
         */
        setEditableKeyword(params) {
            const { keywordAuth, multiple, isAll = false } = params;
            let originalUrls = '';
            let languages = '';
            let regions = '';
            let urls = '';
            let near = '';
            let gmb = '';

            const processedKeywords = processKeywords(
                this.$store.getters,
                this.getSelectedKeywordsWithAllData,
            );

            if (multiple) {
                originalUrls = _.uniq(
                    processedKeywords.map(kw => kw.originalUrl),
                );
                languages = _.uniq(processedKeywords.map(kw => kw.language));
                regions = _.uniq(processedKeywords.map(kw => kw.region));
                urls = _.uniq(processedKeywords.map(kw => kw.fullUrl));
                near = _.uniq(processedKeywords.map(kw => kw.near));
                gmb = _.uniq(processedKeywords.map(kw => kw.gmb));
            }

            const editableKeyword = processedKeywords.find(
                el => el.id === keywordAuth,
            );
            const emptyKeyword = {
                originalUrl: originalUrls.length === 1 ? originalUrls[0] : '',
                category: editableKeyword ? editableKeyword.category : '',
                language: languages.length === 1 ? languages[0] : null,
                ignore_local: processedKeywords.every(d => d.ignore_local),
                included_in_sov: true,
                region: regions.length === 1 ? regions[0] : '',
                near: near.length === 1 ? near[0] : '',
                url: urls.length === 1 ? urls[0] : '',
                gmb: gmb.length === 1 ? gmb[0] : '',
            };

            this.editKeywordsPopup = {
                isVisible: true,
                multiple,
                isAll,
                editableKeyword:
                    multiple || isAll ? emptyKeyword : editableKeyword,
            };
            this.setIsEditPopupVisible(true);
        },
        setKeywordsToMove() {
            this.$modal.show(
                MoveKeywordsModal,
                { keywords: this.getSelectedKeywordsWithAllData },
                {
                    height: 211,
                    pivotX: 0,
                    name: 'MoveKeywords tags-mobal-window',
                    width: document.body.offsetWidth < 649 ? '98%' : 649,
                    classes: 'v--modal',
                },
            );
        },
        unsetEditableKeyword(e) {
            if (e && e.target && e.target.id === 'multi-edit-button') {
                return;
            }
            this.editKeywordsPopup = {
                isVisible: false,
                editableKeyword: null,
                multiple: false,
            };
            this.setIsEditPopupVisible(false);
        },
        getTabHeader(tabTile, cyAttr) {
            return (
                `<span data-cy="${cyAttr}">${tabTile}</span>` +
                (cyAttr === 'keyword-suggestions-tab'
                    ? '<span data-important-tooltip="' +
                      this.$t('i-keyword-suggestions') +
                      '" class="kw kw-information kw-information-suggestions"></span>'
                    : '')
            );
        },
        onDateSelect(date, key) {
            this.updateProjectTimeFrame({
                projectId: this.getCurrentProject.id,
                option: 'visibility_and_estimated_traffic',
                value: {
                    date_range: {
                        start: date[0],
                        end: date[1]
                    }
                },
                interval: {
                    interval: key
                }
            })
            this.fetchVisibilityAndEstimatedTrafficData(date, key);
        },
        onMetricsDateSelect(dateKey) {
            const metrics = exactDatePickerList[dateKey].altLabel;

            if (metrics) {
                this.metricsDate = exactDatePickerList[dateKey].label;
                this.updateOnlyDisplaySettings({ ...this.getDisplaySettings, metrics });
            }
        },
        fetchVisibilityAndEstimatedTrafficData(dateValue, key){
            const { interval } = this.getProjectOverviewOptionItem('visibility_and_estimated_traffic');

            let intervalDate = null;
            if(key && key !== this.interval){
                this.date = null;
                this.interval = key;
                intervalDate = datePickerList[key].value;
            } else {
                this.interval = interval;
                if(!dateValue){
                    if(!this.date && interval){
                        this.interval = interval;
                        intervalDate = datePickerList[interval].value;
                    } else {
                        this.date = null;
                    }
                } else {
                    this.date = dateValue;
                }
            }
            const [ startDate, endDate ] = intervalDate ? intervalDate : this.date ? this.date : [null, null];
            const tagId = this.$route.query?.tag;
            this.fetchProjectVisibility({ 
                startDate,
                endDate,
                projectId: this.getCurrentProject.project_id,
                tagId
            });
            this.fetchProjectEstimatedTraffic({
                startDate,
                endDate,
                projectId: this.getCurrentProject.project_id,
                tagId
            });
        },
    },
};
</script>

<style lang="scss">
@import '~sass/partials/new-nav-tabs';

.content-and-filter-wrap {
    position: relative;

    .project-overview {
        width: 100%;

        &__metrics-container {
            .dashbord-metrics {
                flex-direction: row;
            }

            .metrics-btn {
                height: 224px;
                width: 40px;
                justify-content: inherit;
                .btn-group {
                    padding: 0 0 0 1px;
                }
                .btn-default {
                    font-size: 13px;
                    padding: 20px 3px;
                    text-align: center;
                    width: 100%;
                    color: #63697b !important;
                    &:active,
                    &.active {
                        font-weight: 500;
                        color: #63697b !important;
                        background-color: #f2fafb !important;
                    }

                    &:hover {
                        color: #63697b !important;
                    }
                }
                .btn-default:hover {
                    background-color: #f2fafb !important;
                }
            }
        }
    }
}

.dashboard-table-wrap {
    .nav-tabs {
        @extend .nav-tabs_outlined;
        margin: 0;
        background: white;
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .tab__share-of-voice {
        .tab-content {
            margin: 7px 0 15px 0;
        }
    }

    .tab__keyword-suggestions {
        margin-top: 19px;
    }

    .tab-content {
        margin-bottom: 15px;
        margin: 0;
    }
}

.tab-content {
    .keywords-container_share-view {
        margin-top: 20px;
    }
}

@media screen and (min-width: 960px) {
    .dashboard-table-wrap {
        flex-grow: 1;
        min-width: 650px;
    }
}

@media (max-width: 959px) {
    .dashboard-table-wrap {
        width: 100%;

        .nav-tabs {
            margin: 0;
        }
    }
}

.test{
    height: 128px;
}
</style>

<style lang="scss" scoped>
    .metrics-wrapper {
        width: 100%;
        display: flex;
        gap: 16px;
        margin-bottom: 18px;

        & > div{
            flex: 1;
            border: 1px solid kw-color(kw-light-grey, 1);
            padding: 16px;
            border-radius: 4px;
            background: kw-color(kw-white, 1);;
        }

        .metrics-block {
            /deep/ .dashbord-metrics{

                .dashboard {
                    display: flex;
                    flex-flow: row wrap;
                    gap: 8px;

                    .dashboard-block {
                        margin: 0;
                        height: 144px;
                        flex: 1 1 30%;
                        display: flex;
                        flex-direction: column;

                        @media screen and (max-width: 900px) {
                            flex: 1 1 40%;
                        }

                        .dash-total {
                            flex: 1 1 auto;
                        }
                    }
                }

                .metrics-btn {
                    display: none;
                }
            }
        }

        .metrics-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 18px;


            .secondary-text{
                text-wrap: nowrap;
                margin: 0;
                color: kw-color(kw-light-grey, 8);
                font-size: 12px;
                margin-right: 8px;

                .count {
                    font-weight: 500;
                }
            }
        }

        .charts-block {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 50%;

            .chart-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .chart {
                    flex: 1;
                    min-height: 128px;
                }

            }
        }

    }
</style>
