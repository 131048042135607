<template>
    <div
        :ref="`projectParent-${project.id}`"
        class="row project_parent"
    >
        <div
            v-if="(project.children && project.children.length !== 0) || (project.tags && project.tags.length)"
            class="subprojects-projects-list"
            @click.stop.exact="setOpenedProjectsKeys({project})"
        >
            <span :class="subprojectsClasses" />
        </div>
        <div
            class="project__name-wrapper"
            data-cy="project-name-wrapper"
        >
            <router-link
                :class="itemParentLinkNameClasses"
                :to="parentRoute"
            >
                <span
                    class="project__name"
                    @mouseover="getTooltipForText(project.name, $event)"
                    v-html="`${project.name} ${project.deleted ? `<small>${$t('deleted-project')}</small>` : ''}`"
                />
                <span
                    v-if="!project.deleted"
                    class="project__count"
                    data-cy="project-keyword-count"
                    v-text="`(${project.keywords_count.ACTIVE || 0})`"
                />
            </router-link>
            <div v-if="(project.children && project.children.length !== 0) || (project.tags && project.tags.length)" />
        </div>
        <span
            v-if="!project.deleted && !isViewOnlyUser"
            data-item-id="addTag"
            class="project_add_tag_icon_block"
            @click="handleAddTagOrSubProjectClick(project)"
        >
            <span
                class="project-add-tag-icon kw kw-plus"
                data-cy="parent-project-add-tag-icon"
                @mouseover="addTagTooltip($event, $t('add-new-tag-or-group-new-line'))"
            />
        </span>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'ParentProject',
        mixins: [ToolTipRenderMixin],
        props: {
            getOpenedProjectsKeys: {
                type: Array,
                required: true,
            },
            project: {
                type: Object,
                required: true,
            },
            setOpenedProjectsKeys: {
                type: Function,
                required: true,
            },
            addTagTooltip: {
                type: Function,
                required: true,
            },
            handleAddTagOrSubProjectClick: {
                type: Function,
                required: true,
            },
            initialTab: {
                type: String,
                required: false
            }
        },
        computed: {
            ...mapGetters([
                'isViewOnlyUser',
            ]),
            parentRoute () {
                const {id, deleted} = this.project;
                const idForRouter = deleted ? id.replace('-deleted', '') : id;

                return {
                    name: deleted ? 'addKeywords' : 'keywordList',
                    params: {id: encodeURIComponent(idForRouter)},
                    ...(this.initialTab ? {query: {tab: this.initialTab}} : {})
                };
            },
            subprojectsClasses() {
                return {
                    'kw kw-chevron-up': true,
                    'is-open': this.getOpenedProjectsKeys.includes(this.project.id)
                }
            },
            itemParentLinkNameClasses () {
                return {
                    'project__name': true,
                    'project__name_parent': true,
                    'project__name_deleted': this.project.deleted,
                    'has_arrow_icon': (this.project.children && this.project.children.length !== 0) || (this.project.tags && this.project.tags.length)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
  .row {
    align-items: center;
    display: flex;
    margin: 0;
  }

  .project_parent {
    padding: 0;
    position: relative;
    height: 33px;
    transition: all .5s;
    display: flex;
    align-items: center;
  }

  .project__name-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
  }

  .project__count {
    margin-left: 5px;
  }

  .subprojects-projects-list {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    min-width: 18px;
    position: relative;
    transition: all .5s;
    width: 33px;
    height: 100%;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  .kw-chevron-up {
    color: kw-color(kw-blue, 1);
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    padding-left: 12px;
    transform: scaleY(-1);

    &.is-open {
      transform: scaleY(1);
    }
  }

  .project__name_parent {
    align-items: center;
    display: flex;
    height: 100%;
    outline: transparent;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;

    &:hover {
      text-decoration: unset;
    }

    span.project__name {
      display: inline-block;
      vertical-align: bottom;
      width: max-content;
      @import '~sass/cutting-name';
    }

    &.has_arrow_icon {
      padding-left: 0;
    }
  }

  .project__name_deleted {
    color: #7B7E86 !important;
    font-weight: normal !important;
  }
</style>