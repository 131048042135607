var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.keywordsContainerClasses},[(!_vm.getGlobalSearchQuery)?[(_vm.getCommonKeywordsChartIsShow)?_c('common-keyword-chart'):_vm._e(),_vm._v(" "),(_vm.showGaChart)?_c('google-analytics-chart'):_vm._e()]:_vm._e(),_vm._v(" "),_c('dashboard-action-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActionBarVisible),expression:"isActionBarVisible"}],attrs:{"app-width":_vm.appWidth}}),_vm._v(" "),(
            !_vm.isSearch &&
            !_vm.getLazyLoadMode &&
            _vm.isMobile &&
            _vm.itemsWithProcessedFields.length
        )?[(_vm.isSelectAllVisible)?_c('div',{staticClass:"select_all_block"},[(!_vm.keyKeywordsById.length)?[_vm._v("\n                "+_vm._s(_vm.$t('other-pages-selected-keywords', {
                        count: _vm.getSelectedAuth.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]:(
                    _vm.getSelectedAuth.length === _vm.getOriginalItems.length
                )?[_vm._v("\n                "+_vm._s(_vm.$t('one-pages-selected-keywords', {
                        count: _vm.keyKeywordsById.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]:[_vm._v("\n                "+_vm._s(_vm.$t('selected-keywords-count', {
                        count: _vm.getSelectedAuth.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]],2):_vm._e(),_vm._v(" "),(
                _vm.getIsAllCheckboxesSelected && !_vm.getAllWithoutIdsData.length
            )?_c('div',{staticClass:"select_all_block"},[_vm._v("\n            "+_vm._s(_vm.$t('all-keywords-are-selected', { count: _vm.getTotalKeywords }))+"\n            "),_c('span',{on:{"click":_vm.handleUnSelectAll}},[_vm._v(_vm._s(_vm.$t('unselect-all')))])]):_vm._e(),_vm._v(" "),(_vm.getIsAllCheckboxesSelected && _vm.getAllWithoutIdsData.length)?_c('div',{staticClass:"select_all_block"},[(_vm.isCurrentPageUnselected)?[_vm._v("\n                "+_vm._s(_vm.$t('other-pages-selected-keywords', {
                        count:
                            _vm.getTotalKeywords - _vm.getAllWithoutIdsData.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]:(_vm.isOnlyCurrentPageItemsSelected)?[_vm._v("\n                "+_vm._s(_vm.$t('one-pages-selected-keywords', {
                        count:
                            _vm.getTotalKeywords - _vm.getAllWithoutIdsData.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]:[_vm._v("\n                "+_vm._s(_vm.$t('selected-keywords-count', {
                        count:
                            _vm.getTotalKeywords - _vm.getAllWithoutIdsData.length,
                    }))+"\n                "),_c('span',{on:{"click":_vm.handleSelectAll}},[_vm._v(_vm._s(_vm.$t('select-all-count-keywords', {
                        count: _vm.getTotalKeywords,
                    })))])]],2):_vm._e()]:_vm._e(),_vm._v(" "),_c('keywords-table',{attrs:{"keywords":_vm.itemsWithProcessedFields}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }