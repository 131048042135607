import apiFactory from '@/api';
import { UPGRADE_PLAN } from '@/constants';
import { Events, EventBus } from '@/events';

import {
    SET_CURRENT_PLAN,
    SET_DEFAULT_CARD,
    SET_INVOICES,
    SET_OPTIONS,
    SET_PACKAGES,
    SET_PACKAGES_LOADING,
} from '../mutations';

const billingApi = apiFactory.get('billing');
const invoicesApi = apiFactory.get('invoices');

const billing = {
    state: {
        currentPlan: {},
        defaultCard: null,
        invoices: [],
        packages: false,
        packagesLoading: false,
        invoiceOptions: {
            field1: '',
            field2: '',
            field3: '',
            footer: '',
            memo: '',
        },
    },
    mutations: {
        [SET_INVOICES]: (state, invoices) => state.invoices = invoices,
        [SET_DEFAULT_CARD]: (state, card) => state.defaultCard = card,
        [SET_OPTIONS]: (state, options) => state.invoiceOptions = options,
        [SET_PACKAGES]: (state, payload) => state.packages = payload,
        [SET_PACKAGES_LOADING]: (state, payload) => state.packagesLoading = payload,
        [SET_CURRENT_PLAN]: (state, plan) => {
            const currentPlan = plan;
            const sub = currentPlan.subscription;
            let expiresAt = new Date(sub.account_expire_at);
            let now = new Date();
            const isExpired = expiresAt < now;
            let timeInMilliseconds = expiresAt.getTime() - now.getTime();
            currentPlan.expiresTillTomorrow = timeInMilliseconds / 86400000 < 1 && timeInMilliseconds > 0;
            currentPlan.isExpired = isExpired;
            currentPlan.expiredStatus = isExpired ? 'expired' : 'actual';
            sub.isExpired = isExpired;
            currentPlan.status = 'na';
            currentPlan.isNewPaidUser = !sub.account_expire_at

            if (sub.cancel_at_period_end && sub.cancel_at) {
                currentPlan.status = 'warning';
            } else if (sub.status === 'active' || sub.status === 'trialing') {
                currentPlan.status = 'success';
            } else if (
                sub.status === 'past_due' ||
                sub.status === 'canceled' ||
                sub.status === 'unpaid'
            ) {
                currentPlan.status = 'error';
            } else if (
                sub.status === 'paused'
            ) {
                currentPlan.status = 'paused';
            }
            state.currentPlan = currentPlan;
        },
    },
    getters: {
        getAllInvoices: state => state.invoices,
        getInvoiceOptions: state => state.invoiceOptions,
        getCurrentPlan: state => state.currentPlan,
        getTrialPeriod: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.subscription?.trial_period : 0,
        getInitialTrialPeriod: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.subscription?.initial_trial_period : 0,
        getExpiresTillTomorrow: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.expiresTillTomorrow ?? false : false,
        getIsExpired: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.isExpired ?? false : false,
        getDefaultCard: state => state.defaultCard,
        getPackages: state => state.packages,
        getPackagesLoading: state => state.packagesLoading,
        getIsNewPaidUser: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.isNewPaidUser ?? false : false,
        getExpirePostTrial: (state, getters) => getters.isAdminOrMasterUser ? state.currentPlan?.subscription?.expire_post_trial ?? false : false,
    },
    actions: {
        setPackagesLoading({ commit }, packagesLoading = true) {
            commit(SET_PACKAGES_LOADING, packagesLoading);
        },
        setPackages({ commit }, packages = false) {
            commit(SET_PACKAGES, packages);
        },
        async fetchInvoices({ commit }) {
            commit(SET_INVOICES, []);

            try {
                const res = await invoicesApi.fetch();
                const invoices = res.data.data.map(el => ({ id: el.id, ...el.attributes }));
                invoices.sort((inv1, inv2) => inv2.date - inv1.date);
                commit(SET_INVOICES, invoices);
            } catch (error) {
                //
            }
        },

        async updateInvoiceDetails(props, payload) {
            try {
                await invoicesApi.updateOptions(payload);
            } catch (error) {
                //
            }
        },
        async fetchPackages({ dispatch, getters }, force = false) {
            if (!getters.getPackages || force) {
                try {
                    dispatch('setPackages');
                    dispatch('setPackagesLoading');

                    const res = await billingApi.fetchPackages();

                    if (typeof res.data.data.RESPONSE !== 'undefined') {
                        throw new Error(res.data.data.RESPONSE);
                    }

                    dispatch('setPackages', res.data.data);
                } catch (error) {
                    // console.error(error);
                } finally {
                    dispatch('setPackagesLoading', false);
                }
            }
        },
        async fetchPackageById({ dispatch, getters }, id) {
            try {
                dispatch('setPackagesLoading');

                const p = getters.getPackages && getters.getPackages.find(el => el.package_identity === id);

                if (p) {
                    return p;
                }

                const res = await billingApi.fetchPackageById(id);

                return res?.data?.data ?? false;
            } catch (e) {
                // console.error(e);
            } finally {
                dispatch('setPackagesLoading', false);
            }

            return false;
        },
        async changePlan({ dispatch, getters }, { plan_id, billing_cycle, type }) {
            const postData = {
                plan_id,
                billing_cycle,
            };

            if (getters.getCoupon) {
                postData.coupon = getters.getCoupon;
            }

            try {
                const res = await billingApi.changePlan(postData, type);

                if (type === UPGRADE_PLAN) {
                    EventBus.emit(Events.UPGRADE_PACKAGE, postData);
                }

                await dispatch('fetchSettings');
                dispatch('setCoupon');

                return res;
            } catch (error) {
                throw new Error(error);
            }
        },
        async cancelSubscription({ dispatch }, payload) {
            try {
                await billingApi.cancelSubscription(payload);

                EventBus.emit(Events.CANCEL_SUBSCRIPTION);

                await dispatch('fetchSettings');
            } catch (error) {
                throw new Error(error);
            }
        },
        async updateTrialPlan({ dispatch }, payload) {
            try {
                await billingApi.subscribe(payload);
                await dispatch('fetchSettings');
            } catch (error) {
                //
            }
        },
        async subscribeNewUser({ getters }, { token, callback }) {
            const { getIsNotStripeUser, getSubscriptionData } = getters;

            if (getIsNotStripeUser) {
                let plan_id = 'UjTp3BIDYe1umYtT3qC8nQ==';

                switch (getSubscriptionData.packageLimit) {
                    case 50: plan_id = 'UjTp3BIDYe1umYtT3qC8nQ=='; break;
                    case 100: plan_id = 'qMrCgoQqKzcfJ8ZspnMMMg=='; break;
                    case 200: plan_id = 'kyjQ7Gw2yOYeGfHpvKrDiw=='; break;
                    case 300: plan_id = 'F34dXkhtoUquCA508ncKHg=='; break;
                    case 500: plan_id = 'WQAOMY+8KfuWYgvC6QulWA=='; break;
                    case 1000: plan_id = 'b4S\/FNmCEpzK2nECQ41FLQ=='; break;
                    case 2000: plan_id = 'VCd3kUxdCmLEmfyXFlTQsQ=='; break;
                    default: plan_id = 'UjTp3BIDYe1umYtT3qC8nQ=='; break;
                }

                await billingApi.subscribe({
                    token,
                    plan_id,
                    billing_cycle: getSubscriptionData.billingCycle,
                });

                if (callback) {
                    callback();
                }
            }
        },
        async storeBillingCard({ dispatch }, { token, setAsDefault, callback }) {
            try {
                await billingApi.addCard({ token, default: setAsDefault });

                if (callback) {
                    callback();
                }

                await dispatch('fetchSettings');
            } catch (error) {
                throw new Error(error);
            }
        },
        async resumeSubscription({ dispatch, getters, }, { }) {
            const postData = {};

            if (getters.getCoupon) {
                postData.coupon = getters.getCoupon;
            }

            try {
                const res = await billingApi.resumeSubscription(postData);
                await dispatch('fetchSettings');
                dispatch('setCoupon');

                return res;
            } catch (error) {
                throw new Error(error);
            }
        },
        async applyCoupon({ getters, dispatch }) {
            if (!getters.getCoupon ||
                getters.getCurrentPlan?.subscription?.coupon == getters.getCoupon?.coupon?.code
            ) {
                return false;
            }

            try {
                await billingApi.applyCoupon({ coupon: getters.getCoupon });
                await dispatch('fetchSettings');
                dispatch('setCoupon');

                return true;
            } catch (error) {
                throw new Error(error);
            }
        },
        setupStripeIntent(props, payload) {
            return billingApi.setupStripeIntent(payload)
        },
    },
};

export default billing;
