var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getPermissionsDataLoaded)?_c('div',{staticClass:"competitors-panel",class:{blocked: !_vm.getPermissionData('share_of_voice')}},[(_vm.isSovUpdatingMessageVisible)?_c('mindshare-update-message'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"competitors-panel__header two-columns"},[_c('div',{staticClass:"title browser-view",attrs:{"id":"tableTitle"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('share-of-voice-header')))]),_vm._v(" "),_c('span',{staticClass:"kw kw-help-circle",on:{"mouseover":function($event){_vm.tooltip({ text: _vm.$t('mindshare-help'), event: $event })}}}),_vm._v(" "),_c('div',{staticClass:"project"},[_c('div',{staticClass:"project__title"},[_vm._v("\n                    "+_vm._s(_vm.$t('project-upper'))+":\n                    "+_vm._s(_vm.getCurrentProject ? _vm.getCurrentProject.name : '')+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"project__tags"},[_vm._v("\n                    "+_vm._s(_vm.$t('tags-uppercased'))+":\n                    "+_vm._s(_vm.showSelectedTag)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"project__keywords"},[_c('div',[_vm._v(_vm._s(_vm.keywordsNumber))]),_vm._v("\n                    "+_vm._s(_vm.$t('keywords-first-upper'))+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"project__btn"},[_c('button-group',{model:{value:(_vm.mindshareView),callback:function ($$v) {_vm.mindshareView=$$v},expression:"mindshareView"}},[_c('radio',{attrs:{"selected-value":"table"}},[_c('span',{staticClass:"kw kw-list"})]),_vm._v(" "),_c('radio',{attrs:{"selected-value":"chart"}},[_c('span',{staticClass:"kw kw-competitor"})])],1)],1),_vm._v(" "),_c('div',{class:_vm.dropdownWrapperClasses},[_c('span',{staticClass:"kw kw-help-circle",on:{"mouseover":function($event){_vm.tooltip({
                            text: _vm.$t('line-chart-help'),
                            event: $event,
                        })}}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('time-frame-over')))]),_vm._v(" "),_c('date-ranges-dropdown',{attrs:{"type":"trending"},on:{"onSelect":function (value){ return _vm.onDateSelect(value, 'trending'); }}})],1)])]),_vm._v(" "),(_vm.isOnlyMapYoutubeKeywords)?_c('div',{staticClass:"top-competitors__info"},[_c('span',[_vm._v(_vm._s(_vm.$t('there-are-only-yt-maps-kw')))])]):(_vm.isMindshareDataLimitExceed)?_c('div',{staticClass:"top-competitors__info"},[_c('span',[_vm._v(_vm._s(_vm.$t('competitors-keywords-limit-exceed')))])]):_c('div',{staticClass:"top-competitors",class:{
            'top-competitors__loading':
                _vm.getMindshareHistoryDataLoading ||
                _vm.getNewAddedKeywordsIsFetching,
        }},[(_vm.isCompetitorsPanelVisible)?[_c('share-of-voice-chart',{attrs:{"mindshareView":_vm.mindshareView,"showChart":"","mobileHide":false,"chartStyles":_vm.chartStyles}})]:_c('div',{staticClass:"empty-top-competitors"})],2),_vm._v(" "),(this.$route.name !== 'shareOfVoice' && this.$route.name !== 'shareOfVoiceWithHash')?[_c('div',{staticClass:"competitors-panel__header"},[_c('div',{staticClass:"title title_distribution browser-view"},[_c('h4',[_vm._v(_vm._s(_vm.$t('ranking-vs-distribution-header')))]),_vm._v(" "),_c('span',{staticClass:"kw kw-help-circle",on:{"mouseover":function($event){_vm.tooltip({
                            text: _vm.$t('timeframe-breakdown-chart'),
                            event: $event,
                        })}}}),_vm._v(" "),_c('button-group',{model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},_vm._l((_vm.viewsOptions),function(option){return _c('radio',{key:option.value,attrs:{"selected-value":option.value},domProps:{"innerHTML":_vm._s(option.label)}})}),1)],1),_vm._v(" "),_c('div',{staticClass:"dropdown-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t('time-frame-over')))]),_vm._v(" "),_c('date-ranges-dropdown',{attrs:{"type":"ranking-distribution"},on:{"onSelect":function (value){ return _vm.onDateSelect(value, 'ranking-distribution'); }}})],1)]),_vm._v(" "),_c('div',{staticClass:"ranking-distributions-container"},[_c('ranking-distributions',{attrs:{"view":_vm.view}})],1)]:_vm._e(),_vm._v(" "),(!_vm.getPermissionData('share_of_voice'))?_c('div',{staticClass:"blocker-message"},[_c('div',{staticClass:"header"},[_vm._v(_vm._s("Upgrade to refresh your Share of Voice data"))]),_vm._v(" "),_c('div',{staticClass:"description"},[_vm._v(_vm._s("Discover your true competitors before they outrank you"))]),_vm._v(" "),_c('div',{staticClass:"upgrade-button-wrapper"},[_c('kw-button',{staticClass:"upgrade-button",attrs:{"button-text":_vm.$t('upgrade-now'),"button-type":"primary"},on:{"click":_vm.redirectToPackages}})],1)]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }