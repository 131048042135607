<template>
    <div
        :id="projectsFilterId"
        class="projects-filter"
        v-click-outside="clickHandler"
    >
        <kw-button
            v-if="buttonType==='button'"
            :id="buttonId"
            button-type="secondary"
            prepend-icon-class="kw kw-filter"
            :class="buttonComponentClasses"
            @click="opened = !opened"
            @mouseover="renderTooltip"
        >
            <span
                slot="appendSlot"
                class="select-projects-button-text"
            >
                {{ buttonText }}
            </span>
        </kw-button>

        <div
            v-else-if="buttonType==='select'"
            :class="`select-wrapper${opened ? ' active' : ''}`"
            @click="opened = !opened"
        >
           <span class="text">{{ buttonText }}</span> 
           <span class="icon kw kw-chevron-down"></span>
        </div>
        <slot v-else />
        <div
            v-if="opened"
            class="projects-filter__popup"
            data-cy="alerts-filter-popup"
        >
            <search-input
                icon-position="left"
                :search-input-handler="searchProjects"
                :search-input-placeholder="$t('search')"
                :search-input-value="projectForSearch"
            />
            <ul
                class="projects-filter__list"
                data-cy="alerts-project-filter-list"
            >
                <li
                    v-if="showCheckAll"
                    class="projects-filter__item projects-filter__item_all"
                    @click="checkCallback(preparedProjects)"
                >
                    <checkbox
                        :checkbox="checkedAll(preparedProjects)"
                        @checkbox-click="checkCallback(preparedProjects)"
                    />
                    <span v-text="$t('select-all')" />
                </li>
                <li
                    v-for="(project, i) in filteredProjects"
                    :key="i"
                    :class="projectsFilterItemClasses(project)"
                    data-cy="project-item"
                    @click="checkCallback(project)"
                >
                    <checkbox
                        :checkbox="isProjectChecked(project.fullName)"
                        @checkbox-click="checkCallback(project)"
                    />
                    <span
                        :data-tooltip="project.name"
                        v-text="project.name"
                    />
                    <div
                        v-if="showSelectOnly"
                        class="only-one-filter"
                        @click.stop="() => checkOnlyCallback(project)"
                    >
                        {{ $t('only') }}
                    </div>
                </li>
            </ul>
        </div>
        <!-- <global-events 
            target="window"
            @click="clickHandler"
        /> -->
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

    export default {
        name: 'ProjectsFilter',
        mixins: [ToolTipRenderMixin],
        
        props: {
            selectedProjects: {
                type: Array,
                required: true,
            },
            checkCallback: {
                type: Function,
                required: true,
            },
            checkOnlyCallback: {
                type: Function,
                required: false,
            },
            projects: {
                type: Array,
                required: true,
            },
            buttonText: {
                type: String,
                required: true,
            },
            invalidSelect: {
                type: Boolean,
                required: false,
                default: false,
            },
            showCheckAll: {
                type: Boolean,
                required: false,
                default: true,
            },
            showSelectOnly: {
                type: Boolean,
                required: false,
                default: true, 
            },
            buttonType: {
                type: String,
                required: false,
                default: 'button'
            }
        },
        data () {
            return {
                projectsFilterId: 'projects-filter',
                projectForSearch: '',
                opened: false,
                buttonId: 'select-projects-button'
            };
        },
        watch: {
            selectedProjects(){
                console.log(this.selectedProjects)
            }
        },
        computed: {
            preparedProjects () {
                const projects = _.cloneDeep(this.projects);
                projects.sort((a, b) => a.name > b.name ? 1 : -1);
                const prepared = [];

                projects.forEach(p => {
                    prepared.push({
                        auth: p.auth,
                        deleted: p.hasOwnProperty('deleted') && p.deleted,
                        fullName: p.name,
                        id: p.id,
                        name: p.name,
                        parent: p.parent,
                        type: 'parent',
                    });

                    if (p.children) {
                        p.children.forEach((c, i) => {
                            prepared.push({
                                auth: c.auth,
                                id: c.id,
                                fullName: `${c.parent}[sub]${c.name}`,
                                last: p.children.length - 1 === i,
                                name: c.name,
                                parent: c.parent,
                                type: 'child',
                            });
                        });
                    }
                });

                return prepared;
            },
            filteredProjects () {
                return this.preparedProjects
                    .filter(g => g.fullName.toLowerCase().includes(this.projectForSearch.toLowerCase()));
            },
            buttonComponentClasses () {
                return {
                    'button-component_active': this.opened,
                    'button-component_filled': this.selectedProjects.length,
                    'button-component_invalid': this.invalidSelect,
                };
            },
        },
        methods: {
            renderTooltip(event) {
                if (event.target.id !== this.buttonId) return;
                const { scrollWidth, clientWidth } = event.target.lastChild;
                if (scrollWidth && clientWidth && scrollWidth > clientWidth + 2) {
                    return this.tooltip({text: this.buttonText, event});
                }
            },
            isProjectChecked (projectName) {
                return this.selectedProjects.includes(projectName);
            },
            clickHandler () {
                if(this.opened){
                    this.close();
                }
            },
            close () {
                this.opened = false;
                this.$emit('onClose');
            },
            searchProjects (value) {
                this.projectForSearch = value;
            },
            checkedAll (allProjects) {
                return allProjects.length === this.selectedProjects.length;
            },
            projectsFilterItemClasses (g) {
                return {
                    'projects-filter__item': true,
                    'projects-filter__item_child': g.type === 'child',
                    'projects-filter__item_last-child': g.last,
                    'projects-filter__item_disabled': g.deleted,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>

.button-component_filled {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    justify-content: flex-start !important;
    .select-projects-button-text {
        margin-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
  .projects-filter {
    position: relative;

    button {
      &.kw-btn-secondary {
        /deep/.kw-filter {
          font-size: 18px;
        }
      }
    }
  }

  .projects-filter__popup {
    background-color: kw-color(kw-white, 1);
    border-radius: 4px;
    box-shadow: 0 20px 45px rgba(0, 0, 0, .2);;
    margin-top: 8px;
    padding: 8px;
    position: absolute;
    right: 0;
    width: 290px;
    z-index: 2;
  }

  .projects-filter__list {
    @extend .custom-scroll;
    margin: 10px 0 0;
    max-height: 250px;
    overflow-y: auto;
    padding: 0;
    width: 100%;
  }

  .projects-filter__item {
    color: #383B46;
    display: flex;
    line-height: 16px;
    list-style-type: none;
    padding-bottom: 12px;

    &.projects-filter__item_all {
      font-weight: 600;
    }

    &:last-child {
      padding-bottom: 0;
    }

    span {
      @import '~sass/cutting-name';
      cursor: pointer;
      margin-left: 8px;
    }

    &:hover {
      .only-one-filter {
        visibility: visible;
      }
    }
  }

  .projects-filter__item_child {
    padding-left: 24px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 15px;
      border-left: solid 1px #CCCCCC;
      border-bottom: solid 1px #CCCCCC;
      top: -6px;
      left: 8px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 20px;
      border-left: solid 1px #CCCCCC;
      left: 8px;
      bottom: 0;
    }
  }

  .projects-filter__item_last-child {
    &:after {
      content: none;
    }
  }

  /deep/ .checkbox {
    margin-left: 0;
    min-width: 16px;
  }

  .projects-filter__item_disabled {
    color: #7B7E86;
    pointer-events: none;
  }

  .only-one-filter {
    color: kw-color(kw-blue, 1);
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
    visibility: hidden;

    &:hover {
      text-decoration: underline;
    }
  }

  .select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    border: 1px solid kw-color(kw-white, 5);
    padding: 10px;
    border-radius: 4px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;

    .text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .icon {
       width: 6px;
       height: 6px;
       transition: 0.3s ease;
       font-size: 6px;
    }

    &.active {
        .icon {
            transform: rotate(180deg);
        }
    }
  }
</style>
