<template>
    <div class="footer-section">
        <div class="footer-section__manage">
            <div class="progress-section">
                <circular-progress-bar
                    unique-ref="refresh-progress"
                    :used="getRefreshed"
                    :limit="getUserDataProp('refreshLimit')"
                    :color="fill.green"
                    :message="$t('cycle-kw-added', { cycle })"
                />
                <circular-progress-bar
                    unique-ref="daily-added-progress"
                    :used="getDailyAdded.used"
                    :limit="getDailyAdded.limit"
                    :color="fill.yellow"
                    :message="$t('cycle-refreshes-user', { cycle })"
                />
                <circular-progress-bar
                    unique-ref="used-keywords-progress"
                    :used="getUsedKeywords"
                    :limit="getPackageKeywords"
                    :color="fill.blue"
                    :message="$t('kw-used-on-plan')"
                />
            </div>
            <div class="simple-footer-wrap">
                <kw-button
                    v-if="!isViewOnlyUser && !getSettingIsFetching"
                    :button-text="$t('add_project')"
                    button-type="primary"
                    :button-disabled="isAddProjectDisabled"
                    :class="addProjectButtonClasses"
                    data-cy="add-project-button"
                    prepend-icon-class="kw kw-plus"
                    @click="openAddProject"
                    @prepend-icon-mouseover="
                        tooltip({
                            text: $t('account-restricted-tooltip'),
                            event: $event,
                            disabled: !isAddProjectDisabled,
                        })
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import CircularProgressBar from './CircularProgressBar.vue';

export default {
    name: 'FooterSection',
    components: {
        'circular-progress-bar': CircularProgressBar,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        openAddProject: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            fill: {
                green: { color: '#41a63d' },
                yellow: { color: '#fed168' },
                blue: { color: '#0074d9' },
            },
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentPlan',
            'getPackageKeywords',
            'getRefreshed',
            'getSettingIsFetching',
            'getUsedKeywords',
            'getUserDataProp',
            'isViewOnlyUser',
            'isAddonRestricted',
            'isAddonUser',
            'getDailyAdded',
            'getIsFreePlan',
        ]),
        isAddProjectDisabled() {
            return (
                (this.isAddonUser && this.isAddonRestricted) ||
                this.isViewOnlyUser
            );
        },
        addProjectButtonClasses() {
            return {
                'footer-actions__add-project': true,
                'button-primary_pseudo-disabled':
                    this.getCurrentPlan.expiredStatus === 'expired',
            };
        },
        cycle(){
            return this.getCurrentPlan?.subscription.cycle === 'weekly' ? 
                this.$t('common.weekly') : 
                this.$t('common.daily');
        }
    },
};
</script>

<style lang="scss">
.footer-actions__buttons {
    align-content: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-height: 62px;
    padding-left: 8px;
    align-items: center;
}

.footer-section {
    color: kw-color(kw-blue, 1);
    padding: 0 18px 0 0;
    .archived-projects {
        max-width: 49%;
        width: 100%;
    }

    .simple-footer-wrap {
        .footer-actions__add-project {
            width: 100%;
            justify-content: center;

            .kw-plus {
                font-size: 10px;
            }
        }
    }
}

.footer-section__manage {
    padding: 2px;
    display: flex;
    overflow: hidden;
    flex-flow: column nowrap;

    .button-component_icon {
        margin-right: 7px;
        @media (max-width: 959px) {
            display: none;
        }
    }

    button {
        &:focus {
            box-shadow: none;
        }
    }
}

.progress-section {
    width: 100%;
    display: flex;
    color: #63697b;
    flex-wrap: wrap;
    min-width: 85px;
    max-width: 285px;
    margin-bottom: 1px;
    justify-content: space-between;
}
</style>
