import {switchDateFormat} from '@/helpers/format-date-service';
import toastr from '@/helpers/init-toastr';
import { cookieGetters } from '@/helpers/cookieManager';
import {
    SET_DISPLAY_SETTINGS,
    SET_OTHER_SETTINGS,
    SET_METRICS_IS_FETCHING,
    TOGGLE_KEYWORDS_TABLE_SORTER,
} from '@/store/mutations';
import apiFactory from '@/api';

export const displayPersistedKeys = [
    'display.displaySettings',
    'display.otherSettings',
];

const usersApi = apiFactory.get('users');

const display = {
    state: {
        projectsSorter: 'category',
        displaySettings: {
            category_sort: 'asc',
            dateformat: 'M dS, Y',
            metrics_range: ['1', '3', '5', '10', '50'],
            metrics: 'monthly',
            sort_by_order: 'asc',
            sort_by: 'g',
            currency_code: 'USD',
        },
        otherSettings: {
            chartheight: 275,
            display_length: 100,
            gaps: 'hide',
            newKwTable: 'show',
            global: 'show',
            hidecolumns: '',
            hideverified: 'hide',
            kwtotals: 'show',
            kwtotalsadmin: 'show',
            kwtotalsmaster: 'show',
            kwtotalsregular: 'show',
            kwtotalsview: 'show',
            pdfgraph: 'hide',
            pdfpages: 'hide',
            rnotes: 'hide',
            showcategorydate: 'hide',
            subcat: 'show',
            shareFromViewkey: 'hide',
            usewhitelabel: 'hide',
            vkinfo: 'hide',
            widetable: 'show',
            generateHistoricalGraphPdf: 'hide',
            googleAnalyticsCharts: 'hide',
            rankingAreaChart: 'show',
            companyInformationPdfSettings: 'hide',
            hideVerifiedPdfSettings: 'hide',
            hideReportPdfDate: 'hide',
        }
    },
    mutations: {
        [TOGGLE_KEYWORDS_TABLE_SORTER]: (state, payload) => {
            const {sort_by, sort_by_order} = payload;
            state.displaySettings.sort_by = sort_by;
            state.displaySettings.sort_by_order = sort_by_order;
        },
        [SET_DISPLAY_SETTINGS]: (state, payload) => state.displaySettings = payload,
        [SET_OTHER_SETTINGS]: (state, payload) => state.otherSettings = payload,
    },
    getters: {
        getProjectsSorter: state => state.projectsSorter,
        // getters for display settings
        getDisplaySetting: state => prop => state.displaySettings[prop],
        getDisplaySettings: state => state.displaySettings,
        getFormattedDate: state => date => switchDateFormat(new Date(date), state.displaySettings.dateformat),
        // getters for table settings
        getOtherSetting: state => prop => state.otherSettings[prop],
        getOtherSettings: state => state.otherSettings,
        getDisplayLength: state => state.otherSettings.display_length,
        showKeywordsTotal: (state, {getOtherSetting, isAddonUser, isViewOnlyUser, isAdminUser, isMasterUser}) => {
            if (isMasterUser && getOtherSetting('kwtotalsmaster') === 'hide') {
                return false;
            }

            if (isAdminUser && getOtherSetting('kwtotalsadmin') === 'hide') {
                return false;
            }

            if (isAddonUser && getOtherSetting('kwtotalsregular') === 'hide') {
                return false;
            }

            if (isViewOnlyUser && getOtherSetting('kwtotalsview') === 'hide') {
                return false;
            }

            return true;
        }
    },
    actions: {
        async updateOnlyDisplaySettings ({getters, dispatch, commit}, settings) {
            const { getCurrentProject } = getters;

            try {
                commit(SET_METRICS_IS_FETCHING, true);

                const res = await usersApi.updateUserCompanySettings({
                    displaySettings: {
                        categorysort:  settings.category_sort,
                        metrics: settings.metrics,
                        metricsrange: settings.metrics_range,
                        sortby: settings.sort_by,
                        sortbyorder: settings.sort_by_order === 'asc' ? '0' : '1',
                        dateformat: settings.dateformat,
                    }
                });

                if (typeof res.data.response !== 'undefined' && res.data.response !== 'success') {
                    throw new Error(res.data.message);
                }

                await dispatch('setDisplaySettings', settings);

                /**
                 * in any case fetchMetrics or fetchAllProjectMetricsData
                 * SET_METRICS_IS_FETCHING will set to false
                 * in error case handled to set false below in catch section
                 */
                if (getCurrentProject) {
                    await dispatch('fetchMetrics');
                } else {
                    dispatch('fetchOverviewData');
                    await dispatch('fetchAllProjectsMetricsData');
                }
            } catch (e) {
                commit(SET_METRICS_IS_FETCHING, false);
                console.error(e);
                toastr.e(e);
            }
        },

        toggleKeywordsTableSorter ({dispatch, getters}, payload) {
            const displaySettings = getters.getDisplaySettings;
            const {sort_by, sort_by_order} = displaySettings;
            const dataForUpdate = {
                sort_by,
                sort_by_order,
            }
            if (sort_by !== payload) {
                dataForUpdate.sort_by = payload;
                dataForUpdate.sort_by_order = 'desc';
            } else {
                dataForUpdate.sort_by_order = sort_by_order === 'desc' ? 'asc' : 'desc';
            }
            dispatch('setKeywordsTableSorter', dataForUpdate);
        },
        setDisplaySettings({commit, getters}, payload = {}) {
            const currentSettings = getters.getDisplaySettings;
            const newSettings = {
                ...currentSettings,
            };
            Object.keys(currentSettings).forEach(key => {
                if (typeof payload[key] === 'undefined') return;

                if (key === 'metrics') {
                    newSettings.metrics = payload.metrics || 'monthly';
                    return;
                }

                newSettings[key] = payload[key];
            });
            commit(SET_DISPLAY_SETTINGS, newSettings);
        },
        setOtherSettings({commit, getters}, payload = {}) {
            const otherSettings = getters.getOtherSettings;
            const newSettings = {
                ...otherSettings,
            };
            Object.keys(otherSettings).forEach(key => {
                const hidecolumns = payload.hidecolumns.split(';');

                if (['hidecolumns', 'chartheight'].includes(key)) {
                    newSettings[key] = payload[key];
                    return;
                }

                if (key === 'display_length') {
                    newSettings[key] = payload[key] > 250 ? 99999 : payload[key];
                    return;
                }

                // reversed
                if (['prefix', 'subcat', 'rnotes', 'pdfpages', 'gaps'].includes(key)) {
                    newSettings[key] = hidecolumns.includes(key) ? 'show' : 'hide';
                    return;
                }
                newSettings[key] = hidecolumns.includes(key) ? 'hide' : 'show';
            });
            commit(SET_OTHER_SETTINGS, newSettings);
        },
        updateOtherSettings({commit, getters}, payload = {}) {
            const otherSettings = getters.getOtherSettings;
            const newSettings = {
                ...getters.getOtherSettings,
            };
            Object.keys(otherSettings).forEach(key => newSettings[key] = payload[key]);
            commit(SET_OTHER_SETTINGS, newSettings);
        },
        setKeywordsTableSorter({ commit }, payload) {
            commit(TOGGLE_KEYWORDS_TABLE_SORTER, payload);
        },
    },
};

export default display;
