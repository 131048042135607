import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
import i18n from '@/i18n';
import Toastr from 'toastr';

const typeDependImageClasses = {
    warning: 'kw-alert-triangle',
    error: 'kw-alert-octagon',
    success: 'kw-check-circle',
    info: 'kw-alert-circle'
}

export const updateProgress = (cycle, portion, total) => {
    const added = cycle * portion;
    document.querySelector('#toastr-with-progress .toastr-progress').style.width = `${added / total * 100}%`;
    document.querySelector('.toast-body').innerHTML = i18n.t(
        'keywords-adding-process',
        {progress: `${added > total ? total : added}/${total}`}
    );
};

const createMessage = ({type, msg, undoCallback, withProgress, title}) => {
    const iconClassName = `toast-icon kw ${typeDependImageClasses[type]}`;
    const img = `<span class="${iconClassName}"></span>`;
    const subtitle = title === undefined ?`<span class="toast-subtitle">${type}</span>`: `<span class="toast-subtitle">${title}</span>`;
    const body = `<span class="toast-body">${msg}</span>`;
    const undo = '<button id="toast-undo-button">Undo</button>';
    const progress = '<div class="toastr-progress-wrap"><div class="toastr-progress"></div></div>'

    const text = `
        <div id="${withProgress ? 'toastr-with-progress' : ''}" class="toast-text" data-cy=error-toast-text>
            ${subtitle}
            ${body}
            <div class="toastr-buttons">
                ${undoCallback ? undo : ''}
            </div>
            ${withProgress ? progress : ''}
        </div>
    `;

    return img + text;
}

export default (function InitToStr() {
    Toastr.options = {
        'classes': ['toast-container'],
        'closeButton': true,
        'debug': false,
        'extendedTimeOut': '1000',
        'hideDuration': '1000',
        'hideEasing': 'linear',
        'hideMethod': 'fadeOut',
        'newestOnTop': false,
        'onclick': null,
        'positionClass': 'toast-bottom-right',
        'preventDuplicates': false,
        'progressBar': false,
        'showDuration': '300',
        'showEasing': 'swing',
        'showMethod': 'fadeIn',
        'timeOut': '5000',
        closeHtml: '<span class="kw kw-x" data-cy="close-notification-icon"></span>',
    }

    const createToastr = ({msg, type, page, undoCallback, withProgress, title}, options) => {
        Toastr.options.positionClass = page === 'login' && MOBILE_WIDTH_RESOLUTION_PHONE > window.screen.width
            ? 'toast-top-right'
            : 'toast-bottom-right';
        if (options) {
            Toastr.options.timeOut = typeof options.timeOut !== 'undefined' ? options.timeOut : '5000';
        } else {
            Toastr.options.timeOut = '5000';
        }

        if (undoCallback) {
            Toastr.options.onShown = () => {
                const undoButton = document.getElementById('toast-undo-button');

                if (undoButton) {
                    undoButton.addEventListener('click', undoCallback);
                }
            };
        }

        return Toastr[type](createMessage({type, msg, undoCallback, withProgress, title}));
    };

    return {
        e: msg => createToastr({msg, type: 'error'}),
        error: msg => createToastr({msg, type: 'error'}),
        i: msg => createToastr({msg, type: 'info'}),
        s: msg => createToastr({msg, type: 'success'}),
        success: msg => createToastr({msg, type: 'success'}),
        w: msg => createToastr({msg, type: 'warning'}),
        createToastr,
        clear: Toastr.clear,
    };
})();