export default (i18n, toastr) => {
    return res => {
        const {config} = res;

        if (config.method === 'put' && (config.url.includes('/self') )) {
            toastr.s(i18n.t('saved-changes-msg'));
        }

        if (config.method === 'get' && (config.url.includes('users/email') )) {
            toastr.s(i18n.t('email-seccessfully-sent'));
        }
        return res;
    };
};
