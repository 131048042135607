<template>
    <div class='block-wrapper' v-resize="() => debouncedHandler(resizeChart)">
        <div class='header-wrapper'>
            <chart-header
                :title="$t('available-chart-titles.keywords_in_top')"
                :toolTip="$t('keywords_in_top-tooltip')"
                showTimeframe
                isHidable
                @onHide="onHide"
                :date="date"
                @onDateSelect="onDateSelect"
                :isHideDisabled="isLoading"
                :defaultKey="interval"
            />
        </div>

        <keywords-in-top-chart
            :chart="chart"
            :project="projectData"
            :loading="getProjectKeywordsInTopPositions.loading"
            :isChartDisplayed="true"
            @setChart="setChart"
            :key="`keywords_in_top_${chartKey}`"
            showLatest
        />

        <div class="time-ranges">
            <label class="range" v-for="(item, idx) in timeRanges" :key="item.title">
                <checkbox
                    :checkbox="item.isChecked"
                    @checkbox-click="toggleTimeRange(idx)"
                />
                <span class="text">
                    {{ item.title }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChartBlockHeader from './ChartBlockHeader.vue';
import KeywordsInTopChart from '@/components/common/KeywordsInTopChart.vue';
import resize from 'vue-resize-directive';
import { datePickerList } from '@/components/date-range-picker/datePickerList'

export default {
    name: 'KeywordsInTopBlock',
    components: {
        'chart-header': ChartBlockHeader,
        'keywords-in-top-chart': KeywordsInTopChart
    },
    directives: {
        resize,
    },
    data() {
        return {
            chart: null,
            timeRanges: [],
            projectData: {},
            date: null,
            isLoading: false,
            interval: null,
            fetched: false,
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getProjectKeywordsInTopPositions',
            'getActiveTag',
            'getProjectOverviewOptionItem'
        ]),
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        },
    },
    watch: {
        getActiveTag: function() {
            this.fetchData();
        },
    },
    mounted(){
        if(!this.fetched && this.getCurrentProject?.project_id){
            this.fetchData();
        }
    },
    methods: {
        ...mapActions([
            'setProjectOverviewOptionIsVisible',
            'updateOnlyDisplaySettings',
            'fetchProjectKeywordsInTopPositionsChartData',
            'updateProjectTimeFrame'
        ]),
        debouncedHandler: _.debounce(fn => fn(), 50),
        resizeChart() {
            if (this.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
        onHide() {
            this.isLoading = true;
            this.setProjectOverviewOptionIsVisible({
                projectId: this.getCurrentProject.id,
                option: 'keywords_in_top',
                value: false 
            }).finally(()=>{
                this.isLoading = false;
            });;
        },
        onDateSelect(date, key) {
            this.updateProjectTimeFrame({
                projectId: this.getCurrentProject.id,
                option: 'keywords_in_top',
                value: {
                    date_range: {
                        start: date[0],
                        end: date[1]
                    }
                },
                interval: {
                    interval: key
                }
            })
            this.fetchData(date, key);
        },
        fetchData(dateValue, key){
            this.fetched = true;
            const { interval } = this.getProjectOverviewOptionItem('keywords_in_top');

            let intervalDate = null;
            if(key && key !== this.interval){
                this.date = null;
                this.interval = key;
                intervalDate = datePickerList[key].value;
            } else {
                this.interval = interval;
                if(!dateValue){
                    if(!this.date && interval){
                        this.interval = interval;
                        intervalDate = datePickerList[interval].value;
                    } else {
                        this.date = null;
                    }
                } else {
                    this.date = dateValue;
                }
            }

            const [ startDate, endDate ] = intervalDate ? intervalDate : this.date ? this.date : [null, null];

            const tagId = this.$route.query?.tag;
            this.fetchProjectKeywordsInTopPositionsChartData({ 
                startDate,
                endDate,
                projectId: this.getCurrentProject.project_id,
                tagId 
            }).then(()=>{
                this.init(this.getProjectKeywordsInTopPositions)
            });
        },
        setChart(value) {
            this.chart = value;
        },
        toggleTimeRange(idx) {
            const range = this.timeRanges[idx];
            this.timeRanges[idx].isChecked = !range.isChecked;
            const checked = this.timeRanges.reduce((acc, item) => {
                if (item.isChecked) {
                    acc.push(item.title);
                }
                return acc;
            }, []);

            this.projectData = {
                id: this.getCurrentProject.id,
                charts: this.getProjectKeywordsInTopPositions.data.filter(item => checked.includes(item.title)),
                categories: this.getProjectKeywordsInTopPositions.categories
            };
        },
        init(project){
            if (!project.data) return;

            this.timeRanges = project.data.map(item=> ({title: item.title, isChecked: true})).reverse();
            this.projectData = {
                charts: project.data,
                id: this.getCurrentProject.id,
                categories: project.categories
            };
        }
    },
    beforeDestroy(){
        this.projectData = {};
    }
};
</script>

<style lang='scss' scoped>
    .block-wrapper {
        width: 100%;
        height: 100%;
        min-height: 300px;

        .header-wrapper {
            margin-bottom: 24px;
        }
        .overview-chart-wrapper {
            margin-left: -6px;
            height: 288px;
        }

        .time-ranges {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .range {
                position: relative;
                padding-left: 24px;
                margin-bottom: 0;

                .text {
                    margin-top: 4px;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    letter-spacing: 0.01em;
                    color: kw-color(kw-light-grey, 8);
                }
            }
        }
    }
</style>
