export const BRAND_NAME = 'Keyword.com';
export const DEFAULT_LANGUAGE = 'en (English)';
export const DEFAULT_REGION = 'google.com';
export const LAST_UPDATE_DATE_FORMAT = 'M dS, Y hh:mm';
export const MINIMAL_LEFT_SIDEBAR_WIDTH = 312;
export const TYPING_DEBOUNCE = 500;

// routes constants
export const PUBLIC_ROUTES = [
    'login',
    'thankYou',
    'emailVerification',
    'pdfView',
    'privacy',
    'forgotPassword',
    'setNewPassword',
    'shareViewkey',
    'shareViewkeyWithHash',
    'shareOfVoice',
    'shareOfVoiceWithHash',
    'shareKeywordsTable',
    'shareKeywordsTableWithHash',
    'signup',
    'PageNotFound',
    'ServerError',
];

// table-columns constants
export const KW_TABLE_COLUMNS = {
    GEO: ['near', 'gmb'],
    PERIODS: ['day', 'week', 'month', 'life'],
    GSC: ['clicks', 'impressions', 'ctr'],
    WITHOUT_I: ['kw', 'url', 'source', 'serpFeatures', 'searchIntents'],
    SIMPLE: ['day', 'week', 'month', 'life', 'seocomp', 'clicks', 'impressions', 'ctr', 'searchvolume', 'competition', 'cpc', 'timestamp', 'gpage', 'best', 'source'],
    STATIC: ['checkbox', 'kw'],
    PDF_EXCLUDE: ['checkbox'],
};

// other
export const VIRTUAL_LIST_ITEM_HEIGHT = 33; // should be sync with each line height to avoid table jumping

export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const MOBILE_WIDTH_RESOLUTION_PHONE = 960;

export const KW_TOTALS_SETTINGS = ['kwtotalsmaster', 'kwtotalsadmin', 'kwtotalsregular', 'kwtotalsview'];

export const apiCodes = {
    CRITICAL_ERROR: '0',

    UNAUTHORIZED: '401',
    ENDPOINT_FORBIDDEN: '403',

    MALFORMED_REQUEST: '500',
    MAINTENANCE: '503',

    GROUP_NOT_FOUND: '1001',
    GROUP_DELETED: '1002',
    VIEWKEY_NOT_FOUND: '1003',

    KEYWORD_NOT_FOUND: '1011',
    KEYWORD_DELETED: '1012',
    KEYWORD_UPDATED: '1013',

    ADDON_USER_NOT_FOUND: '1015',
    ADDON_USER_DELETED: '1016',
    ACCESS_NOT_ALLOWED: '1017',

    NOTE_NOT_FOUND: '1020',
    NOTE_DELETED: '1021',

    MODEL_VALIDATION_ERROR: '2000',
    REQUEST_VALIDATION_ERROR: '2001',

    USER_SUBSCRIPTION_ERROR: '2010',
    USER_ALREADY_EXISTS: '2011',
    USER_REGISTRATION_ERROR: '2012',
    WRONG_USER_PASSWORD: '2013',
    ADDON_USER_ALREADY_EXISTS: '2014',
    USER_SUBSCRIPTION_INACTIVE: '2015',
    USER_SUBSCRIPTION_CHANGE_PLAN_ERROR: '2016',

    SPARSE_FIELD_NOT_FOUND: '3000',

    TOKEN_NOT_FOUND: '115',
    USER_NOT_FOUND: '114'
};

// no case sensitive
export const FIRST_GROUP_NAME = 'My First Project';

// default metrics period (for dashboard overview table)
export const DEFAULTS_METRICS = {
    PROP: 'monthly',
    TITLE: 'for 30 days',
};

export const PROTOCOL_REGEXP = /^(?:(http[s]?|ftp))/;
export const PROTOCOL_REGEXP_WITH_SLASHES = /^(?:(ht|f)tp(s?)\:\/\/)?/;

export const MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS = 50;

export const pdfExportParams = {
    selector: 'pdfWatcher',
    delay: 1000,
    per_page: 999999,
    page_width: '16.66in',
    page_height: '11.55in',
    margin_bottom: '0.31in',
    margin_top: '0in',
    layout_dpi: 72,
    element_to_convert: '.only-pdf',
};

export const TABLE_PARTS = {
    HEADER: 'HEADER',
    HEADER_ROW: 'HEADER_ROW',
    HEADER_COLUMN: 'HEADER_COLUMN',
    ROW: 'ROW',
    COLUMN: 'COLUMN',
    BODY: 'BODY',
    BODY_ROW: 'BODY_ROW',
    BODY_COLUMN: 'BODY_COLUMN',
    STATIC_BODY_COLUMN: 'STATIC_BODY_COLUMN',
    DYNAMIC_BODY_COLUMN: 'DYNAMIC_BODY_COLUMN',
}

export const SUBSCRIPTION_CHANGE_HEADER = 'x-change-type'

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPGRADE_PLAN = 'UPGRADE_PLAN';
export const DOWNGRADE_PLAN = 'DOWNGRADE_PLAN';

export const REPORT_TYPE = {
    CSV: 0,
    PDF: 1,
    TEXT: 2,
};

export const REPORT_TYPE_KEY = ['CSV', 'PDF', 'TEXT'];

export const ENGINE_KEYS = {
    'se': 'desktop',
    'sem': 'mobile',
}

export const REPORT_GENERATION_STATUS = {
    PENDING: 0,
    IN_PROGRESS: 1,
    FAILED: 2,
    FILE_IS_READY: 3,
    FILE_SENT: 4,
    FILE_DOWNLOADED: 5,
};

export const KEYWORD_SOURCES = {
    API: { title: 'API', value: 1 },
    GSC: { title: 'GSC', value: 2 },
    MANUAL: { title: 'Manual', value: 3 },
    IMPORT: { title: 'Import', value: 4 },
};

export const GTM_EVENTS = {
    SUCCESSFULLY_SIGNED_UP: 'successfully_signed_up',
    SUCCESSFULLY_LOGGED_IN: 'successfully_logged_in',
    PROJECT_ADDED: 'd_add_project',
    CLICK_SHARE_OF_VOICE_TAB: 'd_share_of_voice_view',
    CLICK_EXPORT: 'd_export_project_data',
    CLICK_SHARE_VIEW_KEY: 'd_share_view_key',
    KEYWORDS_ADDED: 'd_add_kw',
    USE_FILTER: 'd_used_filter',
    CLICK_SUGGESTIONS_TAB: 'suggestions_tab_opened',
    KEYWORDS_FROM_SUGGESTIONS_ADD: 'keywords_from_suggestions_add',
    SUGGESTIONS_REMOVED: 'suggestions_removed',
    SUGGESTIONS_RESTORED: 'suggestions_restored',
};

export const CUSTOM_TRIAL_COUPONS = {
    'BRIGHTONSEO2023': 92,
    'SNIPLY-REVIEW': 92,
    'OMR-REVIEWS': 92,
    '3M200': 92,
    '1MKWD': 30,
    'DEFAULT': 14,
};

export const IMPORT_DATA_STEPS = {
    STEP_1: 1,
    STEP_2: 2,
    STEP_3: 3,
};

export const IMPORT_DATA_STATUSES = {
    MATCH_HEADINGS: 0,
    READY_TO_VALIDATE: 1,
    QUEUED: 2,
    IN_PROGRESS: 3,
    SUCCESS: 4,
    FAILED: 5,
};

export const API_DOCUMENTATION_LINK = 'https://documenter.getpostman.com/view/26915172/2s93eSabYB#intro';

export const CUSTOM_SUBDOMAINS = {
    'jettr20@gmail.com': 'https://skyhighagency.keyword.com',
    'seo@gamedaymenshealth.com': 'https://gamedaymenshealth.keyword.com',
    'info@seoconsult.ca': 'https://miromind.seranktracker.com',
};

export const BILLING_CYCLES = {
    monthly: 'monthly',
    annually: 'annually',
};

export const SMTP_PROVIDERS_FOR_SNIPER_LINK = {
    'google': 'https://mail.google.com/mail/u/email/#search/from%3A(no-reply%40keyword.com)+in%3Aanywhere+newer_than%3A1h',
    'g-suite': 'https://mail.google.com/mail/u/email/#search/from%3A(no-reply%40keyword.com)+in%3Aanywhere+newer_than%3A1h',
    'microsoft': 'https://outlook.live.com/mail/?login_hint=${encodeURIComponent(email)}',
    'yahoo': 'https://mail.yahoo.com/d/search/keyword=from%253Ano-reply%40keyword.com',
    'proton': 'https://mail.proton.me/u/0/all-mail#from=no-reply%40keyword.com',
};

export const PROJECT_ACTIVE_TABS = {
    keywords: 0,
    topPages: 1,
    competitions: 2,
    suggestions: 3,
};

export const PROJECT_MAIN_TABS = {
    keywords: 0,
    overview: 1,
    topPages: 2,
    suggestions: 3,
    competitions: 4,
};

export const ONBOARDING_STATUSES = {
    previous: {
        HAVE_NOT: 1,
        XYZ: 2,
        MANUALLY: 3,
    },
    seoGoal: {
        IMPROVE_SEO: 1,
        SEND_REPORTS: 2,
        DISCOVER_COMPETITORS: 3,
        TRAKER_API: 4,
        OTHER: 5
    },
    source: {
        GOOGLE: 1,
        LINKEDIN_ADS: 2,
        SOCIAL_MEDIA: 3,
        BING: 4,
        WEBINAR: 5,
    },
    firstProject: null,
    finish: null,
};

export const INTEGRATED_SOCIAL_MEDIA_FOR_SIGN_IN = [
    'google', 'facebook', 'linkedin', 'microsoft'
];

export const DEFAULT_CURRENCY = {
    code: 'USD',
    symbol: '$',
};

export const IGNORE_CURRENCY = ['XDR'];

export const MIXPANEL_EVENT_CONSTANTS = {
    PROJECT_INITIATING_SOURCE: {
        ONBOARDING: 'onboarding',
        SIDE_PANEL: 'side_panel',
    },
    LEADING_SOURCE: {
        ACCOUNT: 'account',
        ONBOARDING: 'onboarding_popup',
        PROJECT_DASHBOARD: 'project_dashboard',
    },
    KEYWORD_TYPE: {
        'web': 'google_organic',
        'map': 'google_local_maps',
    },
};

export const CANCELATION_COUPON = 'SAVCHN20';

export const projectOverviewOptionsDefaultState = {
    project_metrics: {
        visible: true,
        order: 0,
        compared_date: null
    },
    keywords_in_top: {
        visible: true,
        order: 1,
        interval: null,
    },
    sov: {
        visible: true,
        order: 2,
        interval: null
    },
    distribution: {
        visible: true,
        order: 3,
        interval: null,
    },
    visibility: {
        visible: true,
        order: 4,
        interval: null,
    },
    estimated_traffic: {
        visible: true,
        order: 5,
        interval: null,
    },
    top_pages: {
        visible: true,
        order: 6,
        compared_date: null
    },
    organic_traffic_acquisition: {
        visible: true,
        order: 7,
        compared_date: null
    },
    serp_feature_performance: {
        visible: true,
        order: 8,
        compared_date: null
    },
    keywords_by_intent: {
        visible: true,
        order: 9,
        compared_date: null
    },
    average_rank: {
        visible: true,
        order: 10,
        interval: null,
    },
    visibility_and_estimated_traffic: {
        visible: false,
        order: 99,
        interval: null,
    },
};

export const overviewUsers = [
    'anita.christofidesparent@keyword.com',
    'flux@serpbook.com',
    'dev@serpbook.com',
    'kevin+demozapier@keyword.com',
    'mae.jay.t@gmail.com',
    'bogdan.soldatenkov@keyword.com',
];
