<template>
    <div
        class="display-settings__table"
        data-cy="table-other-settings"
    >
        <span class="kw kw-more-horizontal table_setting_icon" />
        <div>
            <h2
                class="settings__subtitle"
                v-text="$t('other-settings')"
            />
        </div>
        <div
            v-for="(item, i) in projectCheckboxesConfig"
            :key="i"
            :class="settingsItemClasses(item)"
        >
            <checkbox
                :id="`${item.name}-checkbox`"
                :data-cy="`checkbox-${item.name}`"
                :checkbox="checkboxCondition(item.name)"
                @checkbox-click="toggleCheckbox(item.name)"
            />
            <label
                :for="`${item.name}-checkbox`"
                :class="[item.class]"
                class="settings__label settings__label_other"
                @click="toggleCheckbox(item.name)"
                v-text="item.label"
            />
            <span
                v-if="item.tooltip"
                class="kw kw-information icon-info-in-cell"
                @mouseover="tooltip({ text: item.tooltip, event: $event, maxWidth: 163 })"
            />
            <!-- this will recover in future -->
            <!-- <template v-if="item.children && item.children.length">
                <div
                    v-for="(subitem, j) in item.children"
                    :key="j"
                    class="display-settings__subitem"
                >
                    <checkbox
                        :id="`${subitem.name}-checkbox`"
                        :checkbox="checkboxCondition(subitem.name)"
                        @checkbox-click="toggleCheckbox(subitem.name)"
                    />
                    <label
                        :for="`${subitem.name}-checkbox`"
                        class="settings__label settings__label_other"
                        @click="toggleCheckbox(subitem.name)"
                        v-html="subitem.label"
                    />
                </div>
            </template> -->
        </div>
        <!-- chart height setting -->

        <div class="display-settings__item__block">
            <!-- ranking area chart setting -->
            <!-- <label
                for="ranking-area"
                class="settings__label"
                v-text="$t('ranking-area-chart')"
            />
            <radio-buttons
                :options="rankingAreaChartOptions"
                :value="getOtherSetting('rankingAreaChart')"
                @radio_click="e => save({setting: 'rankingAreaChart', value: e})"
            /> -->
            <label
                for="ranking-area"
                class="settings__label text-wrap"
                v-text="getDisplayChartInKeywordTabText"
            />
            <radio-buttons
                :options="rankingAreaChartOptions"
                :value="getOtherSetting('rankingAreaChart')"
                @radio_click="e => save({setting: 'rankingAreaChart', value: e})"
            />

            <!-- google analytics setting -->
            <label
                for="google-analytics"
                class="settings__label"
                v-text="$t('google-analytics-chart')"
            />
            <radio-buttons
                :options="googleChartsOptions"
                :value="getOtherSetting('googleAnalyticsCharts')"
                @radio_click="e => save({setting: 'googleAnalyticsCharts', value: e})"
            />
            <!-- global ms setting -->
            <label
                class="settings__label"
                v-text="$t('global-search-volume')"
            />
            <radio-buttons
                :options="globalMSSettings"
                :value="getOtherSetting('global')"
                @radio_click="e => save({setting: 'global', value: e})"
            />
        </div>
    </div>
</template>

<script>
    import 'vue-slider-component/theme/antd.css';
    import {mapGetters, mapActions} from 'vuex';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import ValidationMixin from '@/mixins/ValidationMixin';
    import {overviewUsers} from '@/constants';

    import {
        globalMSSettings,
        googleChartsOptions,
        rankingAreaChartOptions,
        perPageConfig,
        projectCheckboxesConfig,
    } from '@/components/settings/configs';

    export default {
        name: 'TableOtherSettings',

        mixins: [
            ValidationMixin,
            ToolTipRenderMixin,
        ],
        data () {
            return {
                googleChartsOptions,
                rankingAreaChartOptions,
                perPageConfig,
                globalMSSettings,
                inited: false,
                projectCheckboxesConfig,
            };
        },
        computed: {
            ...mapGetters([
                'getDisplaySetting',
                'getOtherSetting',
                'getDisplaySettings',
                'getUserDataProp'
            ]),
            getDisplayChartInKeywordTabText(){
              const email = this.getUserDataProp("email");
              return overviewUsers.includes(email) ? this.$t('display-visibility-and-est-traffic') : this.$t('ranking-area-chart')
            }
        },
        mounted () {
            this.$nextTick(() => this.inited = true);
        },
        methods: {
            ...mapActions([
                'updateUserTableSettings',

            ]),
            checkboxCondition (name) {
                return this.getOtherSetting(name) === 'show';
            },
            toggleCheckbox (name) {
                this.updateUserTableSettings({
                    setting: name,
                    value: this.getOtherSetting(name) === 'show' ? 'hide' : 'show',
                });

            },
            save (setting) {
                if (!this.inited) return;
                this.updateUserTableSettings(setting);
            },

            settingsItemClasses (item) {
                return {
                    'display-settings__item': true,
                    'display-settings__item_parent': item.children && item.children.length,
                };
            },
        },
    }
</script>

<style lang="scss">
  @import '~sass/partials/dropdowns';

  .settings__label_with_tooltip {
      flex: none !important;
      margin-right: 3px;
  }

  .display-settings__table {
    display: flex;
    flex-flow: column nowrap;
    padding: 43px 24px;

    .button-closer {
      align-self: flex-end;
    }

    .form-control.dropdown-toggle {
      @extend .custom-dropdown;
      color: kw-color(kw-black, 1);
    }

    .btn-group {
      margin-bottom: 16px;

      .btn {
        border-color: #DEE5EC;
        box-shadow: none;
        color: kw-color(kw-black, 1);
        font-size: 13px;
        line-height: 15px;
        outline: transparent !important;
        width: 50%;

        &:hover {
          background-color: transparent;
        }

        &.active {
          background-color: kw-color(kw-blue, 1);
          border-color: kw-color(kw-blue, 1);
          color: kw-color(kw-white, 1);
        }
      }
    }

    .open .form-control.dropdown-toggle {
      @extend .custom-dropdown_active;
    }

    .dropdown-menu {
      @extend .custom-scroll;
      @extend .custom-dropdown-menu;
      width: 100%;

      .glyphicon.glyphicon-ok.check-mark:before {
        content: '';
      }
    }

    .vue-slider {
      padding: 10px 0 8px 0 !important;

      .vue-slider-rail {
        background-color: #DEE5EC;
        box-shadow: none;

        .vue-slider-process {
          box-shadow: none;
          background-color: kw-color(kw-blue, 1);
        }

        .vue-slider-dot-handle {
          background-color: kw-color(kw-blue, 1);
          border: none;
        }
      }
    }

    .radio-buttons {
      display: flex;

      .radio-button {
        &:first-child {
          margin-right: 12px;
        }
      }
    }

    .display-settings__item {
      min-width: max-content;
      box-sizing: border-box;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 10px 0;
      position: relative;
      max-width: 350px;

      .checkbox {
        position: relative !important;
        top: unset;
      }

      &_parent {
        position: relative;

        &:before {
          content: '';
          width: 1px;
          height: calc(100% - 42px);
          background-color: #C4C4C4;
          position: absolute;
          top: 32px;
          left: 8px;
        }
      }

      &:last-child {
        margin-bottom: 6px;
      }

      &__block {
        border-top: 1px solid #E1E8EF;
        padding-top: 10px;
        max-width: 350px;
      }
    }

    .display-settings__subitem {
      align-items: center;
      display: flex;
      flex-grow: 1;
      padding: 5px 0 5px 24px;
      position: relative;

      &:before {
        border-bottom: 1px solid #C4C4C4;
        content: '';
        left: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 11px;
      }

      &:last-child {
        &:after {
          background-color: kw-color(kw-white, 1);
          content: '';
          height: 28px;
          left: 6px;
          position: absolute;
          top: calc(50% + 1px);
          width: 4px;
        }
      }

      @media screen and (max-width: 800px) {
        flex-basis: 100%;
      }
    }

    .settings__label_other {
      cursor: pointer;
      flex-grow: 1;
      font-weight: normal !important;
      margin-bottom: 0;
      padding-left: 8px;
    }

    .settings__label_other {
      justify-content: flex-start;
      text-transform: unset;

      span {
        color: kw-color(kw-green, 1);
      }

      @media screen and (max-width: 480px) {
        max-width: calc(100% - 16px);
      }
    }
  }

  #table-width {
    margin-bottom: 8px;
  }

  .display-settings__limits {
    color: kw-color(kw-grey, 1);
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    line-height: 15px;
    padding-bottom: 20px;
  }

  .display-settings__ranges {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    input {
      text-align: center;
      width: 54px;
      @media screen and (max-width: 480px) {
        padding: 10px 10px;
        width: 44px;
      }
    }
  }

  .display-settings__table-sort {
    display: flex;
    flex-flow: row nowrap;

    .btn-group {
      width: 50%;

      &:first-child {
        margin-right: 8px;
      }
    }
  }

  .settings__label {
    justify-content: initial !important;
    &.text-wrap {
      white-space: break-spaces;
    }
  }

  .settings__label_chart-height {
    justify-content: space-between !important;
  }

  .kw-information {
    color: #828F9C;
    font-size: 12px;
    font-weight: 700;
    margin: 0 4px;
  }
</style>
