<template>
    <div class="custom-modal">
        <keyword-chart
            :close-handler="
                () => {
                    toggleShowSingleChart(keyword);
                    close();
                }
            "
            :keywords="[keyword]"
        />
    </div>
</template>

<script>
const KeywordChart = () => import('components/main-table/KeywordChart');
export default {
    name: 'KwHistoricalChart',
    props: {
        keyword: {
            type: Object,
            required: true,
        },
        toggleShowSingleChart: {
            type: Function,
            required: true,
        },
    },
    components: { KeywordChart },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';
.custom-modal {
    overflow-y: auto;
    height: 100%;
}

.keyword-chart {
    width: 100%;
}
</style>
