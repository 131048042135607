
import i18n from '@/i18n';
import apiFactory from '@/api';
import { Events, EventBus } from '@/events';
import toastr, {updateProgress} from '@/helpers/init-toastr';
import { GTM_EVENTS, ENGINE_KEYS, KEYWORD_SOURCES } from '@/constants';

const projectsApi = apiFactory.get('projects');

const PORTION_SIZE = 500;
const partialKeywordAdd = async (data, getters, dispatch, preparedKeywordsLength, cycle, updateAddedCallback) => {
    const portion = data.splice(0, PORTION_SIZE);
    const packageKeywordsCount = getters.getPackageKeywords;
    const packageUsedKeywordsCount = getters.getUsedKeywords;
    let GTM_DATA = {};

    if (portion[0].attributes.source === KEYWORD_SOURCES.GSC.value) {
        /**
         * GTM custom event keywords_from_suggestions_add
         */
        GTM_DATA = {
            event: GTM_EVENTS.KEYWORDS_FROM_SUGGESTIONS_ADD,
            uid: getters.getUserData.userId,
        };
    } else {
        /**
         * GTM custom event d_share_view_key
         */
        GTM_DATA = {
            event: GTM_EVENTS.KEYWORDS_ADDED,
            domains: [],
            regions: [],
            languages: [],
            search_engines: [],
            devices: [],
            uid: getters.getUserData.userId,
            used_tags: 'no',
        };

        portion.forEach(keyword => {
            let engine = keyword.attributes.ignore_local ? 'google_organic' : 'google_local_maps';
            let device = ENGINE_KEYS[keyword.attributes.type];

            if (GTM_DATA.domains.indexOf(keyword.attributes.url) === -1) {
                GTM_DATA.domains.push(keyword.attributes.url);
            }

            if (GTM_DATA.regions.indexOf(keyword.attributes.region) === -1) {
                GTM_DATA.regions.push(keyword.attributes.region);
            }

            if (GTM_DATA.languages.indexOf(keyword.attributes.language) === -1) {
                GTM_DATA.languages.push(keyword.attributes.language);
            }

            if (GTM_DATA.search_engines.indexOf(engine) === -1) {
                GTM_DATA.search_engines.push(engine);
            }

            if (GTM_DATA.devices.indexOf(device) === -1) {
                GTM_DATA.devices.push(device);
            }

            if (GTM_DATA.used_tags === 'no' && keyword.attributes.tags.length) {
                GTM_DATA.used_tags = 'yes';
            }
        });
    }

    const res = await projectsApi.addKeywords(getters.getCurrentProject.id, { data: portion });

    if (Object.keys(GTM_DATA).length) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(GTM_DATA);
    }

    if (portion[0].attributes.source === KEYWORD_SOURCES.GSC.value) {
        EventBus.emit(Events.CREATE_KWS_FROM_SUGGESTIONS, portion);
    }

    if ((packageUsedKeywordsCount + preparedKeywordsLength) <= packageKeywordsCount) {
        if (!document.getElementById('toastr-with-progress')) {
            toastr.createToastr({
                msg: i18n.t('keywords-adding-process', {progress: `${portion.length}/${preparedKeywordsLength}`}),
                type: 'info',
                withProgress: true,
            }, { timeOut: '0' });
            updateProgress(cycle, PORTION_SIZE, preparedKeywordsLength);
        } else {
            updateProgress(cycle, PORTION_SIZE, preparedKeywordsLength);
        }
    }
    const addedKeywordsLength = res.data.meta.counts.added;
    updateAddedCallback(addedKeywordsLength);

    dispatch('changeKeywordsCount', {
        id: getters.getCurrentProject.id,
        count: addedKeywordsLength,
        type: 'increase',
    });

    if (data.length) {
        await partialKeywordAdd(data, getters, dispatch, preparedKeywordsLength, cycle + 1, updateAddedCallback);
    } else {
        // not to close the toaster immediately
        setTimeout(toastr.clear, 1500);
    }
    return res;
};

export{
    partialKeywordAdd,
}