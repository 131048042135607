import Vue from '@/app';
import apiFactory from '@/api';
import { Events, EventBus } from '@/events';
import { resetApiToken } from '@/api/fileManager';
import { cookieSetters } from '@/helpers/cookieManager';
import { recurringProgressChecking } from '@/helpers/downloads-service';
import { IMPORT_DATA_STATUSES } from '@/constants';
import ImportFinishedModal from 'components/modals/ImportFinishedModal';
import { SET_IMPORT_ACTIVITY_LOG_ID, SET_IMPORT_ACTIVITY_LOG_DATA } from '@/store/mutations';

const importDataApi = apiFactory.get('importData');

export default {
    state: {
        importActivityLogId: null,
        importActivityLogData: null,
    },
    mutations: {
        [SET_IMPORT_ACTIVITY_LOG_ID]: (state, payload) => {
            cookieSetters.setImportActivityLogId(payload);
            state.importActivityLogId = payload;
        },
        [SET_IMPORT_ACTIVITY_LOG_DATA]: (state, payload) => state.importActivityLogData = payload,
    },
    getters: {
        getImportActivityLogId: state => state.importActivityLogId,
        getImportActivityLogData: state => state.importActivityLogData,
    },
    actions: {
        async uploadImportDataFile(context, file) {
            try {
                let formData = new FormData();
                formData.append('imported_file', file);
                resetApiToken();

                const res = await importDataApi.uploadImportDataFile(formData);

                return {
                    data: res.data.data,
                    fileName: file.name,
                };
            } catch (error) {
                EventBus.emit(Events.IMPORT_ERROR_EVENT, {
                    num_keywords: error?.properties?.error_message?.num_keywords ?? 0,
                    error_message: error?.properties?.error_message?.response?.data?.errors?.[0]?.detail
                        ?? error?.response?.statusText
                        ?? 'Uncaught error',
                });
                console.error(error);
            }

            return false;
        },
        async updateHeaders(context, { id, tableData }) {
            try {
                const res = await importDataApi.updateHeaders(id, tableData);

                return res.data.data;
            } catch (error) {
                EventBus.emit(Events.IMPORT_MAPPING_ERROR_EVENT, {
                    error_message: error?.response?.data?.errors[0]
                        ? error?.response?.data?.errors[0]?.detail
                        : error.message
                });
                console.error(error);
            }

            return false;
        },
        async fetchInvalidRows(context, id) {
            try {
                const res = await importDataApi.fetchInvalidRows(id);

                return res.data.data;
            } catch (error) {
                console.error(error);
            }

            return false;
        },
        async updateInvalidRow(context, { id, rowIndex, tableData }) {
            try {
                let params = {
                    row_index: rowIndex,
                    data: tableData,
                }

                const res = await importDataApi.updateInvalidRow(id, params);

                return res.data;
            } catch (errorData) {
                try {
                    return errorData.response.data;
                } catch (error) {
                    console.error(error);
                }
            }
        },
        async acceptImporting({ commit, dispatch }, { id, skipInvalidRows }) {
            try {
                let params = {
                    skip_invalid_rows: skipInvalidRows,
                }

                const res = await importDataApi.acceptImporting(id, params);

                commit(SET_IMPORT_ACTIVITY_LOG_ID, id);
                EventBus.emit(Events.CREATE_KWS_FROM_IMPORT, { import_activity_log_id: id });
                dispatch('recurringCheckProgress', id);

                return res.data.data;
            } catch (error) {
                console.error(error);
            }

            return false;
        },
        async fetchImportingProgress({ commit }, id) {
            let keepChecking = true;

            try {
                const res = await importDataApi.fetchImportingProgress(id);
                const data = res.data.data;
                commit(SET_IMPORT_ACTIVITY_LOG_DATA, data);

                if (
                    data.total === data.imported ||
                    data.status === IMPORT_DATA_STATUSES.SUCCESS
                ) {
                    Vue.$modal.show(
                        ImportFinishedModal,
                        {},
                        {
                            classes: 'v--modal center-modal-popup',
                            height: 'auto',
                            name: 'ImportFinishedModal',
                            width: 420,
                        },
                    );

                    keepChecking = false;
                }
            } catch (error) {
                console.error(error);

                keepChecking = false;
            } finally {
                if (!keepChecking) {
                    commit(SET_IMPORT_ACTIVITY_LOG_ID, null);
                    commit(SET_IMPORT_ACTIVITY_LOG_DATA, null);
                }
            }

            return keepChecking;
        },
        recurringCheckProgress({ dispatch }, id) {
            recurringProgressChecking(() => dispatch('fetchImportingProgress', id));
        },
    }
}
