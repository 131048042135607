<template>
    <div
        v-if="showViewkey"
        :class="shareViewkeyClasses"
        @mouseover="mouseoverHandler"
    >
        <share-header v-if="getIsFreePlan || isHeaderVisible" />

        <div class="share-data__wrapper">
            <share-data v-if="showViewkeyData" :project="getCurrentProject" />
        </div>

        <div class="share-top-bar">
            <refresh :title="projectDashboardTitle" />
            <share-dropdown
                :project="getViewkeyProjectName"
                :subproject="getViewkeySubprojectName"
            />
        </div>

        <project />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginForViewkey from 'components/modals/LoginForViewkey';
import Project from 'components/main-block/components/Project';
import Refresh from 'components/refresh/Refresh';
import ShareDropdown from 'components/common/ShareDropdown';
import ShareData from 'components/share-viewkey/ShareData';
import ShareHeader from 'components/share-viewkey/ShareHeader';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
export default {
    name: 'ShareViewkey',
    components: {
        project: Project,
        refresh: Refresh,
        'share-dropdown': ShareDropdown,
        'share-data': ShareData,
        'share-header': ShareHeader,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        viewkey: {
            type: String,
            required: false,
            default: '',
        },
        auth: {
            type: String,
            required: false,
            default: '',
        },
        client: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getIsFreePlan',
            'getListOfKeywordsDomains',
            'getProjectProperty',
            'getSelectedAuth',
            'getViewkeyAccessStatus',
            'getViewkeyProjectName',
            'getViewkeySubprojectName',
        ]),
        getWebsiteUrl() {
            return process.env.KEYWORD_WEBSITE_URL;
        },
        showViewkey() {
            return (
                this.getViewkeyAccessStatus &&
                this.getViewkeyAccessStatus !== 'denied'
            );
        },
        showViewkeyData() {
            return this.getCurrentProject && this.getCurrentProject.vkinfo;
        },
        projectDashboardTitle() {
            return this.getListOfKeywordsDomains?.length === 1
                ? this.getListOfKeywordsDomains[0]
                : this.getProjectProperty('name');
        },
        shareViewkeyClasses() {
            return {
                'share-viewkey': true,
                'share-viewkey_action-bar': this.getSelectedAuth.length,
            };
        },
    },
    watch: {
        showViewkey(val) {
            this.openLoginModalForViewkey(val);
        },
    },
    async created() {
        this.setIsViewkeyView(true);
        await this.$store.dispatch('getViewkeyInfo', {
            shortkey: this.viewkey,
            id: this.auth,
            client: this.client,
        });

        const {
            name: routeName,
            params: routeParams,
        } = this.$router.currentRoute;
        if (routeName === 'shareViewkeyWithHash' || routeName === 'shareKeywordsTableWithHash') {
            await this.getTagDataForShareAction(routeParams.hash);
        }
        this.openLoginModalForViewkey(this.showViewkey);
    },
    beforeCreate() {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        document.getElementsByTagName('head')[0].append(meta);
    },
    methods: {
        ...mapActions(['setIsViewkeyView', 'getTagDataForShareAction']),
        openLoginModalForViewkey(val) {
            if (!val) {
                this.$modal.show(
                    LoginForViewkey,
                    {
                        viewkey: this.viewkey,
                        auth: this.auth,
                        client: this.client,
                    },
                    {
                        width: 282,
                        height: 'auto',
                        classes: 'v--modal v--modal_login-viewkey',
                        clickToClose: false,
                        name: 'loginForViewkeyModal',
                    },
                );
            } else {
                this.$modal.hide('loginForViewkeyModal');
            }
        },
    },
};
</script>

<style lang="scss">
.share-viewkey {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 44px;
    position: relative;
    width: 100%;

    .dashboard-table-wrap {
        margin: auto;
        max-width: 1080px;
    }

    &.share-viewkey_action-bar {
        .dashboard-table-wrap {
            .keywords-container {
                &.keywords-container_share-view {
                    .keywords-table__wrapper {
                        .keywords-table__header {
                            &.keywords-table__header_fixed {
                                top: 91px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.share-data__wrapper {
    margin-top: 32px;
    display: flex;
    flex-flow: column nowrap;
    max-width: 1080px;
    width: 100%;
    @media screen and (min-width: 960px) {
        .dashboard-table-wrap {
            flex-grow: 1;
            min-width: 650px;
        }
    }
}

.share-top-bar {
    align-items: center;
    background-color: kw-color(kw-white, 1);
    box-shadow: 3px 0 0 kw-color(kw-white, 1);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1080px;
    min-width: 280px;
    padding: 16px 16px 0;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 12;

    .refresh {
        padding-bottom: 10px;
        width: 100%;
    }

    .share-export-dropdowns {
        padding-bottom: 10px;
        width: 100%;
    }
}

@media (min-width: 960px) {
    .share-viewkey_action-bar {
        padding-bottom: 84px;
    }
    .share-top-bar {
        flex-flow: row nowrap;
        padding: 16px 0 0 0;

        .share-export-dropdowns {
            width: unset;
        }
    }
}
</style>
