export default {
    'empty-project-name': 'Project name cannot be empty',
    'empty-group-name': 'Group name cannot be empty',
    'invalid-project-name': 'Invalid project name',
    'project-name-max-error': 'The limit is 50 characters. Please, shorten the Project name.',
    'project-already-exist': 'Project "{name}" already exists',
    'group-already-exist': 'Group "{name}" already exists',
    'password-validation': 'Password must have 8-100 characters',
    'old-password-validation': 'Password can not be empty',
    'company-name-max-error': 'The limit is 50 characters. Please, shorten the Company name.',
    'city-name-max-error': 'The limit is 255 characters. Please, shorten the City name.',
    'description-name-max-error': 'The limit is 1000 characters. Please, shorten the ViewKey Company Description.',
    'location-should-be-valid': 'Location should be valid',
    'near-should-be-valid': 'Location should be valid',
    'gmb-should-be-valid': 'GMB should be valid',
    'unable-load-image': 'We are unable to load this image possibly due to cross-domain image display permissions. Please try another image hosted somewhere else.',
    'field-empty-error': 'The field cannot be empty.',
};