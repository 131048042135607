<template>
    <div class="confirm-handler-modal">
        <div v-if="header" class="header" v-html="header" />
        <div v-if="body" class="body">
            {{ body }}
        </div>
        <div :class="footerClasses">
            <kw-button
                v-if="showNegativeButton"
                :button-text="negativeButton"
                button-type="text"
                data-cy="simple-confirm-cancel"
                @click="cancel"
            />
            <kw-button
                :button-text="positiveButton"
                button-type="primary"
                data-cy="simple-confirm-accept"
                :loading="loading"
                @click="confirm"
            />
        </div>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
export default {
    name: 'SimpleConfirmWithCustomHandler',
    props: {
        header: {
            type: String,
            required: false,
            default: '',
        },
        body: {
            type: String,
            required: false,
            default: '',
        },
        positiveButton: {
            type: String,
            required: false,
            default: 'OK',
        },
        negativeButton: {
            type: String,
            required: false,
            default: 'Cancel',
        },
        handler: {
            type: Function,
            required: true,
        },
        showNegativeButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        footerClasses() {
            return {
                footer: true,
                footer_centered: !this.showNegativeButton,
            };
        },
    },
    methods: {
        async confirm() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            await this.handler(true);
            this.loading = false;
            this.close();
        },
        cancel() {
            this.handler(false);
            this.close();
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';

.confirm-handler-modal {
    padding: {
        bottom: 24px;
        left: 24px;
        right: 8px;
        top: 24px;
    }

    .header {
        color: kw-color(kw-black, 7);
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 24px;
        padding-right: 13px;
        text-align: left;
    }

    .body {
        @extend .custom-scroll;
        margin-bottom: 20px;
        margin-top: 24px;
        max-height: 25vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 13px;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        padding-right: 13px;
    }

    .footer_centered {
        justify-content: center;
    }
}
</style>
