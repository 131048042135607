import {
    SET_ACCOUNT_SETTINGS,
    SET_BILLING_DETAILS,
    SET_BILLING_DETAILS_LOADED,
    SET_API_KEY_RESET_IN_PROGRESS,
} from '@/store/mutations';
import apiFactory from '@/api';
import { logout } from '@/helpers/service';

const usersApi = apiFactory.get('users');
const billingApi = apiFactory.get('billing');
const legacyApi = apiFactory.get('legacy');

const account = {
    state: {
        accountSettings: {
            avatar: null,
            contactnum_code: 'US',
            contactnum: '1',
            country: 'US',
            countrystate: 'Alabama',
            name: '',
        },
        billingDetails: {
            country: 'US',
            countrystate: 'Alabama',
            postal_code: '',
            company: '',
            street: '',
            suite: '',
            city: '',
            tax_id_type: null,
            tax_id : ''
        },
        billingDetailsLoaded: false,
        apiKeyResetInProgress: false,
    },
    mutations: {
        [SET_ACCOUNT_SETTINGS]: (state, settings) => state.accountSettings = settings,
        [SET_BILLING_DETAILS]: (state, settings) => state.billingDetails = settings,
        [SET_BILLING_DETAILS_LOADED]: (state, payload) => state.billingDetailsLoaded = payload,
        [SET_API_KEY_RESET_IN_PROGRESS]: (state, payload) => state.apiKeyResetInProgress = payload,
    },
    getters: {
        getAccountSetting: state => prop => state.accountSettings[prop],
        getAccountSettings: state => state.accountSettings,
        getBillingDetail: state => prop => state.billingDetails[prop],
        getBillingDetails: state => state.billingDetails,
        getBillingDetailsLoaded: state => state.billingDetailsLoaded,
        getApiKeyResetInProgress: state => state.apiKeyResetInProgress,
    },
    actions: {
        async updatePassword(context, settings) {
            try {
                await usersApi.updatePassword({
                    old_password: settings.current,
                    new_password: settings.new,
                    confirm_password: settings.new,
                });
            } catch (error) {
                // we should detect wrong old password directly in the vue-compunent
                if (error.response.data.errors && error.response.data.errors.length) {
                    throw (error.response.data.errors);
                }
            }
        },
        async updateAccount({ dispatch }, settings) {
            try {
                await usersApi.updateAccount(settings);
                dispatch('setAccountSettings', settings);
            } catch (error) {
                //
            }
        },
        setAccountSettings({ commit, getters }, payload) {
            const currentSettings = getters.getAccountSettings;
            const newSettings = {
                ...currentSettings,
            };
            Object.keys(currentSettings)
                .forEach(key => newSettings[key] = payload[key] || '')
            commit(SET_ACCOUNT_SETTINGS, newSettings);
        },

        async updateBillingDetails({ dispatch, getters }, settings) {
            try {
                await billingApi.updateBillingDetails(settings);
                dispatch('setBillingDetails', settings);

                /**
                 * GTM custom event add_payment_method
                 */
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'add_payment_method',
                    uid: getters.getUserData.userId,
                });
            } catch (error) {
                return Promise.reject(error);
            }
        },

        setBillingDetails({ commit, getters }, payload) {
            const currentDetails = getters.getBillingDetails;
            let newDetails = currentDetails;

            if (Object.keys(payload).length) {
                newDetails = {
                    ...currentDetails,
                };
                Object.keys(currentDetails)
                    .forEach(key => newDetails[key] = payload[key] || '')
            }

            commit(SET_BILLING_DETAILS, newDetails);
            commit(SET_BILLING_DETAILS_LOADED, true);
        },

        async resetApiKey({ commit, dispatch }) {
            commit(SET_API_KEY_RESET_IN_PROGRESS, true);

            try {
                let res = await legacyApi.resetApiKey();

                if (res.data.RESPONSE == 'SUCCESS') {
                    logout();
                }
            } catch (error) {
                return Promise.reject(error);
            } finally {
                commit(SET_API_KEY_RESET_IN_PROGRESS, false);
            }
        },

        async sendEmailToSupport({ commit, dispatch }){
            try {
                await usersApi.sendEmailToSupport();

                return Promise.resolve(true);
            } catch (error) {
                return Promise.reject(error);
            } 
        }
    },
};

export default account;
