<template>
    <main-block-with-overview v-if="showNew" />
    <main-block v-else-if="showOld" />
</template>

<script>
    import {mapGetters} from 'vuex';
    import MainBlock from '@/components/main-block/MainBlock.vue';
    import MainBlockWithOverview from '@/components/main-block/MainBlockWithOverview.vue';
    import {overviewUsers} from '@/constants';
    
    export default {
        name: 'ProjectPage',
        components: {
            'main-block': MainBlock,
            'main-block-with-overview': MainBlockWithOverview,
        },
        data(){
            return {
                users: overviewUsers
            }
        },
        computed: {
            ...mapGetters([
                'getUserDataProp',
            ]),
            showNew(){
                return this.users.includes(this.getUserDataProp('email'));
            },
            showOld(){
                return !!this.getUserDataProp('email');
            },
        },
    }
</script>
