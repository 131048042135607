<template>
    <div class="circular-progress-bar-wrapper">
        <span class="progress-bar-name" v-if="message" v-text="message" />
        <!-- the starting angle is calculated using a radian -->
        <vue-circle
            :ref="uniqueRef"
            :progress="progress"
            :size="65"
            :reverse="false"
            line-cap="round"
            :fill="color"
            empty-fill="rgba(0, 0, 0, .1)"
            :animation-start-value="0.0"
            :start-angle="-1.5708"
            insert-mode="append"
            :thickness="7"
            :show-percent="false"
        >
            <span
                class="progress-bar-percent"
                @mouseover="
                    tooltip({
                        text: `${used} / ${limit} (${progressTooltip}%)`,
                        event: $event,
                        disabled: false,
                    })
                "
                >{{ progress }}%</span
            >
        </vue-circle>
    </div>
</template>

<script>
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import VueCircle from 'vue2-circle-progress/src/index.vue';

export default {
    name: 'CircularProgressBar',
    components: {
        VueCircle,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        limit: {
            type: Number,
            required: true,
        },
        used: {
            type: Number,
            required: true,
        },
        color: {
            type: Object,
            required: true,
        },
        message: {
            type: String,
            default: null,
        },
        uniqueRef: {
            type: String,
            default: '',
        },
    },
    computed: {
        progress() {
            const progressPercent = Math.floor((this.used / this.limit) * 100);
            return this.limit == 0
                ? 0
                : progressPercent >= 100
                ? 100
                : progressPercent;
        },
        progressTooltip() {
            return this.limit == 0
                ? 0
                : Number(((this.used / this.limit) * 100).toFixed(1));
        },
    },

    watch: {
        used() {
            // updateProgress(value) - It will update component progress value and animate the change. It doesn't redraw the widget.
            this.$refs[this.uniqueRef].updateProgress(this.progress);
        },
        limit() {
            this.$refs[this.uniqueRef].updateProgress(this.progress);
        },
    },
};
</script>

<style lang="scss" scoped>
.circular-progress-bar-wrapper {
    width: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .circular-progress-bar .percent-text {
        font-weight: 400 !important;
    }

    .progress-bar-percent {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-size: 17px;
    }

    .progress-bar-name {
        display: flex;
        padding-top: 4px;
        margin-bottom: 1.5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11.3px;
        line-height: 12px;
        text-align: center;
        color: #63697b;
    }

    .circle {
        display: flex;
    }
}
</style>
