const enAuth = {
    'thank-you': {
        'title': 'Check your email',
        'info-msg': 'We sent a verification link to',
        'open-my-inbox': 'Open My Inbox',
        'open-login-page': 'Go to Log in',
        'do-not-receive-msg': 'Didn’t receive the email?',
        'resend-btn': 'Click to resend.',
        'resent-msg': 'An email has been sent to {email}',
    },
    'verify': {
        'account-activation': 'Account activation is in process',
        'account-activated': 'Account activated',
        'error-msg': 'Something went wrong, please try again.',
        'active-account-msg': 'Thank you, your email has been verified.',
        'button-text': 'Continue to app',
    },
    'login': 'Log In',
    'signup': 'Sign Up',
    'info-section': {
        'login-title': 'Not your average rank tracker.',
        'login-desc': 'We\'re bringing the heat. 🔥🔥🔥',
    },
    'login-form': {
        'email': 'Enter your email',
        'password': 'Enter your password',
        'forgot-password': 'Forgot password?',
    },
    'signup-form': {
        'email': 'Enter your email',
        'password': 'Enter your password',
        'privacy-policy': 'Privacy Policy.',
        'terms-of-service': 'Terms of Service',
        'reg-privacy-msg': 'and acknowledge the',
        'reg-terms-msg': 'By clicking on Sign Up you agree to our',
        'password-hint': 'Must be at least 8 characters and one special character',
        'title': '{type} Package',
        'trial': '14-Day Free Trial',
        'duration': {
            '0': '',
            '14': '14 Days',
            '30': '1 Month',
            '92': '3 Months',
        },
        'billing-cycle': {
            'monthly': 'Monthly',
            'annually': 'Yearly',
            'hint': 'Amount is exclusive of applicable taxes'
        },
        'coupon': {
            'code': 'Coupon Code',
            'msg-error': 'This coupon code is invalid',
            'msg-success': 'Coupon code successfully applied',
        },
    },
    'auth-note': {
        'title': {
            'free': 'Unleash the power of Keyword.com for 14 days for FREE! Access all features and track 200 keywords daily with no credit card required.',
            'paid': '{type} package with <b>{keywords_tracked}</b> keywords tracked <b>{cycle}.<b>',
            'paid-trial': 'Track {count} keywords and access all our features with our 14-day free trial.',
        },
        'msg': 'No credit card needed.',
    },
    'auth-errors': {
        'user-disabled': 'This Master Account is disabled. To log in please use an admin or addon user account.',
        'login': 'Your email or password is incorrect.',
    },
    'forgot-password': {
        'title': 'Forgot your password?',
        'bak-to-login': 'Back to log in',
    },
    'set-new-password': {
        'title': 'Set new password',
        'bak-to-login': 'Back to log in',
    },
    'log-in-with': 'Log in with',
    'sign-up-with': 'Sign up with',
};

export default enAuth;
