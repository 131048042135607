import {KW_TOTALS_SETTINGS} from '@/constants';


export const prepareColumnsAndOtherSettings = (getters, {setting, value}) => {
    // prepare table columns and other settings
    const tableAndOtherData = {};
    const otherSettings = {...getters.getOtherSettings};
    const columnsSettings = [...getters.getColumnsConfig];

    if (otherSettings.hasOwnProperty(setting)) {
        otherSettings[setting] = value;
    }

    if (setting === 'kwtotals') {
        if (otherSettings.kwtotals === 'show' && KW_TOTALS_SETTINGS.every(el => otherSettings[el] === 'hide')) {
            KW_TOTALS_SETTINGS.forEach(el => otherSettings[el] = 'show');
        }
        if (otherSettings.kwtotals === 'hide') {
            KW_TOTALS_SETTINGS.forEach(el => otherSettings[el] = 'hide');
        }
    }

    if (KW_TOTALS_SETTINGS.includes(setting)) {
        if (KW_TOTALS_SETTINGS.some(el => otherSettings[el] === 'show')) {
            otherSettings.kwtotals = 'show';
        }
        if (KW_TOTALS_SETTINGS.every(el => otherSettings[el] === 'hide')) {
            otherSettings.kwtotals = 'hide';
        }
    }

    const columnIndex = columnsSettings.findIndex(el => el.apiAlias === setting);
    const reportColumnIndex = columnsSettings.findIndex(el => el.apiAliasReports === setting);

    if (columnIndex > -1) {
        columnsSettings[columnIndex].enabled = value;
    }

    if (reportColumnIndex > -1) {
        columnsSettings[reportColumnIndex].disabledInReports = value;
    }

    Object.keys(otherSettings).forEach(key => {
        if (key === 'hidecolumns') {
            tableAndOtherData['hidecolumns'] = 'hidecolumns';
            return;
        }

        if (key === 'display_length') {
            tableAndOtherData['iDisplayLength'] = otherSettings.display_length;
            return;
        }

        ['subcat', 'prefix', 'pdfpages', 'rnotes', 'gaps'].includes(key)
            // reversed
            ? tableAndOtherData[key] =  otherSettings[key] === 'show' ? 'hide' : 'show'
            : tableAndOtherData[key] = otherSettings[key];
    });

    columnsSettings.forEach(el => {
        if (typeof el.apiAlias !== 'undefined') {
            tableAndOtherData[el.apiAlias] = el.apiAlias === 'comp' ? el.enabled ? 'hide' : 'show': el.enabled ? 'show' : 'hide'
        }

        if (typeof el.apiAliasReports !== 'undefined' && el.disabledInReports) {
            tableAndOtherData[el.apiAliasReports] ='hide';
        }
    });

    // serp_features were removed but should be hidden
    // tableAndOtherData['serp_features'] = 'hide';

    return {tableAndOtherData, otherSettings, columnsSettings};
};
export const prepareKeywordSettings = (getters, {setting, value}) => {
    const keywordData = {};
    const keywordSettings = {...getters.getKeywordSettings};

    if (keywordSettings.hasOwnProperty(setting)) {
        keywordSettings[setting] = value;
    }

    Object.keys(keywordSettings).forEach(key => {
        keywordData[key] = keywordSettings[key];
    });

    return {keywordData, keywordSettings};
};
export const prepareDisplaySettings = (getters, {setting, value}) => {
    // prepare display settings
    const displayData = {};
    const displaySettings = {...getters.getDisplaySettings};

    if (displaySettings.hasOwnProperty(setting)) {
        displaySettings[setting] = value;
    }

    Object.keys(displaySettings).forEach(key => {
        switch (key) {
            case 'metrics_range':
                displayData.metricsrange = displaySettings.metrics_range;
                break;
            case 'sort_by_order':
                displayData.sortbyorder = displaySettings.sort_by_order === 'asc' ? '0' : '1';
                break;
            case 'currency_code':
                displayData.currency_code = displaySettings.currency_code;
                break;
            default:
                displayData[key.replace(/_/g, '')] = displaySettings[key];
        }
    });

    return {displayData, displaySettings};
};