import {MS_PER_DAY} from '@/constants';
import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
import {switchDateFormat} from '@/helpers/format-date-service';

const periodsConfig = [
    {value: 10, text: '10 days'},
    {value: 30, text: '30 days'},
    {value: 60, text: '60 days'},
    {value: 90, text: '90 days'},
    {value: 180, text: '180 days'},
    {value: 365, text: '365 days'},
    {value: 730, text: '730 days'},
    {value: 666666, text: 'All time'},
];

const config = (notes, projects, leaveNote, competitorData = {}) => ({
    isDataIncluded: true,
    isExportEnabled: true,
    legend: {align: 'center'},
    series: [],
    title: {text: ''},
    credits: {
        href: 'https://app.keyword.com/',
        text: '',
    },
    chart: {
        customBackground: false,
        events: {},
        zoomType: 'x',
        resetZoomButton: {
            theme: {display: 'none'},
        },
    },
    exporting: {
        enabled: true,
        fallbackToExportServer: true,
        buttons: {
            contextButton: {enabled: false},
        },
        chartOptions: {
            plotOptions: {
                series: {
                    dataLabels: {enabled: false},
                }
            },
        },
    },
    colors: [
        '#176BFA',
        '#FA8117',
        '#AE17FA',
        '#910000',
        '#1aadce',
        '#492970',
        '#f28f43',
        '#77a1e5',
        '#c42525',
        '#a6c96a',
    ],
    xAxis: {
        type: 'datetime',
        labels: {
            formatter: function () {
                return switchDateFormat(new Date(this.value), 'chart-date');
            },
        },
    },
    yAxis: [{
        allowDecimals: false,
        reversed: true,
        title: {text: ''},
    }],
    plotOptions: {
        series: {
            pointInterval: MS_PER_DAY,
            pointStart: Date.UTC(2010, 0, 1),
            marker: {
                enabled: true,
                radius: 3,
            },
            point: {
                events: {
                    click: function () {
                        if (window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE) {
                            return;
                        }

                        const {series: {index, chart}, x} = this;

                        const isCompetitorData = chart.options.series[index].name === competitorData.competitorDomain;
                        leaveNote({
                            date: switchDateFormat(new Date(x), 'Y-m-d'),
                            projectId: projects[0],
                            ...isCompetitorData && { competitorDomain: competitorData.competitorDomain, keywordId: competitorData.keywordId}
                        });
                    }
                }
            }
        },
    },
    noData: {
        style: {
            color: '#303030',
            fontSize: '15px',
            fontWeight: 'bold',
        },
    },
    tooltip: {
        crosshairs: true,
        shared: true,
        style: {},
        useHTML: true,
        formatter: function () {
            // date of point formatted for compare
            const date = switchDateFormat(new Date(this.x), 'new-note');

            let isThereManual = false;
            // notes filtering by date
            const notesByDate = projects.reduce((acc, cur) => {
                notes[cur] && acc.push(...notes[cur].filter(n => switchDateFormat(new Date(n.attributes.created_at), 'new-note') === date));
                return acc;
            }, []);

            return `<div class="keyword-chart-tooltip">
                ${notesByDate.length ? `
                    <div class="keyword-chart-tooltip__container">
                        <h6 class="keyword-chart-tooltip__title">Notes</h6>
                        <div class="keyword-chart-tooltip__content">
                            ${notesByDate.reduce((acc, cur) => {
                                acc += `<p>${cur.attributes.note}</p>`;
                                return acc;
                            }, '')}
                        </div>
                    </div>` : ''
                }
                <div class="keyword-chart-tooltip__container">
                    <h6 class="keyword-chart-tooltip__title">${switchDateFormat(new Date(this.x), 'l F jS, Y')}</h6>
                    <div class="keyword-chart-tooltip__content">
                        ${this.points.reduce((acc, cur) => {
                            let manual = cur.series.options.manualStart;

                            if (manual) {
                                manual = switchDateFormat(new Date(cur.series.options.first_rank), 'new-note') === date;
                                isThereManual = isThereManual || manual;
                            }

                            acc += `<div class="chart-tooltip__line">
                                <i style="background-color: ${cur.color}; height: 4px; width: 4px;" class="chart-tooltip__marker"></i>
                                ${cur.series.name}:<b> ${cur.y}</b>${manual ? '<i style="color: red">*</i>' : ''}
                            </div>`;
                            return acc;
                        }, '')}
                        ${isThereManual ? '<div style="margin-top: 10px;">' +
                            '<i style="color: red; vertical-align: middle;">* </i>' +
                            '<span style="color: #838383">Start rank manually updated. Verified Spyglass rank may not match.</span>' +
                            '</div>' : ''
                        }
                    </div>
                </div>
            </div>`;
        },
    },
});

const addSeriesToOptions = ({getSeries, chartOptions, notes, projectId = null, keywordId = null}) => {
    // make sure there is at least one valid rank in the series
    if (getSeries.some(el => el.data.some(ol => ol[1] > 0))) {
        chartOptions.series = _.cloneDeep(getSeries);

        // if date has notes - we should change marker icon
        chartOptions.series.forEach(s => {
            const projectName = s.project ? s.project : projectId;

            if (!notes[projectName] || !notes[projectName].length) {
                return;
            }

            s.data = s.data.map(
                d => notes[projectName].some(n => {
                    if (
                        (s.project && !n.attributes.competitor_domain)
                        || (
                            !s.project && n.attributes.competitor_domain
                            && n.attributes.competitor_domain === s.name
                            && +n.attributes.keyword_id === +keywordId
                        )
                    ) {
                        return switchDateFormat(new Date(n.attributes.created_at), 'new-note') === switchDateFormat(new Date(d[0]), 'new-note');
                    }
                })
                    ? {x: d[0], y: d[1], marker: {symbol: 'url(https://keyword.com/serp/images/notes.png)'}}
                    : d
                );
        });

    } else {
        chartOptions.series = [];
        // If there is no data - don't use background image.
        chartOptions.chart.plotBackgroundImage = null;
    }

    if (!chartOptions.series.length) {
        chartOptions.series = [
            { type: 'line', name: 'Google', data: [] },
        ];

        chartOptions.isExportEnabled = false;
        return chartOptions;
    }

    chartOptions.isExportEnabled = true;
    return chartOptions;
};

const getFileName = (keywords) => {
    return keywords.length === 1
        ? keywords[0].kw
        : keywords.reduce((acc, cur) => acc += `${cur.kw}, `, '').substr(0, 235);
};

export {config, addSeriesToOptions, getFileName, periodsConfig};
