//latest

import {defaultOverviewSort} from '../configs';
import apiFactory from '@/api';
import {cookieGetters} from '@/helpers/cookieManager';
const metricsApi = apiFactory.get('metrics');
import { switchDateFormat } from '@/helpers/format-date-service';
import { dashboardOverviewMetricsOptionsValues } from '@/components/metrics/configs'
import { parseToMetricsTableData } from '@/helpers/dashboardHelpers'
import {
    SET_CURRENT_OVERVIEW_PAGE,
    SET_OVERVIEW_DATA_LOADING,
    SET_OVERVIEW_DATA,
    SET_OVERVIEW_ITEMS_PER_PAGE,
    SET_OVERVIEW_SEARCH,
    SET_OVERVIEW_SORT,
    SET_OVERVIEW_VIEW,
    SET_SORT_BY_CHANGES,
    SET_OVERVIEW_CHARTS_DATA_PARAMS,
    SET_OVERVIEW_DATA_PARAMS,
    SET_OVERVIEW_PAGINATE_WITH_API
} from '../mutations';

export const overviewPersistedKeys = [
    'overview.currentOverviewPage',
    'overview.overviewItemsPerPage',
    'overview.overviewSort',
    'overview.overviewView',
    'overview.sortByChanges',
];

const overview = {
    state: {
        currentOverviewPage: 1,
        overviewData: {data: [], showall: cookieGetters.getShowAll(), fromStart: false},
        overviewDataLoading: false,
        overviewItemsPerPage: 50,
        overviewSearch: '',
        overviewSort: defaultOverviewSort,
        overviewView: 'chart',
        sortByChanges: false,
        overviewDataParams: {
            page: 1,
            per_page: 20
        },
        paginateWithAPI: true
    },
    mutations: {
        [SET_CURRENT_OVERVIEW_PAGE]: (state, payload) => state.currentOverviewPage = payload,
        [SET_OVERVIEW_DATA_LOADING]: (state, payload) => state.overviewDataLoading = payload,
        [SET_OVERVIEW_DATA]: (state, payload) => state.overviewData = payload,
        [SET_OVERVIEW_ITEMS_PER_PAGE]: (state, payload) => state.overviewItemsPerPage = payload,
        [SET_OVERVIEW_SEARCH]: (state, payload) => state.overviewSearch = payload,
        [SET_OVERVIEW_SORT]: (state, payload) => state.overviewSort = payload,
        [SET_OVERVIEW_VIEW]: (state, payload) => state.overviewView = payload,
        [SET_SORT_BY_CHANGES]: (state, payload) => state.sortByChanges = payload,
        [SET_OVERVIEW_CHARTS_DATA_PARAMS]: (state, payload) => state.overviewChartsDataParams = payload,
        [SET_OVERVIEW_DATA_PARAMS]: (state, payload) => state.overviewDataParams = payload,
        [SET_OVERVIEW_PAGINATE_WITH_API]: (state, payload) => state.paginateWithAPI = payload,
    },
    getters: {
        getCurrentOverviewPage: state => state.currentOverviewPage,
        getOverviewData: state => state.overviewData,
        getOverviewDataLoading: state => state.overviewDataLoading,
        getOverviewItemsPerPage: state => state.overviewItemsPerPage,
        getOverviewSearch: state => state.overviewSearch,
        getOverviewSort: state => state.overviewSort,
        getOverviewView: state => state.overviewView,
        getSortByChanges: state => state.sortByChanges,
        getProjectsWithMetrics: (state, {getOriginalActiveProjects, getOverviewData, getOverviewPaginateWithAPI}) => {
            const array = [];
            const { data, fromStart } = getOverviewData;
            // Append additional attributes from the original projects
            if(getOverviewPaginateWithAPI){
                data.forEach(project => {
                    const original = getOriginalActiveProjects.find(x => x.id === project.id);
                    array.push({
                        ...project,
                        attributes: {
                            ...project.attributes,
                            updated_at: original
                                ? switchDateFormat(new Date(original.attributes.updated_at), 'Y-m-d')
                                : null,
                        },
                    });
                });
            } else {
                data.forEach(project => {
                    const original = getOriginalActiveProjects.find(x => x.id === project.project_name);
                    const ranges = parseToMetricsTableData(project, fromStart);
    
                    // push the updated item
                    array.push({
                    type: "metrics",
                        id: project.project_name,
                        chart: null,
                        attributes: {
                            ...project.attributes,
                            updated_at: original
                                ? switchDateFormat(new Date(original.attributes.updated_at), 'Y-m-d')
                                : null,
                            chart: null,
                            total: project.total_keywords_count,
                            ranges,
                        },
                    });
                });
            }
            
            return array;
        },
        getSortedAndFilteredProjectsWithMetrics: (state, {
            getDisplaySetting,
            getOverviewSearch,
            getOverviewSort,
            getProjectsWithMetrics,
            getSortByChanges,
        }) => {
            const metricsPeriod = getDisplaySetting('metrics');
            const {direction, field} = getOverviewSort;
            const filteredProjects = getProjectsWithMetrics.filter(p => p.id.toLowerCase().includes(getOverviewSearch.toLowerCase()));

            // universal method for sorting by metrics ranges
            const universalMetricsSort = (a, b) => {
                // sorting by keywords up
                if (field === 'attributes.up') {
                    return direction === 'asc'
                        ? a.attributes.movements[metricsPeriod].up - b.attributes.movements[metricsPeriod].up
                        : b.attributes.movements[metricsPeriod].up - a.attributes.movements[metricsPeriod].up;
                }

                // if sorting by changes selected we use changes of current period
                if (getSortByChanges) {
                    return direction === 'asc'
                        ? _.get(a, field)[metricsPeriod].change - _.get(b, field)[metricsPeriod].change
                        : _.get(b, field)[metricsPeriod].change - _.get(a, field)[metricsPeriod].change;
                }

                // sorting by selected metrics range
                return direction === 'asc'
                    ? _.get(a, field).count - _.get(b, field).count
                    : _.get(b, field).count - _.get(a, field).count;
            };

            // sorting by name/id of project
            if (field === 'id') {
                return filteredProjects.sort((a, b) =>
                    direction === 'asc'
                        ? a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1
                        : a.id.toLowerCase() > b.id.toLowerCase() ? -1 : 1);
            }

            // sorting by keywords count
            if (field === 'keywords') {
                return filteredProjects.sort((a, b) =>
                    direction === 'asc'
                        ? a.attributes.total - b.attributes.total
                        : b.attributes.total - a.attributes.total);
            }

            // sorting by date
            if (field === 'attributes.updated_at') {
                return filteredProjects.sort((a, b) =>
                    direction === 'asc'
                        ? new Date(a.attributes.updated_at) - new Date(b.attributes.updated_at)
                        : new Date(b.attributes.updated_at) - new Date(a.attributes.updated_at),
                );
            }

            // sorting by metrics ranges
            if (field.includes('attributes')) {
                return filteredProjects.sort((a, b) =>
                    universalMetricsSort(a, b));
            }
        },
        // just pagination of sorted items
        getPaginatedProjectsWithMetrics: (state, getters) => {
            const {
                getSortedAndFilteredProjectsWithMetrics,
                getCurrentOverviewPage,
                getOverviewItemsPerPage
            } = getters;

            return getSortedAndFilteredProjectsWithMetrics.slice(
                (getCurrentOverviewPage - 1) * getOverviewItemsPerPage,
                getCurrentOverviewPage * getOverviewItemsPerPage
            );
        },
        getOverviewDataParams:  state => state.overviewDataParams,
        getOverviewPaginateWithAPI: state => state.paginateWithAPI,
    },
    actions: {
        overviewPageChanged ({getters, commit, dispatch}, payload) {
            switch (payload.page) {
                case 'next':
                    payload.withAPI ?
                        dispatch('fetchOverviewData', {page: getters.getOverviewDataParams.current_page + 1}) :
                        commit(SET_CURRENT_OVERVIEW_PAGE, getters.getCurrentOverviewPage + 1);
                    break;
                case 'prev':
                    payload.withAPI ?
                        dispatch('fetchOverviewData', {page: getters.getOverviewDataParams.current_page - 1}) :
                        commit(SET_CURRENT_OVERVIEW_PAGE, getters.getCurrentOverviewPage - 1);
                    break;
                default:
                    payload.withAPI ?
                        dispatch('fetchOverviewData', { page: payload.page }) :
                        commit(SET_CURRENT_OVERVIEW_PAGE, payload.page);
                    break;
            }
        },
        overviewSorterChanged ({getters, commit}, payload) {
            const {getOverviewSort} = getters;

            commit(SET_OVERVIEW_SORT, {
                field: payload,
                direction: getOverviewSort.field === payload
                    ? getOverviewSort.direction === 'asc' ? 'desc' : 'asc'
                    : 'desc',
            });
        },
        toggleSortByChanges ({getters, commit}) {
            commit(SET_SORT_BY_CHANGES, !getters.getSortByChanges);
        },
        searchProjects ({commit}, payload) {
            commit(SET_OVERVIEW_SEARCH, payload);
        },
        async fetchOverviewData ({commit, getters, dispatch}, params) {
            const auth = cookieGetters.getShowAll();
            const paginateWithAPI = getters.getOverviewPaginateWithAPI;
            const metricsValue = getters.getDisplaySetting('metrics');
            const daysRange = params?.days_range ? params.days_range : dashboardOverviewMetricsOptionsValues[metricsValue];
            if (!auth) {
                return;
            }
            commit(SET_OVERVIEW_DATA_LOADING, true);
            const queryParams = {
                params: { 
                    page: params?.page || 1,
                    per_page: params?.per_page || getters.getOverviewDataParams.per_page
                }
            };
            try {
                if(paginateWithAPI){
                    const res = await metricsApi.fetchChartsMetrics(queryParams);

                    const { data, meta } = res.data;
                    commit(SET_OVERVIEW_DATA, {data, showall: auth});

                    dispatch('setOverviewDataParams', meta.pagination);
                } else {
                    let data = getters.getOverviewData.data;
                    if(daysRange !== 'start' || !data.length){
                        const res = await metricsApi.fetchMetricsTableData({
                            days_range: daysRange !== 'start' ? daysRange : 1,
                        });
                        data = res.data.data;
                    }

                    commit(SET_OVERVIEW_DATA, { data: data, showall: auth, fromStart: daysRange==='start' });
                }       
            } catch (error) {
                //
            } finally {
                commit(SET_OVERVIEW_DATA_LOADING, false);
            }
        },
        setOverviewView({commit}, payload){
            commit(SET_OVERVIEW_VIEW, payload);
        },
        setOverviewDataParams ({commit}, payload) {
            commit(SET_OVERVIEW_DATA_PARAMS, payload);
        },
        setOverviewPaginateWithAPI ({commit}, payload) {
            commit(SET_OVERVIEW_PAGINATE_WITH_API, payload);
        },
    }
};

export default overview;
