<template>
    <div :class="mainBlockClasses" @scroll.stop @mouseover="mouseoverHandler">
        <div :class="contentClasses">
            <top-bar />
            <div v-if="!isSearch" class="project-content">
                <tabs v-model="activeTab" class="main-tabs">
                    <tab
                        :header="getTabHeader($t('keywords'), keywordsCount)"
                    >
                        <div v-if="activeTab === mainTabs.keywords" class="content-and-filter-wrap">
                            <project />
                        </div>
                    </tab>
                    
                    <tab
                        :header="`${$t('overview')}`"
                    >
                        <div class="actions-wrapper">
                            <overview-options />
                        </div>
                        <project-overview v-if="activeTab === mainTabs.overview" />
                    </tab>

                    <tab
                        :header="getTabHeader($t('top-pages.title'))"
                    >
                        <top-pages-tab v-if="activeTab == mainTabs.topPages" />
                    </tab>

                    <tab
                        v-if="SHOW_GSC_SERVICE && !this.getViewkeyView"
                        :header="
                            getTabHeader($t('suggested'), getSuggestionsCount)
                        "
                    >
                        <keyword-suggestions
                            v-if="
                                getCurrentProject &&
                                activeTab === mainTabs.suggestions
                            "
                        />
                    </tab>

                    <tab
                        :header="`${$t('share-of-voice')}`"
                    >
                        <competitors-panel
                            v-if="
                                getCurrentProject &&
                                activeTab === mainTabs.competitions
                            "
                        />
                    </tab>
                </tabs>
            </div>
            <div v-else class="content-and-filter-wrap">
                <project />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Events, EventBus } from '@/events';
import ProjectWithOverview from './components/ProjectWithOverview';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import TopBar from './components/TopBar';
import { scrollToSideBarItem } from '@/helpers/scroll-to-side-bar-item';
import ProjectOverview from './components/ProjectOverview.vue';
import { tabs, tab } from 'vue-strap';
import convertToShort from '@/helpers/number-convert'
import {PROJECT_MAIN_TABS} from '@/constants';
import CompetitorsPanel from '../competitors-panel/CompetitorsPanel.vue';
import KeywordSuggestions from 'components/keyword-suggestions/KeywordSuggestions';
import TopPagesTab from './components/TopPages/Tab.vue';
import OverviewOptions from './components/OverviewOptions.vue';

export default {
    name: 'MainBlockWithOverview',
    components: {
        'project': ProjectWithOverview,
        'top-bar': TopBar,
        'project-overview': ProjectOverview,
        'tabs': tabs,
        'tab': tab,
        'competitors-panel': CompetitorsPanel,
        'keyword-suggestions': KeywordSuggestions,
        'top-pages-tab': TopPagesTab,
        'overview-options': OverviewOptions,
    },
    mixins: [ToolTipRenderMixin],
    data() {
        return {
            activeTab: 0,
            mainTabs: PROJECT_MAIN_TABS
        }
    },
    computed: {
        ...mapGetters([
            'getKeywordsIsFetching',
            'getSelectedAuth',
            'getCurrentProject',
            'getActiveTag',
            'getOtherSetting',
            'getViewkeyView',
            'getGSCSuggestions',
            'getGSCProfileCurrentConnection',
        ]),
        SHOW_GSC_SERVICE() {
            return process.env.SHOW_GSC_SERVICE == 'true';
        },
        contentClasses() {
            return {
                'content': true,
                // 'prevent-overflow': this.getKeywordsIsFetching,
            }
        },
        mainBlockClasses() {
            return {
                'main-block': true,
                'main-block-overview': true
            };
        },
        keywordsCount(){
            return convertToShort(this.$route.query?.tag ?
                this.getActiveTag.keywords_count : 
                this.getCurrentProject?.keywords_count.ACTIVE);
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        getSuggestionsCount() {
            return this.getGSCProfileCurrentConnection &&
                this.getGSCSuggestions?.length
                ? this.getGSCSuggestions.length
                : null;
        },
    },
    beforeRouteUpdate(to, from, next) {
        if (to.path !== from.path) {
            this.resetFilter();
        }
        next();
    },
    watch: {
        $route(to) {
            if (this.$route.query && this.$route.query.query) {
                this.setGlobalSearchQuery(this.$route.query.query);
                this.fetchKeywordsFromSearch();
            }
            if (to.name === 'archived') {
                this.setArchivedProjectsOpened(true);
            }
        },
        getActiveTag() {
            this.emitProjectViewEvent();
        },
        getCurrentProject(newProject, oldProject) {
            if (!oldProject && newProject) {
                scrollToSideBarItem(newProject.project_id, this.getActiveTag.id);
            }
        },
        activeTab(to){
            this.changeTabQuery(to)
        }
    },
    created() {
        this.setIsViewkeyView(false);
        const initialTab = this.$route.query?.tab;
        if(initialTab){
            if(this.mainTabs[initialTab]){
                this.activeTab = this.mainTabs[initialTab];
            } else if (Object.values(this.mainTabs).includes(+initialTab)){
                this.activeTab = +initialTab;
            }
        }
    },
    mounted() {
        this.emitProjectViewEvent();
        if (this.$route.query && this.$route.query.query) {
            this.setGlobalSearchQuery(this.$route.query.query)
            this.fetchKeywordsFromSearch();
        }
        if (this.$route.name === 'archived') {
            this.setArchivedProjectsOpened(true);
        }
    },
    methods: {
        ...mapActions([
            'fetchKeywordsFromSearch',
            'resetFilter',
            'setGlobalSearchQuery',
            'setIsViewkeyView',
            'setArchivedProjectsOpened',
        ]),
        getTabHeader(title, count = null) {
            return (`${title} ${count !== null ? `<span>(${count})</span>` : '' }`);
        },
        emitProjectViewEvent() {
            if (!this.getCurrentProject) {
                setTimeout(this.emitProjectViewEvent, 1000);
            } else {
                let tagView = !!Object.keys(this.getActiveTag).length;
                EventBus.emit(Events.PROJECT_VIEW_EVENT, {
                    num_keywords_in_project:
                        tagView
                        ? this.getActiveTag.keywords_count ?? 0
                        : this.getCurrentProject.keywords_count?.ACTIVE ?? 0,
                    tag_view: tagView,
                });
            }
        },
        changeTabQuery(tab){
            const tagId = this.$route.query?.tag;
            if(Object.values(this.mainTabs).includes(tab) || this.mainTabs[`${tab}`]){
                this.$router.push({query: {tab, ...(tagId ? {tag: tagId} : {})}});
            }
        }
    }
}
</script>

<style lang="scss">
@import '~sass/partials/new-nav-tabs';

.dashboard-metric-wrap {
    padding-left: 34px;
    padding-right: 0px;
}

.main-block {
    @extend .custom-scroll;
    padding-bottom: 44px;
    position: relative;
    transition: all .3s ease;
    width: 100%;

    @media (max-width: 959px) {
        padding-bottom: 28px;
    }

    &.main-block-overview {
        background-color: kw-color(kw-white, 4);

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -26px;
            width: 26px;
            height: 100%;
            background-color: kw-color(kw-white, 4);
            @media screen and (max-width: 959px){
                display: none;
            }
        }

        &::after {
            left: unset;
            right: -26px
        }

        .top-bar__wrapper {
            background-color: kw-color(kw-white, 4);
        }

        .main-tabs{
            .nav-tabs{
                position: sticky;
                top: 138px;
                z-index: 10;
                background-color: kw-color(kw-white, 4);
                @media screen and (max-width: 959px){
                    padding-right: 48px;
                    top: 170px;
                }
            }
        }
    }

    .content {
        @extend .custom-scroll;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-width: 650px;
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;

        @media (max-width: 959px) {
            max-width: unset;
            min-width: unset;
            margin-left: 0;
            margin-right: 0;
        }

        &.prevent-overflow {
            overflow: hidden;

            .top-bar__wrapper {
                top: 0;
            }
        }
    }
}

.project-content {
    margin-top: 10px;
    position: relative;

    .actions-wrapper {
        position: sticky;
        right: 0;
        top: 138px;
        z-index: 17;
        width: 100px;
        margin-left: auto;
        .options {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: -55px;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 959px){
        padding: 16px;
        .actions-wrapper {
            top: 170px;
            .options {
                margin-top: -55px;   
            }
        }
    }

    @media screen and (max-width: 630px){
        .actions-wrapper {
            top: 170px;
            .options {
                margin-top: -55px;   
            }
        }
    }

    .main-tabs{
        &>.nav-tabs {
            @extend .nav-tabs_outlined;
            margin: 0;
            background: white;
            display: flex;
            overflow-y: hidden;
            overflow-x: auto;

            &>li.active a {
                border-bottom: 3px solid kw-color(kw-blue, 6) !important;
            }

            li a {
                padding-left: 8px;
                padding-right: 8px;
                margin-right: 4px;
                & > span{
                    color: kw-color(kw-blue, 6);
                }
            }
        }
    }

    .content-and-filter-wrap {
        min-height: 100vh;
    }
}
</style>
