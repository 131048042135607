import client from '../client';
import interceptors from '../interceptors';
import { SUBSCRIPTION_CHANGE_HEADER } from '../../constants';
const billingInterceptor = interceptors.get('billing');

client.interceptors.response.use(
    billingInterceptor.s,
    billingInterceptor.e,
);

const resource = '/billing';

export default {
    subscribe: async payload => await client.post(`${resource}/subscription`, payload),
    changePlan: async (payload, type) => await client.put(`${resource}/subscription/plan`, payload, { headers: { [SUBSCRIPTION_CHANGE_HEADER]: type } }),
    cancelSubscription: async (payload) => await client.delete(`${resource}/subscription`, payload),
    resumeSubscription: async (payload) => await client.put(`${resource}/subscription/resume`, payload),
    addCard: async payload => await client.post(`${resource}/card`, payload),
    updateBillingDetails: async payload => await client.post(`${resource}/billing-details`, payload),
    fetchPackages: async () => await client.get('packages'),
    fetchPackageById: async planId => await client.get(`packages/${encodeURIComponent(planId)}`),
    setupStripeIntent: async payload => await client.post(`${resource}/setup-intent`, payload),
    applyCoupon: async (payload) => await client.put(`${resource}/subscription/apply-coupon`, payload),
};
