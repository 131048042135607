<template>
    <div :class="projectOverviewClasses">
        <div :class="metricsContainerClasses">
            <div
                v-if="!getViewkeyView && isChartDisplayed"
                class="project-overview__metrics-title"
            >
                <template v-if="!loading">
                    <project-link
                        v-if="!loading"
                        :project-raw="getProjectRaw"
                        :new-tab="false"
                    />
                    <div v-if="project.total" class="project-overview__total">
                        <span v-text="project.total" />
                        {{ $t('total-keywords') }}
                    </div>
                </template>
                <template v-else>
                    <placeholder-cell :width="200" :height="14" />
                    <placeholder-cell :width="200" :height="14" />
                </template>
            </div>
            <metrics
                v-if="this.$route.name === 'keywordList' && isMobileView"
                :data="project"
                :loading="loading"
            />
            <metrics v-else :data="project" :loading="loading" type="chart" />
        </div>
        <template v-if="isChartDisplayed">
            <div
                v-resize="() => debouncedHandler(resizeChart)"
                :class="chartContainerClassNames"
            >
                <template v-if="isDashboard">
                    <dropdown
                        :close-on-click="true"
                        :item-click-handler="dropdownItemClick(project)"
                        :items="dropdownItems"
                        :x="-96"
                        :y="4"
                    >
                        <template slot="btn">
                            <kw-button
                                button-type="secondary"
                                prepend-icon-class="kw kw-more-horizontal"
                            />
                        </template>
                    </dropdown>
                </template>

                <div v-else class="project-overview__chart-header">
                    {{ $t('keywords-chart-header') }}
                </div>

                <keywords-in-top-chart
                    :loading="loading"
                    :project="project"
                    :chart="chart"
                    @setChart="setChart"
                    :isChartDisplayed="isChartDisplayed"
                    :projectId="project.id"
                    :key="`${chartKey}`"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { actions } from '@/configs/overviewConfigs';
import { mapGetters, mapActions } from 'vuex';
import Metrics from 'components/metrics/Metrics';
import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';
import resize from 'vue-resize-directive';
import {
    MOBILE_WIDTH_RESOLUTION_PHONE,
    pdfExportParams,
    REPORT_TYPE,
} from '@/constants';
import KeywordsInTopChart from './KeywordsInTopChart.vue';

export default {
    name: 'ChartAndMetrics',
    components: {
        metrics: Metrics,
        'keywords-in-top-chart': KeywordsInTopChart,
    },
    directives: {
        resize,
    },
    mixins: [ProjectsActionsMixin],
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
            dropdownItems: actions,
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getKeywordFilters',
            'getViewkeyView',
            'getOtherSetting',
            'getActiveTag'
        ]),
        chartKey(){
            return this.getActiveTag?.id ? this.getActiveTag.id : this.getCurrentProject?.id;
        },
        getProjectRaw() {
            return this.getCurrentProject?.id || this.project?.id || '';
        },
        projectOverviewClasses() {
            return {
                'project-overview': this.isChartDisplayed,
                'project-overview_viewkey': this.getViewkeyView,
            };
        },
        isMobileView() {
            return MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width;
        },
        isChartDisplayed() {
            return (
                this.$route.name === 'Overview' ||
                this.getOtherSetting('rankingAreaChart') !== 'hide'
            );
        },
        metricsContainerClasses() {
            return {
                'project-overview__metrics-container': this.isChartDisplayed,
                'project-overview__metrics-container_full': !this
                    .isChartDisplayed,
            };
        },
        isDashboard() {
            return !this.getViewkeyView && this.$route.name !== 'keywordList';
        },
        chartContainerClassNames() {
            return {
                'project-overview__chart-container': true,
                'project-page': !this.isDashboard,
            };
        },
    },
    methods: {
        ...mapActions(['selectEditProject']),
        debouncedHandler: _.debounce(fn => fn(), 50),
        resizeChart() {
            if (this.chart) {
                // just walkaround because of reflow() does not work
                window.dispatchEvent(new Event('resize'));
            }
        },
        setChart(value){
            this.chart = value;
        },
        dropdownItemClick(project) {
            return e => {
                switch (e) {
                    case 'get-viewkey':
                        this.openGetViewkey([this.getProjectById(project.id)]);
                        break;

                    case 'archive-project':
                        this.manageProjects(
                            'archive',
                            true,
                            this.getProjectById(project.id),
                        );
                        break;

                    case 'edit-project':
                        this.selectEditProject(project.id);
                        break;

                    case 'get-csv':
                        this.exportProjectAsCSV(
                            this.getProjectById(project.id),
                        );
                        break;

                    case 'get-pdf':
                        this.exportReport(this.getProjectById(project.id), {
                            report_type: REPORT_TYPE.PDF,
                            startDate: this.getKeywordFilters.keywordsDate,
                            ...pdfExportParams,
                        });
                        break;
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.project-overview {
    background-color: kw-color(kw-white, 1);
    border: 1px solid kw-color(kw-light-grey, 1);
    border-radius: 3px;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 17px;
    padding: 16px 10px 16px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 959px) {
        padding: 16px 8px;
    }
}

.project-overview_viewkey {
    border: 1px solid kw-color(kw-light-grey, 1);
    padding: 24px 0 24px 30px;
}

.project-overview__metrics-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-right: 5px;

    a {
        margin-right: 10px;
    }
}

.project-overview__total {
    color: kw-color(kw-grey, 1);
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    span {
        font-weight: 500;
    }
}

.project-overview__metrics-container {
    width: 502px;
    padding-top: 8px;
    padding-right: 5px;

    &_full {
        width: 100%;
    }

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 32px;

        /deep/ .dashboard {
            max-width: 100% !important;
        }
    }

    /deep/ .dashboard {
        display: flex;
        flex-flow: row wrap;
        max-width: 542px;
    }

    /deep/ .dashboard-block {
        flex: 1 1 30%;
        margin: 0 6px;

        &:first-child {
            margin-left: 0;
        }

        &:nth-child(4) {
            margin-left: 0;
            margin-top: 12px;
        }

        &:nth-child(5) {
            margin-top: 12px;
        }

        &:nth-child(6) {
            margin-top: 12px;
        }
    }
}

.project-overview__chart-container {
    .project-overview__chart-header {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        color: #63697b;
        padding: 0 10px;
        width: 100%;
    }

    align-items: flex-end;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    justify-content: space-between;
    max-width: calc(100% - 502px);
    position: relative;

    &.project-page {
        margin-top: 8px;
    }

    /deep/ .button-loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50% -50%);
    }

}

.kw-button {
    position: absolute;
    right: 0;
    top: -8px;
    width: 36px;
    padding: 8px 9px !important;

    @media (max-width: 959px) {
        top: -26px;
    }

    /deep/ span {
        // important for js (do not remove)
        pointer-events: none;
        font-size: 16px;
        font-weight: 700;
    }
}

/deep/ .overview-chart-wrapper {
    height: 228px;
}

@media screen and (min-width: 959px) {
    .project-overview__chart-container {
        &.project-page {
            &:before {
                content: '';
                border-left: 1px solid #dce4ec;
                height: 297px;
                position: absolute;
                left: 0;
                top: -24px;
                z-index: 1;
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .project-overview__chart-container {
        min-width: 100%;
        max-width: 100%;
        /deep/ .highcharts-container {
            padding: 0 0 0 1px;
        }
        /deep/ .project-overview__chart {
            width: 101%;
        }
        .project-overview__chart-header {
            padding: 0;
        }
    }
    .project-overview__metrics-container {
        padding: 0;

        /deep/ .dashboard-block {
            &:nth-child(3) {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .project-overview_viewkey {
        border: 0;
        box-shadow: none;
        padding: 0;
    }
}
@media screen and (max-width: 865px) {
    /deep/ .project-overview__chart {
        width: 102% !important;
    }
    /deep/ .highcharts-container {
        padding: 0 0 0 8px !important;
    }
}
@media screen and (max-width: 725px) {
    /deep/ .highcharts-container {
        padding: 0 0 0 5px !important;
    }
}
@media screen and (max-width: 566px) {
    .project-overview__chart {
        width: 103% !important;
    }
    /deep/ .highcharts-container {
        padding: 0 0 0 7px !important;
    }
}
@media screen and (max-width: 466px) {
    /deep/ .highcharts-container {
        padding: 0 0 0 5px !important;
    }
}
</style>
